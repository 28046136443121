import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import { ErrorHandler } from '../services/errorHandler.service';

import { ManagerService } from '../services/manager.service';
import { snackbar_options } from '../variables';
import { MarathonComponent } from './marathon/marathon.component';


@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {
  current_page = '';
  marathons = [];

  constructor(
    public snackBar: MatSnackBar,
    public http: ManagerService,
    private router: Router,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    let href = window.location.pathname.split('/');
    if (href.indexOf("maraton") > -1)
      this.current_page = 'marathon-' + href[href.length - 1];
    this.get_data();
  }

  get_data = () => {
    this.http.Manager('get_marathons')
      .subscribe(data => {
        this.marathons = data.marathons;
      }, err => {
        this.errorHandler.handleError(err);
      });
  }

  set_page = (page) => {
    MarathonComponent.returned.next();
    this.current_page = page;
  }

  logout = () => {
    this.http.Manager('logout')
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.router.navigateByUrl('/');
      }, err => {
        this.errorHandler.handleError(err);
      });
  }

}
