import { Component, OnInit } from '@angular/core';
import { MatSnackBar, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class UserAddEditComponent implements OnInit {
  edit = false;

  data = {
    id: 0,
    firstname: '',
    lastname: '',
    gender: 0,
    birthday_date: null,
    email: '',
    phone: '',
    city: '',
    team: '',
    school: '',
    profession: ''
  };
  loading = false;

  constructor(
      public http: AdminService,
      public snackBar: MatSnackBar,
      private router: Router,
    private location: Location,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    let href = window.location.pathname.split('/');
    if (href[href.length - 1] != 'dodaj-uzytkownika') {
        this.edit = true;
        this.http.Admin('user_get', {id: href[href.length-1]})
        .subscribe(data => {
          this.data = data;
        }, err => {
          this.router.navigate(['/admin/uzytkownik']);
          this.errorHandler.handleError(err);
        });
    }
  }

  save = () => {
      if (this.data.firstname.length == 0) return this.snackBar.open('Podaj imię.', '', snackbar_options);
      if (this.data.lastname.length == 0) return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
      if (this.data.email.length == 0) return this.snackBar.open('Podaj e-mail.', '', snackbar_options);
      let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re_mail.test(String(this.data.email).toLowerCase()))
        return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
      if (this.data.phone.length == 0) return this.snackBar.open('Podaj numer telefonu.', '', snackbar_options);
      let re_phone = /^[\d\s]+$/;
      if (!re_phone.test(String(this.data.phone)))
        return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
      if (String(this.data.phone).replace(/[^0-9]/g, "").length < 9)
        return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);
      if (this.data.gender == 0) return this.snackBar.open('Wybierz płeć.', '', snackbar_options);
      if (this.data.city.length == 0) return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
      if (!this.data.birthday_date) return this.snackBar.open('Podaj datę urodzenia.', '', snackbar_options);

      let params = {
          firstname: this.data.firstname,
          lastname: this.data.lastname,
          city: this.data.city,
          gender: this.data.gender,
          birthday_date: this.data.birthday_date,
          phone: this.data.phone
      };
      if (this.data.team) params['team'] = this.data.team;
      if (this.data.school) params['school'] = this.data.school;
      if (this.data.profession) params['profession'] = this.data.profession;
      this.loading = true;
      if (this.edit) {
          this.http.Admin('user_edit', {id: this.data.id, data: params})
          .subscribe(data => {
            if (data && data.message)
              this.snackBar.open(data.message, '', snackbar_options);
            this.router.navigate(['/admin/uzytkownik']);
          }, err => {
            this.loading = false;
            this.errorHandler.handleError(err);
          });
      } else {
          params['email'] = this.data.email;
          this.http.Admin('user_add', params)
          .subscribe(data => {
            if (data && data.message)
              this.snackBar.open(data.message, '', snackbar_options);
            this.router.navigate(['/admin/uzytkownik']);
          }, err => {
            this.loading = false;
            this.errorHandler.handleError(err);
          });
      }
  }

  goBack = () => {
    this.router.navigate(['/admin/uzytkownik']);
  }

}
