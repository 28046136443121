import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { StartService } from '../../services/start.service';
import { SharedService } from "../../shared.service";
import { MatSnackBar, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { snackbar_options } from '../../variables';
import {Router} from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';
import $ from 'jquery';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class RegisterComponent implements OnInit {
  server: string;
  agreements = [];
  form_data = {
      email: '',
      firstname: '',
      lastname: '',
      city: '',
      gender: 0,
      birthday_date: undefined,
      phone: '',
      team: '',
      school: '',
      profession: ''
    };

  constructor(
    private location: Location,
    public http: StartService,
    public snackBar: MatSnackBar,
    private shared: SharedService,
    private router: Router,
    public errorHandler: ErrorHandler
  ) {
    this.server = `${shared.host}`;
  }

  ngOnInit() {
    $("body").removeClass('overflow-hidden');
    this.http.Auth('register_get')
    .subscribe(data => {
      this.agreements = data;
    }, err => {
      this.router.navigate(['/']);
      this.errorHandler.handleError(err);
    })
  }

  register = () => {
    if (!this.form_data.firstname) return this.snackBar.open('Podaj imię.', '', snackbar_options);
    if (!this.form_data.lastname) return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
    if (!this.form_data.gender) return this.snackBar.open('Wybierz płeć.', '', snackbar_options);
    if (!this.form_data.city) return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
    if (!this.form_data.birthday_date) return this.snackBar.open('Podaj datę urodzenia.', '', snackbar_options);
    if (!this.form_data.email) return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
    let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_mail.test(String(this.form_data.email).toLowerCase()))
      return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
    let re_phone = /^[\d\s]+$/;
    if (this.form_data.phone && !re_phone.test(String(this.form_data.phone)))
      return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
    if (this.form_data.phone && String(this.form_data.phone).replace(/[^0-9]/g, "").length < 9)
      return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);

      for (let i in this.agreements) {
        if (!this.agreements[i].checked)
          return this.snackBar.open('Zaznacz wszystkie zgody.', '', snackbar_options);
      }

      let params = {
        email: this.form_data.email,
        firstname: this.form_data.firstname,
        lastname: this.form_data.lastname,
        city: this.form_data.city,
        gender: this.form_data.gender,
        birthday_date: this.form_data.birthday_date,
        phone: this.form_data.phone
      }
      if (this.form_data.team) params['team'] = this.form_data.team;
      if (this.form_data.school) params['school'] = this.form_data.school;
      if (this.form_data.profession) params['profession'] = this.form_data.profession;
      this.http.Auth('register', params)
        .subscribe(data => {
          if (data.message)
            this.snackBar.open(data.message, '', snackbar_options);
          this.router.navigateByUrl('/login');
        }, err => {
          this.errorHandler.handleError(err);
        });
  }

}
