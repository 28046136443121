import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PanelComponent } from "./panel.component";
import { AccountComponent } from './account/account.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountAddEditContestantComponent } from './account-add-edit-contestant/account-add-edit-contestant.component';
import { InfoComponent } from './info/info.component';
import { ResourcesComponent } from './resources/resources.component';
import { UserMarathonsComponent } from './user-marathons/user-marathons.component';
import { UserMarathonsDetailsComponent } from './user-marathons-details/user-marathons-details.component';
import { MarathonSignComponent } from './marathon-sign/marathon-sign.component';

const routes: Routes = [
  {
    path: 'panel', component: PanelComponent, children: [
      { path: '', redirectTo: '/panel/konto', pathMatch: 'full'},
      { path: 'konto', component: AccountComponent},
      { path: 'konto/dodaj-zawodnika', component: AccountAddEditContestantComponent},
      { path: 'konto/edytuj-zawodnika/:id', component: AccountAddEditContestantComponent},
      { path: 'konto/:id', component: AccountEditComponent},
      { path: 'zapisy', component: MarathonSignComponent},
      { path: 'maratony', component: UserMarathonsComponent},
      { path: 'maraton/:id', component: UserMarathonsDetailsComponent},
      { path: 'portfel', component: ResourcesComponent},
      { path: 'portfel/:id', component: ResourcesComponent},
      { path: 'info', component: InfoComponent}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class PanelRoutingModule { }
