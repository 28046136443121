import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../shared.service";

@Injectable({
  providedIn: 'root'
})
export class PanelService {
  SERVER: string;

  constructor(public http: HttpClient, private shared: SharedService) {
    this.SERVER = `${shared.host}/api/user`;
  }

  User(action, params?): any {
    switch (action) {

      case 'panel_get':
        return this.http.get(`${this.SERVER}/user-data`);

      //KONTO
      case 'account_get':
        return this.http.get(`${this.SERVER}/user`);
      case 'account_details_get':
        return this.http.get(`${this.SERVER}/user/${params}`);
      case 'account_edit':
        return this.http.put(`${this.SERVER}/user`, params);
      case 'account_pass_edit':
        return this.http.put(`${this.SERVER}/user/password`, params);
      case 'account_delete':
        return this.http.put(`${this.SERVER}/user/delete`, params);

      case 'account_user_add':
        return this.http.post(`${this.SERVER}/user/sub-user`, params);
      case 'account_user_edit':
        return this.http.put(`${this.SERVER}/user/sub-user/${params.id}`, params.data);
      case 'account_user_delete':
        return this.http.delete(`${this.SERVER}/user/sub-user/${params.id}`);

      //ZAPISY NA MARATON
      case 'map_locations_get':
        return this.http.get(`${this.SERVER}/main/marathon/maps`);
      case 'map_search_tips_get':
        return this.http.post(`${this.SERVER}/main/marathon/search/place`, params);
      case 'map_marathons_get':
        return this.http.post(`${this.SERVER}/main/marathon/search`, params);

      case 'marathon_sign_details_get':
        return this.http.get(`${this.SERVER}/main/marathon/${params.id}${params.list}`);
      case 'marathon_sign_users_get':
        return this.http.get(`${this.SERVER}/main/marathon/${params.id}/register-system/${params.system}/users`);

      case 'marathon_sign_ind_get':
        return this.http.post(`${this.SERVER}/main/marathon/${params}/register-system/1`, {});
      case 'marathon_sign_family_get':
        return this.http.post(`${this.SERVER}/main/marathon/${params.id}/register-system/2`, params.data);
      case 'marathon_sign_coach_get':
        return this.http.get(`${this.SERVER}/main/marathon/${params.id}/register-system/3/distances`);
      case 'marathon_sign_coach_tracks_get':
        return this.http.post(`${this.SERVER}/main/marathon/${params.id}/register-system/3/tracks`, {});

      case 'marathon_sign_user_add':
        return this.http.post(`${this.SERVER}/user/sub-user?marathon_id=${params.id}`, params.data);

      case 'marathon_register':
        return this.http.post(`${this.SERVER}/main/marathon/${params.id}/register-system/${params.system}/save`, params.data);

      case 'marathon_sign_start_pack_get':
        return this.http.get(`${this.SERVER}/starter-pack/${params.id}`);
      case 'marathon_sign_start_pack_add':
        return this.http.post(`${this.SERVER}/starter-pack/${params.id}`, params.data);

      case 'marathon_sign_summary':
        return this.http.post(`${this.SERVER}/main/marathon/${params.id}/end-info`, params.data);

      //MOJE MARATONY
      case 'marathons_current_get':
        return this.http.get(`${this.SERVER}/marathons/current/${params}`);
      case 'marathons_past_get':
        return this.http.get(`${this.SERVER}/marathons/past/${params}`);
      case 'marathon_details_get':
        return this.http.get(`${this.SERVER}/marathon/${params}`);
      case 'marathon_details_list_person':
        return this.http.post(`${this.SERVER}/marathon/${params}/list-start/person`, {});
      case 'marathon_details_list_all':
        return this.http.post(`${this.SERVER}/marathon/${params}/list-start/all`, {});
      case 'marathon_details_user_list':
        return this.http.get(`${this.SERVER}/marathon/${params}/user-list`);
      case 'marathon_details_user_replace':
        return this.http.post(`${this.SERVER}/marathon/${params.id}`, params.data);

      //PORTFEL
      case 'hearts_history_get':
        return this.http.get(`${this.SERVER}/hearts/history/${params}`);
      case 'hearts_add':
        return this.http.post(`${this.SERVER}/hearts/buy`, params);
      case 'payments_history_get':
        return this.http.get(`${this.SERVER}/hearts/orders/${params}`);

      //INFORMACJE
      case 'informations_get':
        return this.http.get(`${this.SERVER}/page-info`);

      case 'logout':
        return this.http.post(`${this.shared.host}/api/logout`, {})
    }
  }
}
