import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.scss']
})
export class CoordinatorComponent implements OnInit {
  coordinators = [];
  displayedColumns = ['firstname', 'lastname', 'email', 'phone', 'id'];
  order = 'firstname';
  order_dir = 0;
  current_coordinators_page = 0;
  is_next_page_coordinators = false;
  loading_page = true;
  loading_coordinators = false;
  delete_main_loader = false;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    private router: Router,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_coordinators_list();
  }

  get_coordinators_list = () => {
    if (!this.delete_main_loader) {
      this.loading_coordinators = true;
      this.http.Admin('manager_list_get', {order: this.order, page: this.current_coordinators_page, order_dir: this.order_dir})
      .subscribe(data => {
        this.current_coordinators_page++;
        this.is_next_page_coordinators = data.next_page;
        this.coordinators = [...this.coordinators, ...data.managers];
        this.loading_coordinators = false;
        this.loading_page = false;
      }, err => {
        this.loading_page = false;
        this.loading_coordinators = false;
        this.errorHandler.handleError(err);
      });
    }
  }

  sort = (order) => {
    if (this.order == order)
      this.order_dir = this.order_dir == 0 ? 1 : 0;
    else
      this.order_dir = 0;

    this.order = order;
    this.coordinators = [];
    this.current_coordinators_page = 0;
    this.loading_page = true;
    this.is_next_page_coordinators = false;
    this.get_coordinators_list();
  }

  add_coordinator = () => {
    if (!this.delete_main_loader) {
      let dialogRef = this.dialog.open(CoordinatorDialogAddCoordinator, {
        disableClose: true,
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'saved') {
          this.coordinators = [];
          this.current_coordinators_page = 0;
          this.loading_page = true;
          this.is_next_page_coordinators = false;
          this.get_coordinators_list();
        }
      });
    }
  }

  goto_details = (id) => {
    if (!this.delete_main_loader)
      this.router.navigate(['/admin/koordynator/' + id]);
  }

  delete_coordinator = (id) => {
    if (!this.delete_main_loader) {
      this.delete_main_loader = true;
      let pos = this.coordinators.map((item) => {return item.id}).indexOf(id);
      this.coordinators[pos].loader = true;
      this.http.Admin('manager_details_get', {id: id})
      .subscribe(data => {
        let dialogRef = this.dialog.open(CoordinatorDialogDeleteCoordinator, {
          disableClose: true,
          width: '400px',
          data: {coordinator: data, has_marathons: data.marathons.length > 0}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'deleted') {
            this.coordinators = [];
            this.current_coordinators_page = 0;
            this.loading_page = true;
            this.is_next_page_coordinators = false;
            this.get_coordinators_list();
          }
        });
        this.coordinators[pos].loader = false;
        this.delete_main_loader = false;
      }, err => {
        this.coordinators[pos].loader = false;
        this.delete_main_loader = false;
        this.errorHandler.handleError(err);
      });
    }
  }
}

@Component({
  selector: 'coordinator-dialog-add-coordinator',
  templateUrl: 'dialog-add-coordinator.html',
  styleUrls: ['./coordinator.component.scss']
})
export class CoordinatorDialogAddCoordinator {
  loading = false;
  firstname = '';
  lastname = '';
  email = '';
  phone = '';

  constructor(
    public dialogRef: MatDialogRef<CoordinatorDialogAddCoordinator>,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  save = () => {
    if (this.firstname.length == 0) return this.snackBar.open('Podaj imię.', '', snackbar_options);
    if (this.lastname.length == 0) return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
    if (this.email.length == 0) return this.snackBar.open('Podaj e-mail.', '', snackbar_options);
    let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_mail.test(String(this.email).toLowerCase()))
      return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
    if (this.phone.length == 0) return this.snackBar.open('Podaj telefon.', '', snackbar_options);
    let re_phone = /^[\d\s]+$/;
    if (!re_phone.test(String(this.phone)))
      return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
    if (String(this.phone).replace(/[^0-9]/g, "").length < 9)
      return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);
    this.loading = true;
    this.http.Admin('manager_add', {firstname: this.firstname, lastname: this.lastname, email: this.email, phone: this.phone})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('saved');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}

@Component({
  selector: 'coordinator-dialog-delete-coordinator',
  templateUrl: 'dialog-delete-coordinator.html',
  styleUrls: ['./coordinator.component.scss']
})
export class CoordinatorDialogDeleteCoordinator {
  loading = false;
  coordinator = {id: 0};
  has_marathons = false;
  new_coordinator = '0';

  constructor(
    public dialogRef: MatDialogRef<CoordinatorDialogDeleteCoordinator>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {
    this.coordinator = this.data.coordinator;
    this.has_marathons = this.data.has_marathons;
  }

  ngOnInit() {}

  delete = () => {
    if (this.has_marathons && this.new_coordinator == '0')
      return this.snackBar.open('Wybierz nowego koordynatora', '', snackbar_options);
    let request = {id: this.coordinator.id};
    if (this.new_coordinator != '0')
      request['data'] = '?new_manager_id=' + parseInt(this.new_coordinator);
    else
      request['data'] = '';
    this.loading = true;
    this.http.Admin('manager_delete', request)
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}
