import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

import { StartModule } from './start/start.module';
import { PanelModule } from './panel/panel.module';
import { AdminModule } from './admin/admin.module';
import { ManagerModule } from './manager/manager.module';

import { PanelService } from './services/panel.service';
import { StartService } from './services/start.service';
import { AdminService } from './services/admin.service';
import { ManagerService } from './services/manager.service';
import { SharedService } from './shared.service';
import { ErrorHandler } from './services/errorHandler.service';

import { PipesModule } from './pipes/pipes.module';
import { NgxGalleryModule } from 'ngx-gallery';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    PipesModule,
    StartModule,
    PanelModule,
    AdminModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ManagerModule,
    NgxGalleryModule
  ],
  providers: [PanelService, StartService, AdminService, ManagerService, SharedService, ErrorHandler],
  bootstrap: [AppComponent]
})
export class AppModule { }
