import { Component, Inject, OnInit } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { PanelService } from '../../services/panel.service';
import { snackbar_options } from '../../variables';
import { PanelComponent } from '../panel.component';
import { ErrorHandler } from '../../services/errorHandler.service';

@Component({
  selector: 'marathon-sign-dialog-summary',
  templateUrl: 'dialog-summary.html',
  styleUrls: ['./marathon-sign.component.scss']
})
export class MarathonSignDialogSummary {
  data = {
    id: 0
  }

  constructor(
    public dialogRef: MatDialogRef<MarathonSignDialogSummary>,
    public http: PanelService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public errorHandler: ErrorHandler
  ) {
    this.data = dialog_data.data;
  }

  close = () => {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'marathon-sign-dialog-add-contestant',
  templateUrl: 'dialog-add-contestant.html',
  styleUrls: ['./marathon-sign.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MarathonSignDialogAddContestant {
  data = {
    firstname: '',
    lastname: '',
    gender: 0,
    city: '',
    birthday_date: null,
    email: '',
    phone: null,
    team: '',
    school: '',
    profession: ''
  };
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<MarathonSignDialogAddContestant>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public http: PanelService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  close = () => {
    this.dialogRef.close();
  }

  save = () => {
    if (this.data.firstname.length == 0)
      return this.snackBar.open('Podaj imię.', '', snackbar_options);
    if (this.data.lastname.length == 0)
      return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
    if (this.data.gender == 0)
      return this.snackBar.open('Wybierz płeć.', '', snackbar_options);
    if (this.data.city.length == 0)
      return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
    if (!this.data.birthday_date)
      return this.snackBar.open('Podaj datę urodzenia.', '', snackbar_options);
    if (this.data.email.length == 0)
      return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
    let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_mail.test(String(this.data.email).toLowerCase()))
      return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
    if (!this.data.phone)
      return this.snackBar.open('Podaj numer telefonu.', '', snackbar_options);
    let re_phone = /^[\d\s]+$/;
    if (!re_phone.test(String(this.data.phone)))
      return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
    if (String(this.data.phone).replace(/[^0-9]/g, "").length < 9)
      return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);

    this.loading = true;
    let request = {
      firstname: this.data.firstname,
      lastname: this.data.lastname,
      city: this.data.city,
      gender: this.data.gender,
      phone: String(this.data.phone),
      email: this.data.email,
      birthday_date: this.data.birthday_date,
      team: this.data.team ? this.data.team : '',
      school: this.data.school ? this.data.school : '',
      profession: this.data.profession ? this.data.profession : ''
    };
    this.http.User('marathon_sign_user_add', {id: this.dialog_data.marathon_id , data: request})
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close(data.new_users);
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });

  }
}

@Component({
  selector: 'marathon-sign-dialog-track-confirm',
  templateUrl: 'dialog-track-confirm.html',
  styleUrls: ['./marathon-sign.component.scss']
})
export class MarathonSignDialogTrackConfirm {
  loading = false;
  data: any;

  constructor(
    public dialogRef: MatDialogRef<MarathonSignDialogTrackConfirm>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public http: PanelService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {
    this.data = dataDialog;
  }

  close = () => {
    this.dialogRef.close('close');
  }

  save = () => {
    this.loading = true;
    this.http.User('marathon_register', this.data.request)
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        PanelComponent.returned.next();
        this.dialogRef.close('confirm');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-sign-dialog-no-hearts',
  templateUrl: 'dialog-no-hearts.html',
  styleUrls: ['./marathon-sign.component.scss']
})
export class MarathonSignDialogNoHearts {
  constructor(public dialogRef: MatDialogRef<MarathonSignDialogNoHearts>) {}

  close = () => {
    this.dialogRef.close('close');
  }
}
