import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-marathon',
  templateUrl: './marathon.component.html',
  styleUrls: ['./marathon.component.scss']
})
export class MarathonComponent implements OnInit {
  show_finished = false;
  marathons_all = [];
  marathons_actual = [];
  displayedColumns = ['name', 'city', 'poll_name', 'start_date', 'id'];
  order = 'start_date';
  order_dir = 1;
  current_marathons_page = 0;
  is_next_page = false;
  loading_page = true;
  loading_marathons = false;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_marathon_list();
  }

  get_marathon_list = () => {
      this.loading_marathons = true;
      this.http.Admin('marathons_get', {order: this.order, page: this.current_marathons_page, order_dir: this.order_dir})
      .subscribe(data => {
          let marathons_all = [];
          let marathons_actual = [];
          for (let i in data.marathons) {
              marathons_all.push(data.marathons[i]);
              if (!data.marathons[i].finished)
                marathons_actual.push(data.marathons[i]);
          }
          this.marathons_all = [...this.marathons_all, ...marathons_all];
          this.marathons_actual = [...this.marathons_actual, ...marathons_actual];
          this.current_marathons_page++;
          this.is_next_page = data.next_page;
          this.loading_marathons = false;
          this.loading_page = false;
      }, err => {
        this.loading_marathons = false;
        this.loading_page = false;
        this.errorHandler.handleError(err);
      });
  }

  sort = (order) => {
    if (this.order == order)
      this.order_dir = this.order_dir == 0 ? 1 : 0;
    else
      this.order_dir = 0;

    this.order = order;
    this.marathons_all = [];
    this.marathons_actual = [];
    this.current_marathons_page = 0;
    this.loading_page = true;
    this.is_next_page = false;
    this.get_marathon_list();
  }

  delete_marathon = (id) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteMarathon, {
      disableClose: true,
      width: '400px',
      data: {id: id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.marathons_all = [];
        this.marathons_actual = [];
        this.current_marathons_page = 0;
        this.loading_page = true;
        this.is_next_page = false;
        this.get_marathon_list();
      }
    });
  }

}

@Component({
  selector: 'marathon-dialog-delete-marathon',
  templateUrl: 'dialog-delete-marathon.html',
  styleUrls: ['./marathon.component.scss']
})
export class MarathonDialogDeleteMarathon {
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteMarathon>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  delete = () => {
    this.http.Admin('marathon_delete', {id: this.data.id})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.errorHandler.handleError(err);
    });

  }
}
