import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatTabChangeEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
  selectedIndex = 0;
  edit_men = false;
  men = [
    {pos: 1, name: '', cat: '', city: '', res: ''},
    {pos: 2, name: '', cat: '', city: '', res: ''},
    {pos: 3, name: '', cat: '', city: '', res: ''},
    {pos: 4, name: '', cat: '', city: '', res: ''},
    {pos: 5, name: '', cat: '', city: '', res: ''},
    {pos: 6, name: '', cat: '', city: '', res: ''},
    {pos: 7, name: '', cat: '', city: '', res: ''},
    {pos: 8, name: '', cat: '', city: '', res: ''},
    {pos: 9, name: '', cat: '', city: '', res: ''},
    {pos: 10, name: '', cat: '', city: '', res: ''}
  ];
  edit_women = false;
  women = [
    {pos: 1, name: '', cat: '', city: '', res: ''},
    {pos: 2, name: '', cat: '', city: '', res: ''},
    {pos: 3, name: '', cat: '', city: '', res: ''},
    {pos: 4, name: '', cat: '', city: '', res: ''},
    {pos: 5, name: '', cat: '', city: '', res: ''},
    {pos: 6, name: '', cat: '', city: '', res: ''},
    {pos: 7, name: '', cat: '', city: '', res: ''},
    {pos: 8, name: '', cat: '', city: '', res: ''},
    {pos: 9, name: '', cat: '', city: '', res: ''},
    {pos: 10, name: '', cat: '', city: '', res: ''}
  ];
  edit_pool = false;
  pool = [
    {pos: 1, name: '', city: '', res: ''},
    {pos: 2, name: '', city: '', res: ''},
    {pos: 3, name: '', city: '', res: ''},
    {pos: 4, name: '', city: '', res: ''},
    {pos: 5, name: '', city: '', res: ''},
    {pos: 6, name: '', city: '', res: ''},
    {pos: 7, name: '', city: '', res: ''},
    {pos: 8, name: '', city: '', res: ''},
    {pos: 9, name: '', city: '', res: ''},
    {pos: 10, name: '', city: '', res: ''}
  ];
  edit_club = false;
  club = [
    {pos: 1, name: '', city: '', res: ''},
    {pos: 2, name: '', city: '', res: ''},
    {pos: 3, name: '', city: '', res: ''},
    {pos: 4, name: '', city: '', res: ''},
    {pos: 5, name: '', city: '', res: ''},
    {pos: 6, name: '', city: '', res: ''},
    {pos: 7, name: '', city: '', res: ''},
    {pos: 8, name: '', city: '', res: ''},
    {pos: 9, name: '', city: '', res: ''},
    {pos: 10, name: '', city: '', res: ''}
  ];
  edit_team = false;
  team = [
    {pos: 1, name: '', city: '', res: ''},
    {pos: 2, name: '', city: '', res: ''},
    {pos: 3, name: '', city: '', res: ''},
    {pos: 4, name: '', city: '', res: ''},
    {pos: 5, name: '', city: '', res: ''},
    {pos: 6, name: '', city: '', res: ''},
    {pos: 7, name: '', city: '', res: ''},
    {pos: 8, name: '', city: '', res: ''},
    {pos: 9, name: '', city: '', res: ''},
    {pos: 10, name: '', city: '', res: ''}
  ];
  edit_profession = false;
  profession = [
    {pos: 1, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 2, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 3, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 4, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 5, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 6, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 7, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 8, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 9, job: '', name: '', cat: '', city: '', res: ''},
    {pos: 10, job: '', name: '', cat: '', city: '', res: ''}
  ];
  current_edited_ranking = [];

  loading = true;
  loading_save = false;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_ranking('men');
  }

  get_ranking = (type) => {
    this.loading = true;
    this.http.Admin('ranking_get_' + type)
    .subscribe(data => {
      if (data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      if (data && data['rank_' + type])
        this[type] = JSON.parse(data['rank_' + type]);
      this.loading = false;
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }

  setActiveTab = (event: MatTabChangeEvent) => {
    let current_page_name = 'men';
    if (event.index == 1) current_page_name = 'women';
    else if (event.index == 2) current_page_name = 'pool';
    else if (event.index == 3) current_page_name = 'club';
    else if (event.index == 4) current_page_name = 'team';
    else if (event.index == 5) current_page_name = 'profession';
    this.get_ranking(current_page_name);
    let current_page = this.selectedIndex;
    if ((this.edit_men || this.edit_women || this.edit_pool || this.edit_club || this.edit_team || this.edit_profession) && !this.loading_save) {
      let dialogRef = this.dialog.open(RankingDialogSaveRanking, {
        disableClose: true,
        data: {id: current_page}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.message == 'save') {
          this.save_ranking(current_page + 1);
        } else if (result.message == 'no_save') {
          this.edit_men = false;
          this.edit_women = false;
          this.edit_pool = false;
          this.edit_club = false;
          this.edit_team = false;
          this.edit_profession = false;
        }
      });
    }
    this.selectedIndex = event.index;
  }

  edit_ranking = (id) => {
    switch (id) {
      case 1:
        this.current_edited_ranking = this.men.map(x => Object.assign({}, x));
        this.edit_men = true; this.edit_women = false; this.edit_pool = false;
        this.edit_club = false; this.edit_team = false; this.edit_profession = false;
        break;
      case 2:
        this.current_edited_ranking = this.women.map(x => Object.assign({}, x));
        this.edit_women = true; this.edit_men = false; this.edit_pool = false;
        this.edit_club = false; this.edit_team = false; this.edit_profession = false;
        break;
      case 3:
        this.current_edited_ranking = this.pool.map(x => Object.assign({}, x));
        this.edit_pool = true; this.edit_men = false; this.edit_women = false;
        this.edit_club = false; this.edit_team = false; this.edit_profession = false;
        break;
      case 4:
        this.current_edited_ranking = this.club.map(x => Object.assign({}, x));
        this.edit_club = true; this.edit_men = false; this.edit_women = false;
        this.edit_pool = false; this.edit_team = false; this.edit_profession = false;
        break;
      case 5:
        this.current_edited_ranking = this.team.map(x => Object.assign({}, x));
        this.edit_team = true; this.edit_men = false; this.edit_women = false;
        this.edit_pool = false; this.edit_club = false; this.edit_profession = false;
        break;
      case 6:
        this.current_edited_ranking = this.profession.map(x => Object.assign({}, x));
        this.edit_profession = true; this.edit_men = false; this.edit_women = false;
        this.edit_pool = false; this.edit_club = false; this.edit_team = false;
        break;
    }
  }

  save_ranking_put = (type) => {
    let current_edited_ranking = this.current_edited_ranking.map(x => Object.assign({}, x));
    this.loading_save = true;
    this.http.Admin('ranking_edit', {type: type, content: JSON.stringify(current_edited_ranking)})
    .subscribe(data => {
      if (data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this[type] = current_edited_ranking;
      this['edit_' + type] = false;
      this.loading_save = false;
    }, err => {
      this.loading_save = false;
      this.errorHandler.handleError(err);
    });
  }

  save_ranking = (id) => {
    switch (id) {
      case 1: this.save_ranking_put('men'); break;
      case 2: this.save_ranking_put('women'); break;
      case 3: this.save_ranking_put('pool'); break;
      case 4: this.save_ranking_put('club'); break;
      case 5: this.save_ranking_put('team'); break;
      case 6: this.save_ranking_put('profession'); break;
    }
  }
}

@Component({
  selector: 'ranking-dialog-save-ranking',
  templateUrl: 'dialog-save-ranking.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingDialogSaveRanking {
  constructor(
    public dialogRef: MatDialogRef<RankingDialogSaveRanking>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  id = this.data.id;

  ngOnInit() {}

  close = (message) => {
    this.dialogRef.close({id: this.id, message: message});
  }
}
