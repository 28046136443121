import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatSnackBar, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';

import { MapsAPILoader } from '@agm/core';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-marathon-add',
  templateUrl: './marathon-add.component.html',
  styleUrls: ['./marathon-add.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MarathonAddComponent implements OnInit {

  marathon_start: any;
  marathon_end: any;
  register_end: any;
  name = '';
  pool = '';
  city = '';
  address = '';
  select_coordinator = 0;
  coordinators = [];
  place = '';
  set_coords = false;
  marathon_fees_amount = 0;

  start_packets = [];
  select_start_packet = 0;
  marathon_date_packet: any;

  marker: any;
  loading = false;

  file_name = '';
  doc: File;

  openInput = () => {
    document.getElementById("add-organise-input").click();
  }

  fileChange = (files: File[]) => {
    if (files.length > 0) {
      this.file_name = files[0].name;
      this.doc = files[0];
    }
  }

  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;

  @ViewChild("search")
  public searchElementRef: ElementRef;

  constructor(
    private location: Location,
    public http: AdminService,
    private router: Router,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.zoom = 5;
    this.latitude = 52.229676;
    this.longitude = 21.012229;
    this.searchControl = new FormControl();
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          let vicinity = place.vicinity.split(', ');
          this.address = place.vicinity;
          this.pool = place.name;
          this.city = vicinity[vicinity.length - 1];
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 18;
          this.set_coords = true;
        });
      });
    });
    this.http.Admin('marathons_coordinators_get')
      .subscribe(data => {
        if (data && data.managers && data.managers.length == 0) {
          this.snackBar.open('Dodaj koordynatora.', '', snackbar_options);
          this.router.navigate(['/admin/maraton']);
        } else {
          this.coordinators = data.managers;
          this.get_start_packet();
        }
      }, err => {
        this.router.navigate(['/admin/maraton']);
        this.errorHandler.handleError(err);
      });
  }

  get_start_packet = () => {
    this.http.Admin('packets_full_get')
      .subscribe(data => {
        this.start_packets = data.starter_pack;
      }, err => {
        this.router.navigate(['/admin/maraton']);
        this.errorHandler.handleError(err);
      });
  }

  markerDragEnd = (marker, event) => {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.set_coords = true;
  }

  isNaturalNumber = (n) => {
    n = n.toString();
    var n1 = Math.abs(n),
      n2 = parseInt(n, 10);
    return !isNaN(n1) && n2 === n1 && n1.toString() === n;
  }

  save_marathon = () => {
    if (!this.marathon_start) return this.snackBar.open('Podaj datę wydarzenia.', '', snackbar_options);
    if (!this.register_end) return this.snackBar.open('Podaj datę zamknięcia zapisów.', '', snackbar_options);
    if (this.name.length == 0) return this.snackBar.open('Podaj nazwę wydarzenia.', '', snackbar_options);
    if (this.pool.length == 0) return this.snackBar.open('Podaj nazwę obiektu.', '', snackbar_options);
    if (this.city.length == 0) return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
    if (this.address.length == 0) return this.snackBar.open('Podaj adres.', '', snackbar_options);
    if (!this.isNaturalNumber(this.marathon_fees_amount) || this.marathon_fees_amount < 0) return this.snackBar.open('Podaj wysokość opłaty startowej.', '', snackbar_options);
    if (this.select_coordinator == 0) return this.snackBar.open('Wybierz koordynatora.', '', snackbar_options);
    if (this.select_start_packet && !this.marathon_date_packet)
      return this.snackBar.open('Podaj termin, do którego można zakupić pakiety startowe.', '', snackbar_options);

    let formData: FormData = new FormData();
    formData.append('users_id', String(this.select_coordinator));
    formData.append('start_date', this.marathon_start);
    formData.append('end_date_marathon', this.marathon_end ? this.marathon_end : this.marathon_start);
    formData.append('end_date', this.register_end);
    formData.append('name', this.name);
    formData.append('poll_name', this.pool);
    formData.append('city', this.city);
    formData.append('address', this.address);
    formData.append('marathon_fees_amount', String(this.marathon_fees_amount));


    if (this.file_name)
      formData.append('marathon_statement', this.doc, this.doc.name);
    // let request = {
    //     users_id: this.select_coordinator,
    //     start_date: this.marathon_start,
    //     end_date: this.register_end,
    //     name: this.name,
    //     poll_name: this.pool,
    //     city: this.city,
    //     address: this.address
    // };
    if (this.set_coords) {
      formData.append('latitude', String(this.latitude));
      formData.append('longitude', String(this.longitude));
      // request['latitude'] = String(this.latitude);
      // request['longitude'] = String(this.longitude);
    }
    if (this.select_start_packet) {
      formData.append('starter_pack_id', String(this.select_start_packet));
      formData.append('starter_pack_date', this.marathon_date_packet);
      // request['starter_pack_id'] = this.select_start_packet;
      // request['starter_pack_date'] = this.marathon_date_packet;
    }
    this.loading = true;
    this.http.Admin('marathon_add', formData)
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.router.navigate([`/admin/maraton/dodaj-maraton/${data.id}`]);
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });

  }

  goBack = () => {
    this.router.navigate(['/admin/maraton']);
  }

}
