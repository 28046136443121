import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatTabChangeEvent, MatSnackBar } from '@angular/material';
import { PanelService } from '../../services/panel.service';
import { snackbar_options } from '../../variables';
import { ErrorHandler } from '../../services/errorHandler.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  hearts = 0;
  hearts_history = [];
  current_page_hearts = 0;
  is_next_page_hearts = false;
  payments_history = [];
  current_page_payments = 0;
  is_next_page_payments = false;
  loading_history = false;
  loading_payments = false;

  constructor(
    public dialog: MatDialog,
    public http: PanelService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    let href = window.location.pathname.split('/');
    if (href[href.length - 1] != 'portfel') {
      setTimeout(() => {
        this.dialog.open(ResourcesDialogAfterBuy, {
          disableClose: true,
          minWidth: '760px',
          width: '760px'
        });
      }, 5);
    }
    this.get_hearts_history();
  }

  get_hearts_history = () => {
    this.loading_history = true;
    this.http.User('hearts_history_get', this.current_page_hearts)
      .subscribe(data => {
          this.current_page_hearts++;
          this.is_next_page_hearts = data.isNextPage;
          this.hearts = data.hearts;
          this.hearts_history = [...this.hearts_history, ...data.data];
          this.loading_history = false;
      }, err => {
        this.show_error(err);
      });
  }

  get_payments_history = () => {
    this.loading_payments = true;
    this.http.User('payments_history_get', this.current_page_payments)
      .subscribe(data => {
          this.current_page_payments++;
          this.is_next_page_payments = data.isNextPage;
          this.payments_history = [...this.payments_history, ...data.data];
          this.loading_payments = false;
      }, err => {
        this.show_error(err);
      });
  }

  show_error = (err) => {
    this.loading_history = false;
    this.loading_payments = false;
    this.errorHandler.handleError(err);
  }

  setActiveTab = (event: MatTabChangeEvent) => {
    if (event.index == 0) {
      this.hearts_history = [];
      this.current_page_hearts = 0;
      this.is_next_page_hearts = false;
      return this.get_hearts_history();
    }
    if (event.index == 1) {
      this.payments_history = [];
      this.current_page_payments = 0;
      this.is_next_page_payments = false;
      return this.get_payments_history();
    }
  }

  buy_hearts = () => {
    this.dialog.open(ResourcesDialogBuyHearts, {
      disableClose: true,
      minWidth: '760px',
      width: '760px'
    });
  }
}

@Component({
  selector: 'resources-dialog-buy-hearts',
  templateUrl: 'dialog-buy-hearts.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesDialogBuyHearts {
  Math = Math;
  hearts_number = 1;
  vat = false;
  company = '';
  nip: '';
  regon: '';
  street: '';
  place_number: '';
  city: '';
  code: '';
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ResourcesDialogBuyHearts>,
    public http: PanelService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  hearts_number_add = () => {
    if (this.hearts_number >= 1)
      this.hearts_number = Math.floor(this.hearts_number + 1);
    else
      this.hearts_number = 1;
  }

  hearts_number_remove = () => {
    if (this.hearts_number > 1)
      this.hearts_number = Math.ceil(this.hearts_number - 1);
  }

  close = () => {
    this.dialogRef.close();
  }

  pay = () => {
    if (this.hearts_number < 1)
      return this.snackBar.open('Podaj liczbę serc.', '', snackbar_options);
    let request = {
      quantity: Math.floor(this.hearts_number)
    };
    if (this.vat) {
      if (!this.company)
        return this.snackBar.open('Podaj nazwę firmy.', '', snackbar_options);
      let re_nip = /^[ ]*([0-9][ ]*){10}$/;
      if (!re_nip.test(this.nip))
        return this.snackBar.open('Podaj NIP.', '', snackbar_options);
      if (!this.street)
        return this.snackBar.open('Podaj ulicę.', '', snackbar_options);
      if (!this.place_number)
        return this.snackBar.open('Podaj numer.', '', snackbar_options);
      if (!this.city)
        return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
      let re_code = /^[0-9]{2}\-[0-9]{3}$/;
      if (!re_code.test(this.code))
        return this.snackBar.open('Podaj kod pocztowy.', '', snackbar_options);
      request['vat'] = 1;
      request['name'] = this.company;
      request['street'] = this.street;
      request['house_number'] = this.place_number;
      request['city'] = this.city;
      request['zip_code'] = this.code;
      request['nip'] = this.nip;
      if (this.regon) request['regon'] = this.regon;
    } else
      request['vat'] = 0;
    this.loading = true;
    this.http.User('hearts_add', request)
      .subscribe(data => {
        if (data.status)
          document.location.href = data.link;
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'resources-dialog-after-buy',
  templateUrl: 'dialog-after-buy.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesDialogAfterBuy {
  constructor(
    public dialogRef: MatDialogRef<ResourcesDialogAfterBuy>
  ) { }

  close = () => {
    this.dialogRef.close();
  }
}
