import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent, MatSnackBar } from '@angular/material';
import { PanelService } from '../../services/panel.service';
import { snackbar_options } from '../../variables';
import { ErrorHandler } from '../../services/errorHandler.service';

@Component({
  selector: 'app-user-marathons',
  templateUrl: './user-marathons.component.html',
  styleUrls: ['./user-marathons.component.scss']
})
export class UserMarathonsComponent implements OnInit {
  current_marathons = [];
  current_marathons_page = 0;
  current_marathons_next_page = false;
  past_marathons = [];
  past_marathons_page = 0;
  past_marathons_next_page = false;
  loading_current = false;
  loading_past = false;

  constructor(
    public http: PanelService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_current_marathons();
  }

  get_current_marathons = () => {
    this.loading_current = true;
    this.http.User('marathons_current_get', this.current_marathons_page)
      .subscribe(data => {
          this.current_marathons_page++;
          this.current_marathons_next_page = data.isNextPage;
          this.current_marathons = [...this.current_marathons, ...data.marathon];
          this.loading_current = false;
      }, err => {
        this.show_error(err);
      });
  }

  get_past_marathons = () => {
    this.loading_past = true;
    this.http.User('marathons_past_get', this.past_marathons_page)
      .subscribe(data => {
          this.past_marathons_page++;
          this.past_marathons_next_page = data.isNextPage;
          this.past_marathons = [...this.past_marathons, ...data.marathon];
          this.loading_past = false;
      }, err => {
        this.show_error(err);
      });
  }

  show_error = (err) => {
    this.loading_current = false;
    this.loading_past = false;
    this.errorHandler.handleError(err);
  }

  setActiveTab = (event: MatTabChangeEvent) => {
    if (event.index == 0) {
      this.current_marathons = [];
      this.current_marathons_page = 0;
      this.current_marathons_next_page = false;
      return this.get_current_marathons();
    }
    if (event.index == 1) {
      this.past_marathons = [];
      this.past_marathons_page = 0;
      this.past_marathons_next_page = false;
      return this.get_past_marathons();
    }
  }

}
