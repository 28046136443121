import { Component, OnInit, AfterContentInit } from '@angular/core';
import { StartService } from '../../services/start.service';
import { SharedService } from '../../shared.service';
import { TweenMax } from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import $ from 'jquery';
import 'slick-carousel';
import Rellax from 'rellax';
import { MatSnackBar, MatDialog } from '@angular/material';
import { snackbar_options } from '../../variables';
import { ErrorHandler } from '../../services/errorHandler.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryPreviewComponent } from 'ngx-gallery';
import { DomSanitizer } from '@angular/platform-browser';
declare let html2canvas: any;

//nadpisujemy gallerię żeby móc wyświetlić film YT w niej
//dodane przez KM
// NgxGalleryPreviewComponent['decorators'][0].args[0].template = '';
const modificationGallery = () => {
  let new_content = `
    <div *ngIf="description && description.html && isOpen"
      class="ngx-gallery-preview-img ngx-gallery-center custome-gallery-preview"
      (click)="$event.stopPropagation()"
      (mouseenter)="imageMouseEnter()"
      (mouseleave)="imageMouseLeave()"
      (mousedown)="mouseDownHandler($event)"
      (touchstart)="mouseDownHandler($event)"
      [class.ngx-gallery-active]="!loading"
      [class.animation]="animation"
      [class.ngx-gallery-grab]="canDragOnZoom()"
      [style.transform]="getTransform()"
      [style.left]="positionLeft + 'px'"
      [style.top]="positionTop + 'px'"
      style="display: flex; justify-content: center; align-items: center;"
    >
      <!-- wczytujemy zdjecie żeby biblioteka myślała że zdjecie sie już załadowało -->
      <img src="assets/img/logo_top.png" #previewImage style="display: none;"/>
      <div [innerHTML]="description.html"></div>
    </div>

    <div *ngIf="description && description.video && isOpen"
      class="ngx-gallery-preview-img ngx-gallery-center custome-gallery-preview"
      (click)="$event.stopPropagation()"
      (mouseenter)="imageMouseEnter()"
      (mouseleave)="imageMouseLeave()"
      (mousedown)="mouseDownHandler($event)"
      (touchstart)="mouseDownHandler($event)"
      [class.ngx-gallery-active]="!loading"
      [class.animation]="animation"
      [class.ngx-gallery-grab]="canDragOnZoom()"
      [style.transform]="getTransform()"
      [style.left]="positionLeft + 'px'"
      [style.top]="positionTop + 'px'"
      style="display: flex; justify-content: center; align-items: center;"
    >
      <!-- wczytujemy zdjecie żeby biblioteka myślała że zdjecie sie już załadowało -->
      <img src="assets/img/logo_top.png" #previewImage style="display: none;"/>
      <video controls>
        <source [src]="description.video" type="video/mp4" style="max-width: 70%;">
        Video not supported
      </video>
    </div>
    
    <img 
    *ngIf="description && description.src && isOpen"
    [src]="description.src"
    #previewImage 
    class="ngx-gallery-preview-img ngx-gallery-center custome-gallery-preview"
    (click)="$event.stopPropagation()" 
    (mouseenter)="imageMouseEnter()" 
    (mouseleave)="imageMouseLeave()" 
    (mousedown)="mouseDownHandler($event)" 
    (touchstart)="mouseDownHandler($event)" 
    [class.ngx-gallery-active]="!loading" 
    [class.animation]="animation" 
    [class.ngx-gallery-grab]="canDragOnZoom()" 
    [style.transform]="getTransform()" 
    [style.left]="positionLeft + 'px'" 
    [style.top]="positionTop + 'px'"/>
  `;

  let new_description_div = `
  <div 
    class="ngx-gallery-preview-text"
    *ngIf="description && description.desc"
    [innerHTML]="description.desc"
    (click)="$event.stopPropagation()"></div>
  `;

  NgxGalleryPreviewComponent['decorators'][0].args[0].template = NgxGalleryPreviewComponent['decorators'][0].args[0].template.replace(/<img.*?\/>/, new_content);
  NgxGalleryPreviewComponent['decorators'][0].args[0].template = NgxGalleryPreviewComponent['decorators'][0].args[0].template.replace(/(<div[^>]*class="[">]*\bngx-gallery-preview-text\b[">]*"[^>]*>)([\s\S]*?)(<\/div>)/, new_description_div);

}

modificationGallery();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentInit {
  server: string;
  data = {
    contents: undefined,
    counter_status: undefined,
    rank_men: undefined,
    rank_women: undefined,
    rank_pool: undefined,
    rank_club: undefined,
    rank_team: undefined,
    rank_profession: undefined,
    documents: undefined,
    faq: undefined,
    slider_1: undefined,
    slider_2: undefined,
    user_type: -1
  };
  form_data = {
    type: 3,
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    subject: '',
    content: '',
    pool_name: '',
    pool_address: '',
    city: ''
  };
  form_loader = false;
  cv_name = '';
  cv: File;
  letter_name = '';
  letter: File;

  current_ranking = 1;
  current_ranking_tab = [];

  show_mobile_menu_bool = false;

  top_position = true;

  playing_video = false;

  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: any[];

  constructor(
    public http: StartService,
    public snackBar: MatSnackBar,
    private shared: SharedService,
    public errorHandler: ErrorHandler,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer
  ) {
    this.server = `${shared.host}`;

  }

  ngAfterContentInit() {
    const parallaxConfig = {
      speed: 3
    };
    new Rellax('.swimmer-man', parallaxConfig);
    new Rellax('.swimmer-woman', parallaxConfig);
    new Rellax('.swimmer-boy', parallaxConfig);
    new Rellax('.swimmer-girl', parallaxConfig);
    new Rellax('.swimmer-man2', parallaxConfig);

  }

  ngAfterViewInit() { }

  ngOnInit() {

    document.addEventListener('scroll', () => {
      const top_position = window.scrollY < 60;
      if (top_position != this.top_position) {
        let menu = document.getElementById('menu');
        let menu_right = document.getElementById('menu_right');
        let menu_image = document.getElementById('menu_image');
        if (top_position) {
          TweenMax.to(menu, 0.5, { backgroundColor: 'rgba(0,0,0,0)', height: 115 });
          TweenMax.to(menu_image, 0.5, { height: 105 });
          TweenMax.to(menu_right, 0.5, { top: 0 });
        } else {
          TweenMax.to(menu, 0.5, { backgroundColor: 'rgba(0,0,0,0.8)', height: 70 });
          TweenMax.to(menu_right, 0.5, { top: -55 });
          TweenMax.to(menu_image, 0.5, { height: 60 });
        };
        this.top_position = top_position;
      }
    });
    // setTimeout(() => {
    const slick_settings = (type) => {
      return {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: type === 'sponsors' ? $('.slick-sponsors-next') : $('.slick-institution-next'),
        prevArrow: type === 'sponsors' ? $('.slick-sponsors-prev') : $('.slick-institution-prev'),
        responsive: [
          {
            breakpoint: 1040,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 560,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
    // }, 5000);


    // wheels animation
    let wheel = document.querySelector('.wheel');
    let wheel2 = document.querySelector('.wheel2');

    let animWheel = (type, bezier) => {
      TweenMax.fromTo(type === 1 ? wheel : wheel2, type === 1 ? 20 : 15, {
        ease: 'Power1'
      }, {
        rotation: type === 1 ? '+=50' : '-=150',
        bezier: bezier,
        scale: type === 1 ? 1 : .7,
        repeat: -1,
        yoyo: true,
        ease: 'Power1.easeOut'
      });
    };
    setTimeout(() => {
      animWheel(1, [{ x: 50, y: 50 }, { x: 80, y: -30 }, { x: 40, y: 10 }]);
      animWheel(2, [{ x: -100, y: 70 }, { x: -200, y: -50 }, { x: -100, y: -70 }, { x: 0, y: 0 }]);
    }, 1000);
    this.http.Auth('start_get')
      .subscribe(async (data: any) => {
        this.data = data;

        // this.data.user_type = 1;
        this.current_ranking_tab = data.rank_men;
        setTimeout(() => {
          $('.slick-sponsors').slick(slick_settings('sponsors'));
          $('.slick-institution').slick(slick_settings('institution'));
          $('#sponsors').find('.no-slider').addClass('no-slider-' + (data.slider_1.length > 5 ? 5 : data.slider_1.length));
          $('#sponsors').find('.slider-wrapper').addClass('slider-wrapper-' + (data.slider_1.length > 5 ? 5 : data.slider_1.length));
          $('#institution').find('.no-slider').addClass('no-slider-' + (data.slider_2.length > 5 ? 5 : data.slider_2.length));
          $('#institution').find('.slider-wrapper').addClass('slider-wrapper-' + (data.slider_2.length > 5 ? 5 : data.slider_2.length));
        }, 200);

        this.galleryImages = [];
        if (data.gallery) {
          for (let g of data.gallery) {
            let src;
            let html;
            let video;
            let prewiew = 'assets/img/logo_top.png';

            if (g.type == 3) {
              html = this._sanitizer.bypassSecurityTrustHtml(g.src);
              // continue;
              try {
                prewiew = await this.getHtmlCover(g.src);
                // console.log(prewiew)
              } catch (err) {
                console.log(err);
              }

            }
            else if (g.type == 2) {
              video = `${this.server}/gallery/${g.src}`;
              try {
                prewiew = await this.getVideoCover(video);
              } catch (err) {
                console.log(err);
              }
            }
            else {
              //dla zdjęć i video
              src = `${this.server}/gallery/${g.src}`;
              prewiew = src;
            }

            this.galleryImages.push({

              small: prewiew,
              medium: prewiew,
              big: prewiew,

              description: {
                desc: g.desc ? this._sanitizer.bypassSecurityTrustHtml(g.desc) : null,
                type: g.type,
                html: html,
                video: video,
                src: src
              }
            })
          }
        }


      }, err => {
        this.errorHandler.handleError(err);
      });


    this.galleryOptions = [
      {},
      { breakpoint: 500, width: "300px", height: "300px", thumbnailsColumns: 3 },
      { breakpoint: 300, width: "100%", height: "200px", thumbnailsColumns: 2 }
    ];
  }

  // goto_section = (section, time) => {
  //   console.log(section)
  //   TweenMax.to(window, time ? 0 : .8, {scrollTo: {y: '#rank-section', offsetY: 40},  ease: Power4.easeOut});
  // }

  slideToggle(index): void {
    let elements = Array.from(document.querySelectorAll('.faq-content-box'));
    if (elements.indexOf(elements[index]) === Number(index)) {
      elements[index].classList.toggle('active');

      const child = elements[index].children[1];
      child.classList.toggle('hidden');
      // @ts-ignore
      child.style.height = `${child.scrollHeight}px`;
    }
  }

  imageSlide() {
    // TweenMax
  }

  show_mobile_menu = (type) => {
    if (type) {
      this.show_mobile_menu_bool = true;
      $("body").addClass('overflow-hidden');
    } else {
      this.show_mobile_menu_bool = false;
      $("body").removeClass('overflow-hidden');
    }
  }

  show_ranking = (id) => {
    this.current_ranking = id;
    switch (id) {
      case 1:
        return this.current_ranking_tab = this.data.rank_men;
      case 2:
        return this.current_ranking_tab = this.data.rank_women;
      case 3:
        return this.current_ranking_tab = this.data.rank_pool;
      case 4:
        return this.current_ranking_tab = this.data.rank_club;
      case 5:
        return this.current_ranking_tab = this.data.rank_team;
      case 6:
        return this.current_ranking_tab = this.data.rank_profession;
    }
  }

  play_video = () => {
    let video_bcg = <HTMLVideoElement>document.getElementById('video_background');
    video_bcg.play();
    this.playing_video = true;
  }

  pause_video = () => {
    let video_bcg = <HTMLVideoElement>document.getElementById('video_background');
    video_bcg.pause();
    this.playing_video = false;
  }

  openCvInput = () => {
    document.getElementById("add-cv-input").click();
  }

  fileCvChange = (files: File[]) => {
    if (files.length > 0) {
      this.cv_name = files[0].name;
      this.cv = files[0];
    }
  }

  openLetterInput = () => {
    document.getElementById("add-letter-input").click();
  }

  fileLetterChange = (files: File[]) => {
    if (files.length > 0) {
      this.letter_name = files[0].name;
      this.letter = files[0];
    }
  }

  send_form = () => {
    if (!this.form_data.firstname) return this.snackBar.open('Podaj imię.', '', snackbar_options);
    if (!this.form_data.lastname) return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
    if (!this.form_data.email) return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
    let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_mail.test(String(this.form_data.email).toLowerCase()))
      return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
    let re_phone = /^[\d\s]+$/;
    if (this.form_data.phone && !re_phone.test(String(this.form_data.phone)))
      return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
    if (this.form_data.phone && String(this.form_data.phone).replace(/[^0-9]/g, "").length < 9)
      return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);
    if (!this.form_data.type) return this.snackBar.open('Wybierz typ wiadomości.', '', snackbar_options);
    if (!this.form_data.subject) return this.snackBar.open('Podaj temat wiadomości.', '', snackbar_options);
    if (!this.form_data.content) return this.snackBar.open('Podaj treść wiadomości.', '', snackbar_options);
    if (this.form_data.type == 1) {
      if (!this.form_data.city) return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
      if (!this.form_data.pool_name) return this.snackBar.open('Podaj nazwę obiektu.', '', snackbar_options);
      if (!this.form_data.pool_address) return this.snackBar.open('Podaj adres obiektu.', '', snackbar_options);
    } else if (this.form_data.type == 2) {
      if (!this.form_data.city) return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
      if (!this.cv_name) return this.snackBar.open('Dodaj CV.', '', snackbar_options);
      if (!this.letter_name) return this.snackBar.open('Dodaj list motywacyjny.', '', snackbar_options);
    }

    let formData: FormData = new FormData();
    formData.append('firstname', this.form_data.firstname);
    formData.append('lastname', this.form_data.lastname);
    formData.append('email', this.form_data.email);
    if (this.form_data.phone)
      formData.append('phone', this.form_data.phone);
    formData.append('type', String(this.form_data.type));
    formData.append('subject', this.form_data.subject);
    formData.append('content', this.form_data.content);
    if (this.form_data.type == 1) {
      formData.append('city', this.form_data.city);
      formData.append('pool_name', this.form_data.pool_name);
      formData.append('pool_address', this.form_data.pool_address);
    } else if (this.form_data.type == 2) {
      formData.append('city', this.form_data.city);
      formData.append('cv_file', this.cv, this.cv.name);
      formData.append('letter_file', this.letter, this.letter.name);
    }
    this.form_loader = true;
    this.http.Auth('send_form', formData)
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.form_data = {
          type: 3,
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          subject: '',
          content: '',
          pool_name: '',
          pool_address: '',
          city: ''
        };
        this.form_loader = false;
      }, err => {
        this.form_loader = false;
        this.errorHandler.handleError(err);
      });
  }


  public getVideoCover(file: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let seekTo = 0;
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', file);
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        return reject("error when loading video file");
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          let url = ctx.canvas.toDataURL("image/jpeg");
          return resolve(url);
        });
      });
    });
  }

  public getHtmlCover(html: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let yt_id_regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;

      let yt_id = html.match(yt_id_regex)[0];

      if (yt_id) {
        let tmp = yt_id.split('/');
        if (tmp && tmp.length) {
          yt_id = tmp[tmp.length - 1];
        }
      }
      return resolve(`//img.youtube.com/vi/${yt_id}/hqdefault.jpg`);

    });
  }

}
