import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StartComponent } from "./start.component";
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FoundationComponent } from './foundation/foundation.component';

const routes: Routes = [
  {
    path: '', component: StartComponent, children: [
      { path: '', component: HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'login/:id', component: LoginComponent },
      { path: 'rejestracja', component: RegisterComponent },
      { path: 'fundacja', component: FoundationComponent },
      { path: 'fundacja/:session_id', component: FoundationComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class StartRoutingModule { }
