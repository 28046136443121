import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatTabChangeEvent, MatDialog, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AdminService } from '../../services/admin.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { TweenLite } from "gsap";
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorHandler } from '../../services/errorHandler.service';

import { SharedService } from "../../shared.service";

import { snackbar_options } from '../../variables';

import { MarathonDialogAddResult, MarathonDialogDeleteResult, MarathonDialogAddTrack, MarathonDetailsDialogAddCategory, MarathonDetailsDialogAddDistance, MarathonDialogAddUser, MarathonDialogDeleteUser, MarathonDialogChangeTrackSystem, MarathonDialogEditPacket } from './dialogs';

@Component({
  selector: 'app-marathon-details',
  templateUrl: './marathon-details.component.html',
  styleUrls: ['./marathon-details.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MarathonDetailsComponent implements OnInit {
  server: string;

  add_marathon = false; //true - po wejściu bezpośrednio po dodaniu wydarzenia
  selectedIndex = 0;
  marathon_id = 0;

  //DANE
  edit_data = false;
  data = {
    start_date: undefined,
    end_date_marathon: undefined,
    end_date: undefined,
    name: '',
    poll_name: '',
    city: '',
    address: '',
    longitude: 0,
    latitude: 0,
    status: null,
    starter_pack_date: undefined,
    starter_pack_id: 0,
    statement_name: '',
    marathon_fees_amount: 0
  };
  data_edited = {
    start_date: undefined,
    end_date_marathon: undefined,
    end_date: undefined,
    name: '',
    poll_name: '',
    city: '',
    address: '',
    longitude: 21.012229,
    latitude: 52.229676,
    status: null,
    starter_pack_date: undefined,
    starter_pack_id: 0,
    marathon_fees_amount: 0
  };
  counter_status = false;

  selectedTrackHour = '-1';
  track_hours = [];
  tracks = [];
  register_system_1 = { value: -1 };
  register_system_2 = {};
  register_system_3 = {};

  distances = [];
  categories = [];
  users = [];

  //LISTY
  selectedList = '-1';
  file_to_save = null;

  list_all = '';
  loading_list_all = false;
  list_person = '';
  loading_list_person = false;

  //WYNIKI
  results = [];
  distance = null;
  edit_distance = false;

  //KOORDYNATOR
  selectedCoordinator = 0;
  current_coordinator = [{ id: 0, firstname: '', lastname: '', email: '', phone: '' }];
  coordinators = [];
  edit_coordinator = false;

  marathon_packet_users = [];
  marathon_packet_users_page = 0;
  marathon_packet_users_next_page = false;
  marathon_packet_users_loader = false;
  displayedColumns = ['firstname', 'lastname', 'email', 'quantity', 'id'];

  tab1_loader = true;
  tab1_marathon_status_loader = false;
  tab1_save_loader = false;
  tab2_loader = true;
  tracks_loader = false;
  tab3_loader = true;
  tab4_loader = true;
  edit_distance_loader = false;
  tab5_loader = true;
  tab5_save_loader = false;
  tab6_loader = true;

  start_packets: any;

  file_name = '';
  doc: File;

  openInput = () => {
    document.getElementById("add-organise-input").click();
  }
  fileChange = (files: File[]) => {
    if (files.length > 0) {
      this.file_name = files[0].name;
      this.doc = files[0];
    }
  }

  public searchControl: FormControl;
  public zoom: number = 5;

  @ViewChild("search")
  public searchElementRef: ElementRef;


  constructor(
    public dialog: MatDialog,
    private location: Location,
    private router: Router,
    public http: AdminService,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private shared: SharedService,
    private sanitizer: DomSanitizer,
    public errorHandler: ErrorHandler
  ) {
    this.server = `${shared.host}`;
  }

  ngOnInit() {
    let href = window.location.pathname.split('/');
    this.marathon_id = parseInt(href[href.length - 1]);
    if (href[href.length - 2] == 'dodaj-maraton') {
      this.add_marathon = true;
      this.selectedIndex = 1;
      this.get_hours();
    }
    this.searchControl = new FormControl();
    this.mapsAPILoader.load();
    this.get_details();
  }

  get_start_packet = () => {
    this.http.Admin('packets_full_get')
      .subscribe(data => {
        this.start_packets = data.starter_pack;
      }, err => {
        this.edit_data = false;
        this.errorHandler.handleError(err);
      });
  }

  markerDragEnd = (marker, event) => {
    this.data_edited.latitude = event.coords.lat;
    this.data_edited.longitude = event.coords.lng;
  }

  get_details = () => {
    this.tab1_loader = true;
    this.http.Admin('marathon_details_get', { id: this.marathon_id })
      .subscribe(data => {
        data.latitude = parseFloat(data.latitude);
        data.longitude = parseFloat(data.longitude);
        data.status = data.status == 1;
        if (data.latitude && data.longitude)
          this.zoom = 18;
        if (!data.starter_pack_id)
          data.starter_pack_id = 0;
        this.data = Object.assign({}, data);
        this.edit_data = false;
        this.distance = data.distance;
        this.edit_distance = false;
        this.tab1_loader = false;
      }, err => {
        this.tab1_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  updateMarathonCounter = (event) => {
    this.tab1_marathon_status_loader = true;
    this.http.Admin('marathon_details_set_visibility', {
      marathon_id: this.marathon_id,
      status: event.checked ? 1 : 0
    })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.data.status = event.checked;
        this.tab1_marathon_status_loader = false;
      }, err => {
        this.data.status = !event.checked;
        this.tab1_marathon_status_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  get_hours = () => {
    this.tab2_loader = true;
    this.tab3_loader = true;
    this.http.Admin('marathon_hours_get', { id: this.marathon_id })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.track_hours = data.group_hours;
        this.tab2_loader = false;
        this.tab3_loader = false;
      }, err => {
        this.tab2_loader = false;
        this.tab3_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  get_tracks = (event) => {
    console.log(event);
    let events;
    if (event.value)
      events = event.value.split(" ");
    this.tracks_loader = true;
    this.http.Admin('marathon_tracks_get', {
      id: this.marathon_id,
      start_time: events ? events[1] : '',
      start_date: events ? events[0] : ''
    })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        for (let i in data.tracks) {
          data.tracks[i].status = data.tracks[i].status == 1;
        }
        this.register_system_1 = data.register_system_1;
        this.register_system_2 = data.register_system_2;
        this.register_system_3 = data.register_system_3;
        this.tracks = data.tracks;
        this.tracks_loader = false;
      }, err => {
        this.errorHandler.handleError(err);
        this.tracks_loader = false;
      });
  }

  save_start_list = () => {
    if (this.selectedList == '-1')
      return this.snackBar.open('Wybierz listę startową.', '', snackbar_options);
    let events;
    if (this.selectedList.length > 1) {
      events = this.selectedList.split(" ");
    }
    this.loading_list_all = true;
    this.http.Admin('marathon_list_start_save', {
      id: this.marathon_id, data: {
        start_time: this.selectedList == '0' ? '' : events[1],
        start_date: this.selectedList == '0' ? '' : events[0]
      }
    })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.list_all = data.file;
        this.loading_list_all = false;
        // this.file_to_save = this.sanitizer.bypassSecurityTrustUrl(this.server + '/pdf/' + data.file);
        // setTimeout(() => {
        //     document.getElementById("save-list-file").click();
        // }, 500);
      }, err => {
        this.loading_list_all = false;
        this.errorHandler.handleError(err);
      });
  }

  save_user_list = () => {
    this.loading_list_person = true;
    this.http.Admin('marathon_list_user_save', { id: this.marathon_id })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.list_person = data.file;
        this.loading_list_person = false;
        // this.file_to_save = this.sanitizer.bypassSecurityTrustUrl(this.server + '/csv/' + data.file);
        // setTimeout(() => {
        //     document.getElementById("save-list-file").click();
        // }, 500);
      }, err => {
        this.loading_list_person = false;
        this.errorHandler.handleError(err);
      });
  }

  get_results = () => {
    this.tab4_loader = true;
    this.http.Admin('marathon_result_get', { id: this.marathon_id })
      .subscribe(data => {
        // if (data.message)
        //     this.snackBar.open(data.message, '', snackbar_options);
        this.results = data.files;
        this.tab4_loader = false;
      }, err => {
        this.tab4_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  setActiveTab = (event: MatTabChangeEvent) => {
    this.selectedIndex = event.index;
    switch (event.index) {
      case 0:
        this.get_details();
        break;
      case 1:
        this.get_hours();
        break;
      case 2:
        this.get_hours();
        break;
      case 3:
        this.get_results();
        break;
      case 4:
        this.tab5_loader = true;
        this.http.Admin('marathon_coordinator', { id: this.marathon_id })
          .subscribe(data => {
            if (data.message)
              this.snackBar.open(data.message, '', snackbar_options);
            this.current_coordinator = [data];
            this.tab5_loader = false;
          }, err => {
            this.tab5_loader = false;
            this.errorHandler.handleError(err);
          });
        break;
      case 5:
        this.marathon_packet_users_page = 0;
        this.marathon_packet_users = [];
        this.get_packet_users();
        break;
    }
  }

  get_packet_users = () => {
    this.marathon_packet_users_loader = true;
    this.http.Admin('marathon_packet_users', { id: this.marathon_id, page: this.marathon_packet_users_page })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.marathon_packet_users_page++;
        this.marathon_packet_users = [...this.marathon_packet_users, ...data.user_list.rows];
        this.marathon_packet_users_loader = false;
        this.marathon_packet_users_next_page = data.next_page;
      }, err => {
        this.marathon_packet_users_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  edit_user_packet = (id, quantity) => {
    let dialogRef = this.dialog.open(MarathonDialogEditPacket, {
      disableClose: true,
      width: '500px',
      data: { id: this.marathon_id, user_id: id, quantity }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.marathon_packet_users_page = 0;
        this.marathon_packet_users = [];
        this.get_packet_users();
      }
    });
  }

  edit = (id) => {
    switch (id) {
      case 1:
        this.doc = undefined;
        this.file_name = this.data.statement_name;
        this.data_edited = Object.assign({}, this.data);
        if (!this.data.longitude || !this.data.latitude) {
          this.data_edited.latitude = 52.229676;
          this.data_edited.longitude = 21.012229;
        }
        this.edit_data = true;
        setTimeout(() => {
          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              if (place.vicinity) {
                let vicinity = place.vicinity.split(', ');
                this.data_edited.city = vicinity[vicinity.length - 1];
              } else {
                this.data_edited.city = '';
              }
              this.data_edited.address = place.vicinity;
              this.data_edited.poll_name = place.name;
              this.data_edited.latitude = place.geometry.location.lat();
              this.data_edited.longitude = place.geometry.location.lng();
              this.zoom = 18;
            });
          });
        }, 1000);
        this.get_start_packet();
        break;
      case 5:
        this.tab5_save_loader = true;
        this.http.Admin('marathons_coordinators_get')
          .subscribe(data => {
            if (data && data.message) {
              this.snackBar.open(data.message, '', snackbar_options);
            }
            this.coordinators = data.managers;
            this.selectedCoordinator = this.current_coordinator[0].id;
            this.tab5_save_loader = false;
            this.edit_coordinator = true;
          }, err => {
            this.tab5_save_loader = false;
            this.errorHandler.handleError(err);
          });
        break;
    }
  }

  isNaturalNumber = (n) => {
    n = n.toString();
    var n1 = Math.abs(n),
      n2 = parseInt(n, 10);
    return !isNaN(n1) && n2 === n1 && n1.toString() === n;
  }

  save = (id) => {
    switch (id) {
      case 1:
        if (!this.data_edited.start_date)
          return this.snackBar.open('Podaj datę wydarzenia.', '', snackbar_options);
        if (!this.data_edited.end_date)
          return this.snackBar.open('Podaj datę zamknięcia zapisów.', '', snackbar_options);
        if (this.data_edited.name.length == 0)
          return this.snackBar.open('Podaj nazwę wydarzenia.', '', snackbar_options);
        if (this.data_edited.poll_name.length == 0)
          return this.snackBar.open('Podaj nazwę obiektu.', '', snackbar_options);
        if (this.data_edited.city.length == 0)
          return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
        if (this.data_edited.address.length == 0)
          return this.snackBar.open('Podaj adres.', '', snackbar_options);
        if (!this.isNaturalNumber(this.data_edited.marathon_fees_amount) || this.data_edited.marathon_fees_amount < 0)
          return this.snackBar.open('Podaj wysokość opłaty startowej.', '', snackbar_options);
        if (this.data_edited.starter_pack_id && !this.data_edited.starter_pack_date)
          return this.snackBar.open('Podaj termin, do którego można zakupić pakiety startowe.', '', snackbar_options);

        let formData: FormData = new FormData();
        formData.append('start_date', this.data_edited.start_date);
        formData.append('end_date_marathon', this.data_edited.end_date_marathon ? this.data_edited.end_date_marathon : this.data_edited.start_date);
        formData.append('end_date', this.data_edited.end_date);
        formData.append('starter_pack_date', this.data_edited.starter_pack_date ? this.data_edited.starter_pack_date : '0');
        formData.append('starter_pack_id', this.data_edited.starter_pack_id ? String(this.data_edited.starter_pack_id) : '0');
        formData.append('name', this.data_edited.name);
        formData.append('poll_name', this.data_edited.poll_name);
        formData.append('city', this.data_edited.city);
        formData.append('address', this.data_edited.address);
        formData.append('latitude', String(this.data_edited.latitude));
        formData.append('longitude', String(this.data_edited.longitude));
        formData.append('marathon_fees_amount', String(this.data_edited.marathon_fees_amount));

        if (this.file_name) {
          if (this.doc)
            formData.append('marathon_statement', this.doc, this.doc.name);
        } else
          formData.append('delete_statement', '1');

        this.tab1_save_loader = true;
        this.http.Admin('marathon_details_edit', {
          id: this.marathon_id, data: formData
          //    data: {
          //     start_date: this.data_edited.start_date,
          //     end_date_marathon: this.data_edited.end_date_marathon ? this.data_edited.end_date_marathon : this.data_edited.start_date,
          //     end_date: this.data_edited.end_date,
          //     starter_pack_date: this.data_edited.starter_pack_date,
          //     starter_pack_id: this.data_edited.starter_pack_id ? this.data_edited.starter_pack_id : null,
          //     name: this.data_edited.name,
          //     poll_name: this.data_edited.poll_name,
          //     city: this.data_edited.city,
          //     address: this.data_edited.address,
          //     latitude: String(this.data_edited.latitude),
          //     longitude: String(this.data_edited.longitude),
          //     marathon_fees_amount: this.data_edited.marathon_fees_amount
          // }
        })
          .subscribe(data => {
            if (data && data.message)
              this.snackBar.open(data.message, '', snackbar_options);
            this.tab1_save_loader = false;
            this.get_details();
          }, err => {
            this.tab1_save_loader = false;
            this.errorHandler.handleError(err);
          });

        break;
      case 5:
        this.tab5_save_loader = true;
        this.http.Admin('marathon_coordinator_edit', {
          marathons_id: this.marathon_id,
          users_id: this.selectedCoordinator
        })
          .subscribe(data => {
            if (data && data.message)
              this.snackBar.open(data.message, '', snackbar_options);
            let select = this.selectedCoordinator;
            this.current_coordinator = this.coordinators.filter((item) => {
              return item.id == select;
            });
            this.tab5_save_loader = false;
            this.edit_coordinator = false;
          }, err => {
            this.tab5_save_loader = false;
            this.errorHandler.handleError(err);
          });

        break;
    }
  }

  toggle_track = (id) => {
    let box = document.getElementById('desc' + id);
    let arrow = document.getElementById('arrow' + id);
    if (box.classList.contains('context')) {
      box.classList.remove('context');
      TweenLite.to(box, 0.5, { height: '0px' });
      TweenLite.to(arrow, 0.5, { rotation: 0 });
    } else {
      box.classList.add('context');
      TweenLite.set(box, { height: 'auto' });
      TweenLite.from(box, 0.5, { height: '0px' });
      TweenLite.to(arrow, 0.5, { rotation: 180 });
    }
  }

  add_track = () => {
    let dialogRef = this.dialog.open(MarathonDialogAddTrack, {
      disableClose: true,
      width: '500px',
      data: { id: this.marathon_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.get_hours();
        if (this.selectedTrackHour != '-1')
          this.get_tracks({ value: this.selectedTrackHour });
      }
    });
  }

  add_user = (track_id) => {
    let dialogRef = this.dialog.open(MarathonDialogAddUser, {
      disableClose: true,
      width: '500px',
      data: { track_id: track_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.get_tracks({ value: this.selectedTrackHour });
      }
    });
  }

  add_category = (track_id) => {
    let dialogRef = this.dialog.open(MarathonDetailsDialogAddCategory, {
      disableClose: true,
      width: '400px',
      data: { track_id: track_id, marathon_id: this.marathon_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved')
        this.get_tracks({ value: this.selectedTrackHour });
    });
  }

  add_distance = (track_id) => {
    let dialogRef = this.dialog.open(MarathonDetailsDialogAddDistance, {
      disableClose: true,
      width: '400px',
      data: { track_id: track_id, marathon_id: this.marathon_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved')
        this.get_tracks({ value: this.selectedTrackHour });
    });
  }

  delete_category = (age_category_id, track_id, ind, ind2) => {
    this.tracks[ind].age_categories[ind2].loader_remove = true;
    this.http.Admin('marathon_tracks_delete_age_category', {
      track_id: track_id,
      age_category_id: parseInt(age_category_id)
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.tracks[ind].age_categories[ind2].loader_remove = false;
        for (let i in this.tracks[ind].age_categories)
          if (this.tracks[ind].age_categories[i].id == age_category_id)
            return this.tracks[ind].age_categories.splice(i, 1);
      }, err => {
        this.errorHandler.handleError(err);
        this.tracks[ind].age_categories[ind2].loader_remove = false;
      });
  }

  delete_distance = (distance_id, track_id, ind, ind2) => {
    this.tracks[ind].distances[ind2].loader_remove = true;
    this.http.Admin('marathon_tracks_delete_distance', {
      track_id: track_id,
      distance_id: parseInt(distance_id)
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.tracks[ind].distances[ind2].loader_remove = false;
        for (let i in this.tracks[ind].distances)
          if (this.tracks[ind].distances[i].id == distance_id)
            return this.tracks[ind].distances.splice(i, 1);
      }, err => {
        this.errorHandler.handleError(err);
        this.tracks[ind].distances[ind2].loader_remove = false;
      });
  }

  unregister_user = (user_id, track_id, ind, ind2) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteUser, {
      disableClose: true,
      width: '400px',
      data: { user_id: user_id, track_id: track_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted')
        this.tracks[ind].users[ind2].delete = true;
      // this.get_tracks({value: this.selectedTrackHour});
    });
  }

  updateCounter = (event, track_id, ind) => {
    this.tracks[ind].track_counter_loader = true;
    this.http.Admin('marathon_track_change_status', {
      track_id: track_id,
      status: event.checked ? '1' : '0'
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.tracks[ind].track_counter_loader = false;
        this.tracks[ind].status = event.checked;
      }, err => {
        this.errorHandler.handleError(err);
        this.tracks[ind].track_counter_loader = false;
        this.tracks[ind].status = !event.checked;
      });
  }

  add_result = () => {
    let dialogRef = this.dialog.open(MarathonDialogAddResult, {
      disableClose: true,
      width: '400px',
      data: { id: this.marathon_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved')
        this.get_results();
    });
  }

  delete_result = (id) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteResult, {
      disableClose: true,
      width: '400px',
      data: { pdf_id: id, marathon_id: this.marathon_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted')
        this.get_results();
    });
  }

  add_result_distance = () => {
    if (this.distance < 0 || !this.distance)
      return this.snackBar.open('Podaj dystans.', '', snackbar_options);
    this.edit_distance_loader = true;
    this.http.Admin('marathon_result_distance_add', {
      id: this.marathon_id, data: {
        distance: parseFloat(this.distance)
      }
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.edit_distance = false;
        this.edit_distance_loader = false;
      }, err => {
        this.edit_distance_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  goBack = () => {
    this.router.navigate(['/admin/maraton']);
  }

  change_register_system = (track_id, ind) => {
    let dialogRef = this.dialog.open(MarathonDialogChangeTrackSystem, {
      disableClose: true,
      width: '500px',
      data: {
        track_id: track_id,
        marathon_id: this.marathon_id,
        age_categories: this.tracks[ind].age_categories,
        distances: this.tracks[ind].distances,
        register_system: this.tracks[ind].register_system,
        max_seats: this.tracks[ind].max_seats
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.message == 'saved') {
        if (result.max_seats)
          this.tracks[ind].max_seats = result.max_seats;
        if (result.register_system == 1)
          return this.get_tracks({ value: this.selectedTrackHour });
        this.tracks[ind].register_system = result.register_system;
      }
    });
  }

  print_users_number = (track_id) => {
    let track = this.tracks.filter((track) => {
      return track.id == track_id;
    });
    let users_number = 0;
    for (let i in track[0].users)
      if (!track[0].users[i].delete)
        users_number++;
    return users_number;
  }
}
