import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule, ClusterManager } from "@agm/js-marker-clusterer";
import {
  MatTabsModule,
  MatButtonModule,
  MatInputModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatMenuModule,
  MatDialogModule,
  MatSelectModule,
  MatCardModule,
  MatDividerModule,
  MatProgressSpinnerModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ImageCropperModule } from "ngx-img-cropper";

import { PanelComponent, PanelDialogUserUnderage } from './panel.component';
import { AccountComponent, AccountDialogDelete, AccountDialogDeleteContestant } from './account/account.component';
import { PanelRoutingModule } from './panel.routing.module';
import { InfoComponent } from './info/info.component';
import { ResourcesComponent, ResourcesDialogBuyHearts, ResourcesDialogAfterBuy } from './resources/resources.component';
import { UserMarathonsComponent } from './user-marathons/user-marathons.component';
import { MarathonSignComponent } from './marathon-sign/marathon-sign.component';
import { MarathonSignDialogAddContestant, MarathonSignDialogTrackConfirm, MarathonSignDialogSummary, MarathonSignDialogNoHearts } from './marathon-sign/dialogs';
import { AccountEditComponent, AccountEditDialogCropper, AccountEditDialogChangePassword } from './account-edit/account-edit.component';
import { AccountAddEditContestantComponent } from './account-add-edit-contestant/account-add-edit-contestant.component';
import { UserMarathonsDetailsComponent, UserMarathonsDialogBuyStartPack, UserMarathonsDialogResignation, UserMarathonsDialogResignationConfirm } from './user-marathons-details/user-marathons-details.component';

// import {SanitizeHtmlPipe} from '../pipes/sanitizeHtml';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


import {PipesModule} from '../pipes/pipes.module';

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBkHAFytdHa8wiUbx9sSD9b6FbwffivjLs",
      libraries: ["places"]
    }),
    CommonModule,
    PanelRoutingModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    AgmJsMarkerClustererModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    PipesModule
  ],
  declarations: [
    PanelComponent,
    AccountComponent,
    AccountDialogDelete,
    AccountDialogDeleteContestant,
    InfoComponent,
    ResourcesComponent,
    ResourcesDialogBuyHearts,
    UserMarathonsComponent,
    MarathonSignComponent,
    MarathonSignDialogTrackConfirm,
    MarathonSignDialogSummary,
    MarathonSignDialogAddContestant,
    AccountEditComponent,
    AccountEditDialogChangePassword,
    AccountEditDialogCropper,
    AccountAddEditContestantComponent,
    UserMarathonsDetailsComponent,
    UserMarathonsDialogResignation,
    UserMarathonsDialogResignationConfirm,
    // SanitizeHtmlPipe,
    MarathonSignDialogNoHearts,
    PanelDialogUserUnderage,
    ResourcesDialogAfterBuy,
    UserMarathonsDialogBuyStartPack
  ],
  entryComponents: [
    AccountDialogDelete,
    AccountDialogDeleteContestant,
    ResourcesDialogBuyHearts,
    MarathonSignDialogTrackConfirm,
    MarathonSignDialogSummary,
    MarathonSignDialogAddContestant,
    AccountEditDialogCropper,
    AccountEditDialogChangePassword,
    UserMarathonsDialogResignation,
    UserMarathonsDialogResignationConfirm,
    MarathonSignDialogNoHearts,
    PanelDialogUserUnderage,
    ResourcesDialogAfterBuy,
    UserMarathonsDialogBuyStartPack
  ],
  providers: [
    ClusterManager,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
})
export class PanelModule { }
