import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'marathon-dialog-add-result',
  templateUrl: 'dialog-add-result.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDialogAddResult {
  loading = false;
  name = '';
  file_name = '';
  doc: File;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddResult>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  openInput = () => {
    document.getElementById("add-result-input").click();
  }

  fileChange = (files: File[]) => {
    if (files.length > 0) {
      this.file_name = files[0].name;
      this.doc = files[0];
    }
  }

  save = () => {
    if (this.name.length == 0) return this.snackBar.open('Podaj nazwę pliku.', '', snackbar_options);
    else if (this.file_name.length == 0) return this.snackBar.open('Wybierz plik.', '', snackbar_options);

    let formData: FormData = new FormData();
    formData.append('result_marathon_file', this.doc, this.doc.name);
    formData.append('show_name', this.name);

    this.loading = true;
    this.http.Admin('marathon_result_add', { id: this.data.id, data: formData })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-delete-result',
  templateUrl: 'dialog-delete-result.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDialogDeleteResult {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteResult>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  delete = () => {
    this.loading = true;
    this.http.Admin('marathon_result_delete', { pdf_id: this.data.pdf_id, marathon_id: this.data.marathon_id })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-add-track',
  templateUrl: 'dialog-add-track.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDialogAddTrack {
  loading = false;
  data_loading = true;
  start_hour = 12;
  start_minute = '00';
  track_name = '';
  register_system = 0;
  distances = [];
  age_categories = [];
  max_seats = 1;
  days = [];
  chosen_day = -1;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddTrack>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.http.Admin('marathon_tracks_options_get', { id: this.data.id })
      .subscribe(data => {
        this.data_loading = false;
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        if (data.age_categories.length == 0) {
          this.snackBar.open('Dodaj kategorię wiekową w terminie wydarzenia.', '', snackbar_options);
          return this.dialogRef.close();
        } else {
          for (let i in data.age_categories) {
            data.age_categories[i]['checked'] = false;
          }
          this.age_categories = data.age_categories;
        }
        if (data.distances.length == 0) {
          // this.snackBar.open('Dodaj dystans.', '', snackbar_options);
          // return this.dialogRef.close();
        } else {
          for (let i in data.distances)
            data.distances[i]['checked'] = false;
          this.distances = data.distances;
        }
        if (data['marathon']) {
          let today = new Date(data['marathon'].start_date);
          let end = new Date(data['marathon'].end_date_marathon);
          while (today.getTime() <= (end.getTime() + 7200000)) {
            this.days.push(new Date(today));
            today.setDate(today.getDate() + 1);
          }
          console.log(today, today.getTime(), end, end.getTime())

        }
      }, err => {
        this.dialogRef.close();
        this.errorHandler.handleError(err);
      });
  }

  hour_up = () => {
    if (this.start_hour >= 0 && this.start_hour <= 22)
      this.start_hour = Math.floor(this.start_hour + 1);
    else
      this.start_hour = 0;
  }

  hour_down = () => {
    if (this.start_hour >= 1 && this.start_hour <= 23)
      this.start_hour = Math.ceil(this.start_hour - 1);
    else
      this.start_hour = 23;
  }

  change_minute = (value) => {
    let minute = parseInt(this.start_minute);
    let add_zero = '0';
    minute += value;
    if (minute < 0) minute = 59;
    if (minute > 59) minute = 0;
    if (minute >= 0 && minute <= 9) {
      add_zero += minute;
      this.start_minute = add_zero;
    } else
      this.start_minute = String(minute);
  }

  save = () => {
    if (this.start_hour == null || this.start_hour < 0 || this.start_hour > 23 || parseInt(this.start_minute) < 0 || parseInt(this.start_minute) > 59)
      return this.snackBar.open('Podaj poprawną godzinę.', '', snackbar_options);
    if (!this.max_seats || this.max_seats < 1)
      return this.snackBar.open('Ustal limit zawodników.', '', snackbar_options);
    if (this.chosen_day < 0)
      return this.snackBar.open('Podaj dzień rozpoczęcia.', '', snackbar_options);
    if (this.track_name.length == 0)
      return this.snackBar.open('Podaj nazwę toru.', '', snackbar_options);
    if (this.register_system == 0)
      return this.snackBar.open('Wybierz system zapisu.', '', snackbar_options);
    let distances = [];
    let age_categories = [];
    if (this.register_system == 1) {
      for (let i in this.distances)
        if (this.distances[i].checked)
          distances.push(this.distances[i].id);
      // if (distances.length == 0)
      //   return this.snackBar.open('Wybierz dystans.', '', snackbar_options);
      for (let i in this.age_categories)
        if (this.age_categories[i].checked)
          age_categories.push(this.age_categories[i].id);
      if (age_categories.length == 0)
        return this.snackBar.open('Wybierz kategorie wiekowe.', '', snackbar_options);

    }

    let request = {
      marathon_id: this.data.id,
      start_time: this.start_hour + ':' + this.start_minute,
      start_date: this.days[this.chosen_day],
      name: this.track_name,
      register_system: this.register_system,
      status: 0,
      max_seats: this.max_seats
    };
    if (this.register_system == 1) {
      request['distances'] = distances;
      request['age_categories'] = age_categories;
    }
    this.loading = true;
    this.http.Admin('marathon_track_add', request)
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-add-category',
  templateUrl: 'dialog-add-category.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDetailsDialogAddCategory {
  loading = false;
  data_loading = true;
  category = '0';
  categories = [];
  constructor(
    public dialogRef: MatDialogRef<MarathonDetailsDialogAddCategory>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.http.Admin('marathon_tracks_options_no_used_get', {
      track_id: this.data.track_id,
      marathon_id: this.data.marathon_id
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.categories = data.age_categories;
        this.data_loading = false;
      }, err => {
        this.dialogRef.close();
        this.errorHandler.handleError(err);
      });
  }

  save = () => {
    if (this.category == '0')
      return this.snackBar.open('Wybierz kategorię wiekową.', '', snackbar_options);

    this.loading = true;
    this.http.Admin('marathon_tracks_add_age_category', {
      track_id: this.data.track_id,
      age_category_id: parseInt(this.category)
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-add-distance',
  templateUrl: 'dialog-add-distance.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDetailsDialogAddDistance {
  loading = false;
  data_loading = true;
  distance = '0';
  distances = [];

  constructor(
    public dialogRef: MatDialogRef<MarathonDetailsDialogAddDistance>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.http.Admin('marathon_tracks_options_no_used_get', {
      track_id: this.data.track_id,
      marathon_id: this.data.marathon_id
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.distances = data.distances;
        this.data_loading = false;
      }, err => {
        this.dialogRef.close();
        this.errorHandler.handleError(err);
      });
  }

  save = () => {
    // if (this.distance == '0')
    //   return this.snackBar.open('Wybierz dystans.', '', snackbar_options);

    this.loading = true;
    this.http.Admin('marathon_tracks_add_distance', {
      track_id: this.data.track_id,
      distance_id: parseInt(this.distance)
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-add-user',
  templateUrl: 'dialog-add-user.html',
  styleUrls: ['./marathon-details.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MarathonDialogAddUser {
  loading = false;
  init_loading = true;
  radio = 0;
  current_user = '0';
  users = [];
  current_fakeuser = '0';
  fakeusers = [];
  current_distance = '0';
  distances = [];
  new_user = { id: 0, firstname: '', lastname: '', gender: 0, birthday_date: null, email: '', phone: '', city: '', team: '', school: '', profession: '' };

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  users_to_autocomplete = [];

  checkbox = false;

  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddUser>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.http.Admin('marathon_track_user_list_get', { track_id: this.data.track_id })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.users = data.users;
        let users_to_autocomplete = [];
        for (let i in data.users) {
          let birth = new Date(data.users[i].birthday_date);
          users_to_autocomplete.push({
            id: data.users[i].id,
            data: `${data.users[i].firstname} ${data.users[i].lastname}, ${data.users[i].city}, ${birth.getDate()}.${birth.getMonth()}.${birth.getFullYear()}`
          })
        }
        this.users_to_autocomplete = users_to_autocomplete;

        this.distances = data.distances;
        this.init_loading = false;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }, err => {
        this.errorHandler.handleError(err);
        this.dialogRef.close();
      });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.users_to_autocomplete.filter(option => option.data.toLowerCase().includes(filterValue));
  }

  get_fake_users = (id) => {
    this.http.Admin('marathon_track_fake_user_list_get', { parent_id: id, track_id: this.data.track_id })
      .subscribe(data => {
        this.current_user = id;
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        if (data.length == 0) {
          this.snackBar.open('Wszyscy zawodnicy wybranego użytkownika są już zapisani na to wydarzenie.', '', snackbar_options);
          this.current_user = '0';
        }
        this.fakeusers = data;
        this.current_fakeuser = '0';
      }, err => {
        this.current_user = '0';
        this.errorHandler.handleError(err);
      });
  }

  save = () => {
    if (this.radio == 0) return this.snackBar.open('Wybierz typ użytkownika.', '', snackbar_options);
    if (this.radio == 1) {
      if (this.current_user == '0') return this.snackBar.open('Wybierz użytkownika.', '', snackbar_options);
      if (this.current_fakeuser == '0') return this.snackBar.open('Wybierz uczestnika wydarzenia.', '', snackbar_options);
      // if (this.current_distance == '0' && this.distances.length > 0) return this.snackBar.open('Wybierz dystans.', '', snackbar_options);

      let params = {
        track_id: this.data.track_id,
        users: [parseInt(this.current_fakeuser)]
      };
      if (this.current_distance != '0') params['distances'] = [parseInt(this.current_distance)];

      this.loading = true;
      this.http.Admin('marathon_track_user_add', params)
        .subscribe(data => {
          if (data && data.message)
            this.snackBar.open(data.message, '', snackbar_options);
          this.dialogRef.close('saved');
        }, err => {
          this.loading = false;
          this.errorHandler.handleError(err);
        });
    }
    if (this.radio == 2) {
      if (this.checkbox && this.current_user == '0') return this.snackBar.open('Wybierz użytkownika.', '', snackbar_options);
      if (this.new_user.firstname.length == 0) return this.snackBar.open('Podaj imię.', '', snackbar_options);
      if (this.new_user.lastname.length == 0) return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
      if (this.new_user.email.length == 0) return this.snackBar.open('Podaj e-mail.', '', snackbar_options);
      let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re_mail.test(String(this.new_user.email).toLowerCase()))
        return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
      if (this.new_user.phone.length == 0) return this.snackBar.open('Podaj numer telefonu.', '', snackbar_options);
      let re_phone = /^[\d\s]+$/;
      if (!re_phone.test(String(this.new_user.phone)))
        return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
      if (String(this.new_user.phone).replace(/[^0-9]/g, "").length < 9)
        return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);
      if (!this.new_user.gender) return this.snackBar.open('Wybierz płeć.', '', snackbar_options);
      if (this.new_user.city.length == 0) return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
      if (!this.new_user.birthday_date) return this.snackBar.open('Podaj datę urodzenia.', '', snackbar_options);
      // if (this.current_distance == '0') return this.snackBar.open('Wybierz dystans.', '', snackbar_options);

      let params = {
        firstname: this.new_user.firstname,
        lastname: this.new_user.lastname,
        email: this.new_user.email,
        city: this.new_user.city,
        gender: this.new_user.gender,
        birthday_date: this.new_user.birthday_date,
        phone: this.new_user.phone,
        distance_id: parseInt(this.current_distance),
        track_id: this.data.track_id,
        parent_id: this.checkbox ? parseInt(this.current_user) : null
      };
      if (this.new_user.team) params['team'] = this.new_user.team;
      if (this.new_user.school) params['school'] = this.new_user.school;
      if (this.new_user.profession) params['profession'] = this.new_user.profession;
      this.loading = true;
      this.http.Admin('marathon_track_new_user_add', params)
        .subscribe(data => {
          if (data && data.message)
            this.snackBar.open(data.message, '', snackbar_options);
          this.dialogRef.close('saved');
        }, err => {
          this.loading = false;
          this.errorHandler.handleError(err);
        });
    }
  }
}

@Component({
  selector: 'marathon-dialog-delete-user',
  templateUrl: 'dialog-delete-user.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDialogDeleteUser {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteUser>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  delete = () => {
    this.loading = true;
    this.http.Admin('user_marathons_sign_out', {
      user_id: parseInt(this.data.user_id),
      track_id: parseInt(this.data.track_id)
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-change-track-system',
  templateUrl: 'dialog-change-track-system.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDialogChangeTrackSystem {
  loading = false;
  register_system = 0;
  distances = [];
  age_categories = [];

  init_loading = true;
  max_seats = 0;

  constructor(
    public dialogRef: MatDialogRef<MarathonDialogChangeTrackSystem>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {
    this.register_system = data.register_system;
    this.distances = data.distances;
    // this.age_categories = data.age_categories;
    this.max_seats = data.max_seats;
  }

  ngOnInit() {
    this.http.Admin('marathon_tracks_options_get', { id: this.data.marathon_id })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        for (let i in data.age_categories) {
          let check = this.data.age_categories.find(x => x.id == data.age_categories[i].id);
          if (check && check.id)
            data.age_categories[i]['checked'] = true;
          else
            data.age_categories[i]['checked'] = false;
        }
        this.age_categories = data.age_categories;
        for (let i in data.distances) {
          let check = this.data.distances.find(x => x.id == data.distances[i].id);
          if (check && check.id)
            data.distances[i]['checked'] = true;
          else
            data.distances[i]['checked'] = false;
        }
        this.distances = data.distances;
        this.init_loading = false;
      }, err => {
        this.dialogRef.close();
        this.errorHandler.handleError(err);
      });
  }

  save = () => {
    if (!this.max_seats || this.max_seats < 1)
      return this.snackBar.open('Ustal limit zawodników.', '', snackbar_options);
    if (this.register_system == 0)
      return this.snackBar.open('Wybierz system zapisu.', '', snackbar_options);
    let distances = [];
    let age_categories = [];
    if (this.register_system == 1) {
      for (let i in this.distances)
        if (this.distances[i].checked)
          distances.push(this.distances[i].id);
      // if (distances.length == 0)
      //   return this.snackBar.open('Wybierz dystans.', '', snackbar_options);
      for (let i in this.age_categories)
        if (this.age_categories[i].checked)
          age_categories.push(this.age_categories[i].id);
      if (age_categories.length == 0)
        return this.snackBar.open('Wybierz kategorie wiekowe.', '', snackbar_options);
    }
    let request = {
      track_id: this.data.track_id,
      register_system: this.register_system,
      max_seats: this.max_seats
    };
    if (this.register_system == 1) {
      request['distances'] = distances;
      request['age_categories'] = age_categories;
    }
    this.loading = true;
    this.http.Admin('marathon_track_edit', request)
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close({ message: 'saved', register_system: this.register_system, max_seats: this.max_seats });
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-edit-packet',
  templateUrl: 'dialog-edit-packet.html',
  styleUrls: ['./marathon-details.component.scss']
})
export class MarathonDialogEditPacket {
  loading = false;
  quantity = null;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogEditPacket>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {
    this.quantity = data.quantity;
  }

  save = () => {
    if ((!this.quantity && this.quantity != 0) || this.quantity < 0)
      return this.snackBar.open('Podaj liczbę pakietów.', '', snackbar_options);
    this.loading = true;
    this.http.Admin('marathon_packet_users_edit', {
      id: this.data.id, data: {
        user_id: this.data.user_id,
        quantity: this.quantity
      }
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}
