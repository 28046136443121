import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmJsMarkerClustererModule, ClusterManager } from "@agm/js-marker-clusterer";
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  MatButtonModule,
  MatInputModule,
  MatRadioModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StartRoutingModule } from './start.routing.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// import {SanitizeHtmlPipe} from '../pipes/sanitizeHtml';
import { StartComponent } from "./start.component";
import { HomeComponent } from './home/home.component';
import { LoginComponent, LoginDialogRemindPassword, LoginDialogChangePassword } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { PipesModule } from '../pipes/pipes.module';
import { FoundationComponent } from './foundation/foundation.component';
import { NgxGalleryModule } from 'ngx-gallery';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    ScrollToModule.forRoot(),
    CommonModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    StartRoutingModule,
    MatProgressSpinnerModule,
    AgmJsMarkerClustererModule,
    PerfectScrollbarModule,
    PipesModule,
    NgxGalleryModule
  ],
  entryComponents: [
    LoginDialogRemindPassword,
    LoginDialogChangePassword,

  ],
  declarations: [
    StartComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    LoginDialogRemindPassword,
    LoginDialogChangePassword,
    FoundationComponent
  ],
  providers: [
    ClusterManager,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class StartModule { }
