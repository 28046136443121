import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AdminService } from '../../services/admin.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-marathon-set-charges',
  templateUrl: './marathon-set-charges.component.html',
  styleUrls: ['./marathon-set-charges.component.scss']
})
export class MarathonSetChargesComponent implements OnInit {

  selectedIndex = 0;

  current_fees = null;
  charges = [];
  displayedColumns = ['start', 'amount', 'id'];

  current_charges_page = 0;
  is_next_page = false;
  loading_page = true;
  loading_charges = false;

  bonus_edit = false;
  bonus_register_loader = true;
  bonus_six_players_loader = true;
  bonus_save_loader = false;
  bonus_register: number = 0;
  bonus_six_players: number = 0;
  bonus_register_edited: number;
  bonus_six_players_edited: number;

  name = 'ng2-ckeditor';
  ckeConfig: any;
  marathon_info_loader = true;
  marathon_info_save_loader = false;
  loading_ckeditor = true;
  marathon_info: '';
  log: string = '';
  @ViewChild("myckeditor") ckeditor: any;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea'
    };
    // this.get_charges();
      this.get_settings('bonus_register');
      this.get_settings('bonus_six_players');
  }

  get_charges = () => {
    this.loading_charges = true;
    this.http.Admin('marathons_charges_get', this.current_charges_page)
      .subscribe(data => {
        this.current_charges_page++;
        this.is_next_page = data.next_page;
        this.charges = [...this.charges, ...data.data];
        this.loading_charges = false;
        this.loading_page = false;
        this.current_fees = data.current_fees ? data.current_fees.amount : null;
      }, err => {
        this.loading_page = false;
        this.loading_charges = false;
        this.errorHandler.handleError(err);
      });
  }

  get_settings = (type) => {
    if (type == 'bonus_register') this.bonus_register_loader = true;
    else if (type == 'bonus_six_players') this.bonus_six_players_loader = true;
    else if (type == 'marathon_info') this.marathon_info_loader = true;
    this.http.Admin(`settings_get_${type}`)
      .subscribe(data => {
        this[type] = data[type];
        if (type == 'bonus_register') this.bonus_register_loader = false;
        else if (type == 'bonus_six_players') this.bonus_six_players_loader = false;
        else if (type == 'marathon_info') this.marathon_info_loader = false;
      }, err => {
        if (type == 'bonus_register') this.bonus_register_loader = false;
        else if (type == 'bonus_six_players') this.bonus_six_players_loader = false;
        else if (type == 'marathon_info') this.marathon_info_loader = false;
        this.errorHandler.handleError(err);
      });
    this.bonus_edit = false;
  }

  setActiveTab = (event: MatTabChangeEvent) => {
    this.selectedIndex = event.index;
    switch (event.index) {
      // case 0:
      //   this.charges = [];
      //   this.current_charges_page = 0;
      //   this.loading_page = true;
      //   this.is_next_page = false;
      //   this.get_charges();
      //   break;
      case 0:
        this.get_settings('bonus_register');
        this.get_settings('bonus_six_players');
        break;
      case 1:
        this.marathon_info_loader = true;
        this.get_settings('marathon_info');
        break;
    }
  }

  add_charge = () => {
    let dialogRef = this.dialog.open(MarathonDialogAddCharge, {
      disableClose: true,
      width: '400px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.charges = [];
        this.current_charges_page = 0;
        this.loading_page = true;
        this.is_next_page = false;
        this.get_charges();
      }
    });
  }

  delete_charge = (id, has_marathons) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteCharge, {
      disableClose: true,
      width: '400px',
      data: { id: id, has_marathons: has_marathons }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.charges = [];
        this.current_charges_page = 0;
        this.loading_page = true;
        this.is_next_page = false;
        this.get_charges();
      }
    });
  }

  goBack = () => {
    this.router.navigate(['/admin/maraton']);
  }

  edit_bonus = () => {
    this.bonus_register_edited = this.bonus_register;
    this.bonus_six_players_edited = this.bonus_six_players;
    this.bonus_edit = true;
  }

  save_bonus = () => {
    this.bonus_save_loader = true;
    this.http.Admin(`settings_edit`, {
      bonus_six_players: this.bonus_six_players_edited,
      bonus_register: this.bonus_register_edited
    })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.bonus_register = this.bonus_register_edited;
        this.bonus_six_players = this.bonus_six_players_edited;
        this.bonus_save_loader = false;
        this.bonus_edit = false;
      }, err => {
        this.bonus_save_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  save_info = () => {
    this.marathon_info_save_loader = true;
    this.http.Admin(`settings_edit`, { marathon_info: this.marathon_info })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.marathon_info_save_loader = false;
      }, err => {
        this.marathon_info_save_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  ckeditor_load = () => {
    this.loading_ckeditor = false;
  }

}

@Component({
  selector: 'marathon-dialog-add-charge',
  templateUrl: 'dialog-add-charge.html',
  styleUrls: ['./marathon-set-charges.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MarathonDialogAddCharge {
  loading = false;
  amount: number = 0;
  charge_start: any;
  charge_end: any;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddCharge>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() { }

  save = () => {
    if (!this.amount)
      return this.snackBar.open('Podaj kwotę.', '', snackbar_options);
    if (!this.charge_start || !this.charge_end)
      return this.snackBar.open('Podaj przedział.', '', snackbar_options);

    this.loading = true;
    this.http.Admin('marathons_charges_add', {
      start_date: new Date(this.charge_start._d.setHours(0, 0, 0)),
      end_date: new Date(this.charge_end._d.setHours(23, 59, 59)),
      amount: this.amount
    })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-delete-charge',
  templateUrl: 'dialog-delete-charge.html',
  styleUrls: ['./marathon-set-charges.component.scss']
})
export class MarathonDialogDeleteCharge {
  loading = false;
  has_marathons = false;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteCharge>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.has_marathons = this.data.has_marathons;
  }

  delete = () => {
    this.loading = true;
    this.http.Admin('marathons_charges_delete', { id: this.data.id })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}
