import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { PlatformLocation } from '@angular/common';
import {Router} from '@angular/router';
import { ErrorHandler } from '../services/errorHandler.service';

import { PanelService } from '../services/panel.service';
import { snackbar_options } from '../variables';
import { SharedService } from "../shared.service";
import {Subject} from 'rxjs/Subject';


@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit, OnDestroy {
  timeout: any;
  current_page = 'konto';
  modal_is_shown = false;
  user = {
    id: 0,
    firstname: '',
    lastname: '',
    avatar: '',
    hearts: 0,
    notification: []
  }

  public static returned: Subject<any> = new Subject();

  constructor(
    public dialog: MatDialog,
    public http: PanelService,
    public snackBar: MatSnackBar,
    private shared: SharedService,
    location_p: PlatformLocation,
    private router: Router,
    public errorHandler: ErrorHandler
  ) {
    PanelComponent.returned.subscribe(res => {
      if (res == 'underage')
        return this.show_modal_underage();
      this.get_data();
    });
    location_p.onPopState(() => {
      this.set_menu_active();
    });
  }
  server = this.shared.host;

  ngOnInit() {
    this.set_menu_active();
  }

  ngOnDestroy() {
    PanelComponent.returned.next();
    PanelComponent.returned.complete();
    PanelComponent.returned = new Subject();
  }

  show_modal_underage = () => {
    if (!this.modal_is_shown) {
      this.modal_is_shown = true;
      let dialogRef = this.dialog.open(PanelDialogUserUnderage, {
        disableClose: true,
        minWidth: '550px',
        width: '550px'
      });
      dialogRef.afterClosed().subscribe(result => {
        this.modal_is_shown = false;
      });
    }
  }

  set_menu_active = () => {
    let href = window.location.pathname.split('/');
    if (href.indexOf("konto") > -1)
      this.set_page('konto');
    if (href.indexOf("zapisy") > -1)
      this.set_page('zapisy');
    if ((href.indexOf("maraton") > -1) || href.indexOf("maratony") > -1)
      this.set_page('maratony');
    if (href.indexOf("portfel") > -1)
      this.set_page('portfel');
    if (href.indexOf("info") > -1)
      this.set_page('info');
  }

  get_data = () => {
    this.http.User('panel_get')
      .subscribe(data => {
        let user = data;
        user.notification = [...this.user.notification, ...user.notification];
        this.user = user;
        this.set_snackbar(0);
      }, err => {
        this.errorHandler.handleError(err);
      });
  }

  print_avatar_path = () => {
    if (this.user.avatar) {
      if (this.user.avatar == 'sample_man.jpg') return '../../assets/img/user/ikona_mezczyzna.png';
      if (this.user.avatar == 'sample_woman.jpg') return '../../assets/img/user/ikona_kobieta.png';
      return (this.server + '/avatar/' + this.user.avatar);
    }
  }

  set_page = (page) => {
    if (page == 'portfel')
        this.user.notification = [];
    this.current_page = page;
    this.get_data();
  }

  set_snackbar = (id) => {
    clearTimeout(this.timeout);
    setTimeout(() => {
      let time = 0;
      for (let i = id; i < this.user.notification.length; i++) {
        this.timeout = setTimeout(() => {
          if (document.getElementById("notify-" + i) != null) {
            document.getElementById("notify-" + i).style.top = (i * 45 + 50) + 'px';
            document.getElementById("notify-" + i).style.opacity = '1';
          }
        }, time * 100);
        time++;
      }
    }, 100);
  }

  hide_notify = (notify) => {
    this.user.notification.splice(notify, 1);
    this.set_snackbar(notify);
  }

  logout = () => {
    this.http.User('logout')
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.router.navigateByUrl('/');
      }, err => {
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'panel-dialog-user-underage',
  templateUrl: 'dialog-user-underage.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelDialogUserUnderage {
  constructor(
    public dialogRef: MatDialogRef<PanelDialogUserUnderage>
  ) { }

  close = () => {
    this.dialogRef.close();
  }
}
