import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { PanelService } from '../../services/panel.service';
import { snackbar_options } from '../../variables';
import { MapsAPILoader } from '@agm/core';
import { SharedService } from "../../shared.service";
import { PanelComponent } from '../panel.component';
import { ErrorHandler } from '../../services/errorHandler.service';

import { MarathonSignDialogAddContestant, MarathonSignDialogSummary, MarathonSignDialogNoHearts, MarathonSignDialogTrackConfirm } from './dialogs';

import google_maps_settings from './map-styles'

@Component({
  selector: 'app-marathon-sign',
  templateUrl: './marathon-sign.component.html',
  styleUrls: ['./marathon-sign.component.scss']
})
export class MarathonSignComponent implements OnInit {
  step = 1; //1: mapa, 2: wybór typu (ind, rodzina, trener), 3: ustawienia, 4: pakiety startowe, 5: podsumowanie

  step1_loading = true;
  google_maps_settings = google_maps_settings;
  locations = [];
  search_panel_show = false;
  marathon_time_interval: any;
  data = [];
  latitude: number = 52.229676;
  longitude: number = 20.012229;
  zoom: number = 6;
  map_search = '';
  map_marathon_loader = false;
  tips_timeout: any;
  tips = [];
  tip_loader = false;
  to_step_2_loader = false;

  chosen_marathon: any;
  selected_marathon: 0;
  marathon_list = [];
  step_2_radio = 0;
  step2_loading = true;
  to_step_3_loader = false;

  data_ind: any;
  select_distance_ind = {
    id: 0,
    index: -1
  };
  select_hour_ind = {
    time: '0',
    index: -1
  };
  track_ind = 0;

  users = [];
  main_user: any;
  marathon_fees: -1;
  users_to_sign = [];
  choose_users_loader = false;

  step_family = 1;
  family_radio = 0;
  data_family: any;
  select_hour_family = {
    time: '0',
    index: -1
  };
  track_family = 0;

  //TRENER
  step_coach = 1; //1: zawodnicy, 2: dystanse, 3: przypisywanie torów
  coach_radio = 0; //1: user bierze udział razem z zawodnikami, 2: nie bierze udziału
  data_coach: any; // obiekt z danymi dla trenera: dystanse, godziny
  select_hour_coach = {
    time: '0', //godzina jako string
    index: -1 //indeks godziny w tablicy data_coach.data
  };
  select_track_coach: any; //obiekt toru, do którego są przypisywani aktualnie zawodnicy
  show_contestants = false; //pokaż/ukryj box z przypisywaniem zawodników
  user_select_to_sign = 0; // liczba zaznaczonych do rejestracji zawodników
  toggle_sign_user_block = false; //gdy liczba zawodników przekracza wolne miejsce, blokuje checkboxy
  distances_loader = false;

  Math = Math;
  start_pack_number = 0;
  start_pack = {
    id: 0,
    free_pack: 0,
    url: null
  };
  start_pack_loader = false;
  starter_pack_image_previev = false;

  user_to_summary = [];
  summary_loader = false;

  constructor(
    public dialog: MatDialog,
    public http: PanelService,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private shared: SharedService,
    private ngZone: NgZone,
    public errorHandler: ErrorHandler
  ) { }
  server = this.shared.host;

  ngOnInit() {
    this.get_locations();

    // this.http.User('marathon_sign_start_pack_get', {id: 32})
    //   .subscribe(data => {
    //     console.log(data);
    //     this.start_pack = data;
    //   }, err => {
    //     this.set_main_step(5);
    //   });
    // this.step = 4;
  }

  get_locations = () => {
    this.http.User('map_locations_get')
      .subscribe(data => {
        for (let i in data) {
          data[i].longitude = parseFloat(data[i].longitude);
          data[i].latitude = parseFloat(data[i].latitude);
          data[i]['icon'] = "assets/img/user/heart_map1.png";
        }
        this.step1_loading = false;
        this.locations = data;
      }, err => {
        this.step1_loading = false;
        this.show_error(err);
      });
  }

  show_error = (err) => {
    this.errorHandler.handleError(err);
  }

  get_marathons = (params, type) => { //type: 1 - search, 2 - marathon_id
    let request = {};
    this.map_marathon_loader = true;
    this.data = [];
    this.tips = [];
    if (type == 1) {
      request['search'] = params;
      this.map_search = params;
      for (let i in this.locations)
        this.locations[i].icon = "assets/img/user/heart_map1.png";
    } else if (type == 2) {
      request['marathon_id'] = params;
      this.map_search = '';
    }
    this.http.User('map_marathons_get', request)
      .subscribe(data => {
        this.map_marathon_loader = false;
        this.data = data.marathons;
        this.marathon_time_counter();
      }, err => {
        this.map_marathon_loader = false;
        this.show_error(err);
      });
  }

  marker_click = (id) => {
    this.show_search_panel();
    this.get_marathons(id, 2);
    for (let i in this.locations)
      if (this.locations[i].id == id) {
        this.locations[i].icon = "assets/img/user/heart_selected_map1.png";
        this.latitude = this.locations[i].latitude;
        this.longitude = this.locations[i].longitude;
      } else
        this.locations[i].icon = "assets/img/user/heart_map1.png";
  }

  toggle_search_panel = () => {
    if (this.search_panel_show)
      this.clear_search_panel();
    else
      this.show_search_panel();
  }

  show_search_panel = () => {
    document.getElementById('search-panel').classList.add('isShow');
    this.search_panel_show = true;
    this.reset_search_data();
  }

  clear_search_panel = () => {
    if (this.step == 1) {
      document.getElementById('search-panel').classList.remove('isShow');
      this.search_panel_show = false;
      this.reset_search_data();
    }
  }

  reset_search_data = () => {
    clearInterval(this.marathon_time_interval);
    this.data = [];
    this.map_search = '';
    clearTimeout(this.tips_timeout);
    this.tip_loader = false;
    this.tips = [];
    for (let i in this.locations)
      this.locations[i].icon = "assets/img/user/heart_map1.png";
  }

  marathon_time_counter = () => {
    clearInterval(this.marathon_time_interval);
    this.marathon_time_interval = setInterval(() => {
      for (let i in this.data)
        if (this.data[i].time_end > 0)
          this.data[i].time_end--;
    }, 1000);
  }

  search_focus = (type) => {
    if (type)
      document.getElementById('search-tips-box').classList.add('d-block');
    else
      document.getElementById('search-tips-box').classList.remove('d-block');
  }

  search_update = (text) => {
    if (!text)
      return this.tips = [];
    clearTimeout(this.tips_timeout);
    this.tip_loader = true;
    this.tips_timeout = setTimeout(() => {
      this.http.User('map_search_tips_get', { search: text })
        .subscribe(data => {
          this.tips = data.search;
          this.tip_loader = false;
        }, err => {
          this.show_error(err);
          this.tip_loader = false;
        });
    }, 100);
  }

  print_time = (time) => {
    let days = Math.floor(time / 86400);
    let hours = Math.floor((time - days * 86400) / 3600);
    let minutes = Math.floor((time - (days * 86400 + hours * 3600)) / 60);
    let seconds = (time - (days * 86400 + hours * 3600 + minutes * 60));
    let days_text = days == 1 ? ' dzień ' : ' dni ';
    let hours_text = hours == 1 ? ' godzina ' : (
      [2, 3, 4, 22, 23].indexOf(hours) > -1 ? ' godziny ' : ' godzin '
    );
    let minutes_text = minutes == 1 ? ' minuta ' : (
      [2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54].indexOf(minutes) > -1 ? ' minuty ' : ' minut '
    );
    let seconds_text = seconds == 1 ? ' sekunda' : (
      [2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54].indexOf(seconds) > -1 ? ' sekundy' : ' sekund'
    );
    return days + days_text + hours + hours_text + minutes + minutes_text + seconds + seconds_text;
  }

  choose_marathon = (id, list) => {
    let request = {
      id: parseInt(id),
      list: list ? '?list=1' : ''
    };
    this.step2_loading = true;
    this.to_step_2_loader = true;
    this.selected_marathon = id;
    this.http.User('marathon_sign_details_get', request)
      .subscribe(data => {
        this.chosen_marathon = data;
        this.step_2_radio = 0;
        if (data.marathonList)
          this.marathon_list = data.marathonList;
        this.clear_search_panel();
        this.set_main_step(2);
        this.step2_loading = false;
        this.to_step_2_loader = false;
      }, err => {
        this.step2_loading = false;
        this.to_step_2_loader = false;
        this.show_error(err);
      });
  }

  change_register_type = () => {
    this.set_distance_ind(0);
    this.select_distance_ind.index = -1;
    this.coach_radio = 0;
    this.family_radio = 0;
    this.users = [];
    this.select_hour_family = {
      time: '0',
      index: -1
    };
    this.select_hour_coach = {
      time: '0',
      index: -1
    };
  }

  set_main_step = (step) => {
    this.step = step;
    if (step == 4)
      this.get_start_pack();
  }

  choose_register_type = () => {
    if (!this.step_2_radio)
      return this.snackBar.open('Zaznacz typ uczestnictwa w wydarzeniu.', '', snackbar_options);
    if (this.step_2_radio == 1 && !this.chosen_marathon.register_system.type_1)
      return this.snackBar.open('Brak miejsc.', '', snackbar_options);
    if (this.step_2_radio == 2 && !this.chosen_marathon.register_system.type_2)
      return this.snackBar.open('Brak miejsc.', '', snackbar_options);
    if (this.step_2_radio == 3 && !this.chosen_marathon.register_system.type_3)
      return this.snackBar.open('Brak miejsc.', '', snackbar_options);
    for (let i in this.chosen_marathon.statements)
      if (!this.chosen_marathon.statements[i].checked)
        return this.snackBar.open('Zaznacz wszystkie zgody.', '', snackbar_options);
    if (this.step_2_radio == 1) {
      if (this.chosen_marathon.user_hearts < this.chosen_marathon.marathon_fee)
        return this.show_no_hearts();
      this.to_step_3_loader = true;
      this.http.User('marathon_sign_ind_get', this.chosen_marathon.id)
        .subscribe(data => {
          if (!data.free_places)
            return this.snackBar.open('Brak wolnych miejsc w systemie indywidualnym.', '', snackbar_options);

          this.data_ind = data;
          this.set_main_step(3);
          this.to_step_3_loader = false;
          //jeżeli nie ma dystansów to dajemy żeby dystans był już wybrany 
          this.set_distance_ind(-1);
        }, err => {
          this.to_step_3_loader = false;
          this.show_error(err);
        });
    }
    else if (!this.chosen_marathon.of_age) {
      return PanelComponent.returned.next('underage');
    } else if (this.users.length == 0) {
      if (this.chosen_marathon.user_hearts < this.chosen_marathon.marathon_fee)
        return this.show_no_hearts();
      this.to_step_3_loader = true;
      this.http.User('marathon_sign_users_get', { id: this.chosen_marathon.id, system: this.step_2_radio })
        .subscribe(data => {
          console.log(data);
          if (!data.free_places)
            return this.snackBar.open('Brak wolnych miejsc.', '', snackbar_options);
          this.users = data.usersList;
          this.main_user = data.thisUser;
          this.marathon_fees = data.marathon_fees;
          this.set_main_step(3);
          this.to_step_3_loader = false;
        }, err => {
          this.to_step_3_loader = false;
          this.show_error(err);
        });
    }
    else
      this.set_main_step(3);
  }

  set_distance_ind = (id) => {
    this.select_distance_ind.id = id;
    this.select_hour_ind.time = '0';
    this.select_hour_ind.index = -1;
    this.track_ind = 0;
    if (id && this.data_ind && this.data_ind.data)
      for (let i in this.data_ind.data)
        if (this.data_ind.data[i].id == id)
          return this.select_distance_ind.index = parseInt(i);
  }

  set_hour_ind = (time) => {
    this.select_hour_ind.time = time;
    let events;
    if (time)
      events = time.split(" ");
    this.track_ind = 0;
    for (let i in this.data_ind.data[this.select_distance_ind.index].hours)
      if (events && this.data_ind.data[this.select_distance_ind.index].hours[i].start_time == events[1])
        return this.select_hour_ind.index = parseInt(i);
  }

  choose_users = () => {
    if ((this.step_2_radio == 2 && !this.family_radio) || (this.step_2_radio == 3 && !this.coach_radio))
      return this.snackBar.open('Zaznacz typ uczestnictwa w wydarzeniu.', '', snackbar_options);
    let users_to_sign = 0;
    for (let i in this.users)
      if (this.users[i].checked)
        users_to_sign++;
    // if ((this.family_radio == 1 && users_to_sign == 0) || (this.family_radio == 2 && users_to_sign <= 1))
    //     return this.snackBar.open('Dodaj minimum dwóch zawodników.', '', snackbar_options);
    // if ((this.coach_radio == 1 && users_to_sign == 0) || (this.coach_radio == 2 && users_to_sign <= 1))
    //     return this.snackBar.open('Dodaj minimum dwóch zawodników.', '', snackbar_options);
    if (this.family_radio == 2 && users_to_sign <= 0)
      return this.snackBar.open('Zaznacz zawodników.', '', snackbar_options);
    if (this.coach_radio == 2 && users_to_sign <= 0)
      return this.snackBar.open('Zaznacz zawodników.', '', snackbar_options);
    if (this.chosen_marathon.user_hearts < (this.chosen_marathon.marathon_fee * users_to_sign))
      return this.show_no_hearts();

    let chosen_users = [];
    if (this.family_radio == 1 || this.coach_radio == 1) {
      this.main_user['distance'] = 0;
      chosen_users.push(this.main_user);
      users_to_sign++;
    }
    for (let i in this.users)
      if (this.users[i].checked) {
        this.users[i]['distance'] = 0;
        chosen_users.push(this.users[i])
      }
    this.users_to_sign = JSON.parse(JSON.stringify(chosen_users));
    this.choose_users_loader = true;
    if (this.step_2_radio == 2) {
      this.select_hour_family = {
        time: '0',
        index: -1
      };
      this.http.User('marathon_sign_family_get', { id: this.chosen_marathon.id, data: { users: users_to_sign } })
        .subscribe(data => {
          this.choose_users_loader = false;
          if (data.free_places < users_to_sign)
            return this.snackBar.open(`Wybrano ${users_to_sign} zawodników. Pozostało ${data.free_places} wolnych miejsc.`, '', snackbar_options);
          this.data_family = data;
          this.set_step_family(2);
          for (let index in this.users_to_sign) {
            this.set_distance_family(-1, index);
          }
        }, err => {
          this.choose_users_loader = false;
          this.show_error(err);
        });
    } else if (this.step_2_radio == 3) {
      this.select_hour_coach = {
        time: '0',
        index: -1
      };
      this.http.User('marathon_sign_coach_get', { id: this.chosen_marathon.id })
        .subscribe(data => {
          this.choose_users_loader = false;
          if (data.free_places < users_to_sign)
            return this.snackBar.open(`Wybrano ${users_to_sign} zawodników. Pozostało ${data.free_places} wolnych miejsc.`, '', snackbar_options);
          this.data_coach = data;
          this.set_step_coach(2);

          for (let index in this.users_to_sign) {
            this.set_distance_coach(-1, index);
          }

        }, err => {
          this.choose_users_loader = false;
          this.show_error(err);
        });
    }
  }

  check_distance_settings = () => {
    for (let i in this.users_to_sign)
      if (this.users_to_sign[i].distance == 0) {
        this.select_hour_family = {
          time: '0',
          index: -1
        };
        return false;
      }

    return true;
  }

  choose_distances = () => {
    for (let i in this.users_to_sign)
      if (!this.users_to_sign[i].distance)
        return this.snackBar.open('Wszyscy zawodnicy muszą mieć dystans.', '', snackbar_options);
    this.distances_loader = true;
    this.http.User('marathon_sign_coach_tracks_get', { id: this.chosen_marathon.id })
      .subscribe(data => {
        this.data_coach['data'] = data.data;
        this.data_coach['free_places'] = data.free_places;
        this.set_step_coach(3);
        this.distances_loader = false;
      }, err => {
        this.distances_loader = false;
        this.show_error(err);
      });
  }


  set_distance_family = (value, id) => { this.users_to_sign[id].distance = value; }
  set_hour_family = (value) => {
    this.select_hour_family.time = value;
    let events;
    if (value)
      events = value.split(" ");
    this.track_family = 0;
    if (this.data_family && this.data_family.data)
      for (let i in this.data_family.data)
        if (events && this.data_family.data[i].start_time == events[1])
          return this.select_hour_family.index = parseInt(i);
  }
  set_distance_coach = (value, id) => { this.users_to_sign[id].distance = value; }
  set_hour_coach = (value) => {
    this.select_hour_coach.time = value;
    let events;
    if (value)
      events = value.split(" ");
    this.show_contestants = false;
    for (let i in this.users_to_sign)
      this.users_to_sign[i].checked_to_sign = false;
    this.user_select_to_sign = 0;
    if (this.data_coach && this.data_coach.data)
      for (let i in this.data_coach.data)
        if (events && this.data_coach.data[i].start_time == events[1])
          return this.select_hour_coach.index = parseInt(i);
  }

  show_track_list_user = (id) => {
    for (let i in this.data_coach.data[this.select_hour_coach.index].tracks)
      if (this.data_coach.data[this.select_hour_coach.index].tracks[i].id == id) {
        this.select_track_coach = this.data_coach.data[this.select_hour_coach.index].tracks[i];
        this.show_contestants = true;
        this.toggle_sign_user_block_func();
        return;
      }
  }

  toggle_sign_user = (event) => {
    if (event)
      this.user_select_to_sign++;
    else
      this.user_select_to_sign--;
    this.toggle_sign_user_block_func();
  }

  toggle_sign_user_block_func = () => {
    if (this.user_select_to_sign + this.select_track_coach.users.length >= this.select_track_coach.max_seats)
      this.toggle_sign_user_block = true;
    else
      this.toggle_sign_user_block = false;
  }

  set_step_family = (step) => {
    this.step_family = step;
  }

  set_step_coach = (step) => {
    this.step_coach = step;
    if (step == 2) {
      this.show_contestants = false;
      for (let i in this.users_to_sign)
        this.users_to_sign[i].checked_to_sign = false;
      this.user_to_summary = [];
      this.user_select_to_sign = 0;
    }
  }

  choose_track = (id) => {
    let request = {
      id: this.chosen_marathon.id,
      system: this.step_2_radio,
      data: []
    };
    let fees = -1;
    let user_to_summary = [];
    if (this.step_2_radio == 1) {
      request.data.push({
        user_id: this.data_ind.user_id,
        tracks_id: id,
        distances_id: this.select_distance_ind.id
      });
      fees = this.data_ind.marathon_fees;
      user_to_summary.push(this.data_ind.user_id);
    }
    if (this.step_2_radio == 2) {
      for (let i in this.users_to_sign) {
        request.data.push({
          user_id: this.users_to_sign[i].id,
          tracks_id: id,
          distances_id: this.users_to_sign[i].distance
        });
        user_to_summary.push(this.users_to_sign[i].id);
      }
      fees = this.marathon_fees * request.data.length;
    }
    if (this.step_2_radio == 3) {
      if (this.user_select_to_sign <= 0)
        return this.snackBar.open('Zaznacz zawodników.', '', snackbar_options);
      for (let i in this.users_to_sign) {
        if (this.users_to_sign[i].checked_to_sign)
          request.data.push({
            user_id: this.users_to_sign[i].id,
            tracks_id: id,
            distances_id: this.users_to_sign[i].distance
          });
        user_to_summary.push(this.users_to_sign[i].id);
      }
      fees = this.marathon_fees * request.data.length;

    }
    let dialogRef = this.dialog.open(MarathonSignDialogTrackConfirm, {
      disableClose: true,
      width: '550px',
      data: {
        request: request,
        fees: fees
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'confirm') {
        this.user_to_summary = [...this.user_to_summary, ...user_to_summary];
        if (this.step_2_radio == 3)
          return this.remove_signed_users();
        this.set_main_step(4);
      } else if (result == 'close') {
        this.track_ind = 0;
        this.track_family = 0;
      }
    });
  }

  remove_signed_users = () => {
    if (this.user_select_to_sign == this.users_to_sign.length)
      return this.set_main_step(4);
    let track_index = -1;
    for (let i in this.data_coach.data[this.select_hour_coach.index].tracks)
      if (this.data_coach.data[this.select_hour_coach.index].tracks[i].id == this.select_track_coach.id) {
        track_index = parseInt(i);
      }
    for (let i = this.users_to_sign.length - 1; i >= 0; i--)
      if (this.users_to_sign[i].checked_to_sign) {
        this.data_coach.data[this.select_hour_coach.index].tracks[track_index].users.push({
          user_txt: `${this.users_to_sign[i].firstname} ${this.users_to_sign[i].lastname}, ${this.users_to_sign[i].age_categorie.name}, ${this.users_to_sign[i].distance}`
        });

        this.users_to_sign.splice(i, 1);
      }

    this.user_select_to_sign = 0;
    this.toggle_sign_user_block_func();
  }

  print_distance = (id) => {
    for (let i in this.data_coach.distances) {
      if (this.data_coach.distances[i].id == id) {
        if (this.data_coach.distances[i].not_have_distances)
          return 'Bez dystansu';
        else
          return this.data_coach.distances[i].meters_from + ' - ' + this.data_coach.distances[i].meters_to + ' m';
      }
    }

  }

  get_start_pack = () => {
    this.start_pack_loader = false;
    this.http.User('marathon_sign_start_pack_get', { id: this.chosen_marathon.id })
      .subscribe(data => {
        this.start_pack = data;
      }, err => {
        this.set_main_step(5);
      });
  }

  buy_start_pack = () => {
    if (this.start_pack_number < 1)
      return this.snackBar.open('Zamów minimum 1 pakiet startowy.', '', snackbar_options);
    this.start_pack_loader = true;
    this.http.User('marathon_sign_start_pack_add', { id: this.chosen_marathon.id, data: { starter_pack_count: this.start_pack_number } })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.set_main_step(5);
        PanelComponent.returned.next();
      }, err => {
        this.start_pack_loader = false;
        this.show_error(err);
      });
  }

  start_pack_number_add = () => {
    if (this.start_pack_number >= 0) {
      //OM-68
      // if ((this.start_pack_number + 1) > this.start_pack.free_pack) {
      //   this.start_pack_number = this.start_pack.free_pack;
      //   return this.snackBar.open('Osiągnięto maksymalną możliwą do zamówienia liczbę pakietów startowych.', '', snackbar_options);
      // }
      this.start_pack_number = Math.floor(this.start_pack_number + 1);
    } else
      this.start_pack_number = 0;
  }

  start_pack_number_remove = () => {
    if (this.start_pack_number > 0)
      this.start_pack_number = Math.ceil(this.start_pack_number - 1);
  }

  show_summary = () => {
    this.summary_loader = true;
    this.http.User('marathon_sign_summary', { id: this.chosen_marathon.id, data: { users: this.user_to_summary } })
      .subscribe(data => {
        data['start_pack_number'] = this.start_pack_loader ? this.start_pack_number : 0;
        let dialogRef = this.dialog.open(MarathonSignDialogSummary, {
          disableClose: true,
          width: '980px',
          autoFocus: false,
          data: {
            data: data,
            register_type: this.step_2_radio
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.set_main_step(1);
          this.step_family = 1;
          this.step_coach = 1;
          this.user_to_summary = [];
          this.locations = [];
          this.step1_loading = true;
          this.selected_marathon = 0;
          this.get_locations();
        });
        setTimeout(() => {
          this.summary_loader = false;
        }, 300);
      }, err => {
        this.summary_loader = false;
        this.show_error(err);
      });
  }

  show_no_hearts = () => {
    this.dialog.open(MarathonSignDialogNoHearts, {
      width: '520px',
      autoFocus: false
    });
  }

  add_contestant = () => {
    let dialogRef = this.dialog.open(MarathonSignDialogAddContestant, {
      disableClose: true,
      width: '865px',
      data: {
        marathon_id: this.chosen_marathon.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.users.push(result);
      }
    });
  }

}
