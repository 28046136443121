import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../shared.service";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  SERVER: string;

  constructor(public http: HttpClient, private shared: SharedService) {
    this.SERVER = `${shared.host}/api/admin`;
  }

  Admin(action, params?): any {
    switch (action) {
      case 'content_get_logo':
        return this.http.get(`${this.SERVER}/contents?type=logo`);
      case 'content_get_payment':
        return this.http.get(`${this.SERVER}/contents?type=payment`);
      case 'content_get_award':
        return this.http.get(`${this.SERVER}/contents?type=award`);
      case 'content_update':
        return this.http.put(`${this.SERVER}/contents`, params);
      case 'documents_get':
        return this.http.get(`${this.SERVER}/documents`);
      case 'documents_add':
        return this.http.post(`${this.SERVER}/documents`, params.formData);
      case 'documents_delete':
        return this.http.delete(`${this.SERVER}/documents/${params.id}`);
      case 'sliders_get':
        return this.http.get(`${this.SERVER}/slider`);
      case 'sliders_add':
        return this.http.post(`${this.SERVER}/slider`, params.formData);
      case 'sliders_edit':
        return this.http.put(`${this.SERVER}/slider/${params.id}`, params.formData);
      case 'sliders_delete':
        return this.http.delete(`${this.SERVER}/slider/${params.id}`);

      case 'ranking_get_men':
        return this.http.get(`${this.SERVER}/ranking?type=men`);
      case 'ranking_get_women':
        return this.http.get(`${this.SERVER}/ranking?type=women`);
      case 'ranking_get_pool':
        return this.http.get(`${this.SERVER}/ranking?type=pool`);
      case 'ranking_get_club':
        return this.http.get(`${this.SERVER}/ranking?type=club`);
      case 'ranking_get_team':
        return this.http.get(`${this.SERVER}/ranking?type=team`);
      case 'ranking_get_profession':
        return this.http.get(`${this.SERVER}/ranking?type=profession`);
      case 'ranking_edit':
        return this.http.put(`${this.SERVER}/ranking`, params);

      case 'agreements_get':
        return this.http.get(`${this.SERVER}/agreements?page=${params}`);
      case 'agreements_add':
        return this.http.post(`${this.SERVER}/agreements`, params);
      case 'agreements_delete':
        return this.http.delete(`${this.SERVER}/agreements/${params.id}`);

      case 'faq_get':
        return this.http.get(`${this.SERVER}/faq?page=${params}`);
      case 'faq_add':
        return this.http.post(`${this.SERVER}/faq`, params);
      case 'faq_edit':
        return this.http.put(`${this.SERVER}/faq/${params.id}`, params.data);
      case 'faq_delete':
        return this.http.delete(`${this.SERVER}/faq/${params.id}`);

      case 'manager_list_get':
        return this.http.get(`${this.SERVER}/managers?order=${params.order}&page=${params.page}&sort=${params.order_dir}`);
      case 'manager_details_get':
        return this.http.get(`${this.SERVER}/managers/${params.id}?marathons=1`);
      case 'manager_add':
        return this.http.post(`${this.SERVER}/managers`, params);
      case 'manager_edit':
        return this.http.put(`${this.SERVER}/managers/${params.id}`, params.data);
      case 'manager_reset_pass':
        return this.http.put(`${this.SERVER}/manager-password-reset`, params);
      case 'manager_delete':
        return this.http.delete(`${this.SERVER}/managers/${params.id}${params.data}`);

      case 'users_get':
        return this.http.get(`${this.SERVER}/users?order=${params.order}&page=${params.page}&sort=${params.order_dir}&limit=${params.limit}${params.search ? '&search=' + params.search : ''}`);
      case 'user_add':
        return this.http.post(`${this.SERVER}/users`, params);
      case 'user_delete':
        return this.http.delete(`${this.SERVER}/user/${params.id}`);

      case 'user_get':
        return this.http.get(`${this.SERVER}/user/${params.id}`);
      case 'user_edit':
        return this.http.put(`${this.SERVER}/user/${params.id}`, params.data);
      case 'user_reset_pass':
        return this.http.put(`${this.SERVER}/user/${params.id}/password`, {});
      case 'user_marathons_get':
        return this.http.get(`${this.SERVER}/user/${params.user_id}/marathons/${params.page}`);
      case 'user_marathons_sign_out':
        return this.http.put(`${this.SERVER}/users/tracks`, params);
      case 'user_hearts_get':
        return this.http.get(`${this.SERVER}/user/${params.user_id}/hearts/${params.page}`);
      case 'user_hearts_add':
        return this.http.post(`${this.SERVER}/user/${params.id}/hearts`, params.data);

      case 'packets_get':
        return this.http.get(`${this.SERVER}/starter-packs/${params}`);
      case 'packets_full_get':
        return this.http.get(`${this.SERVER}/starter-packs-list`);
      case 'packet_get':
        return this.http.get(`${this.SERVER}/starter-pack/${params}`);
      case 'packet_add':
        return this.http.post(`${this.SERVER}/starter-packs`, params);
      case 'packet_delete':
        return this.http.delete(`${this.SERVER}/starter-pack/${params.id}`);
      case 'marathon_packet_users':
        return this.http.get(`${this.SERVER}/starter-packs-marathon/${params.id}/${params.page}`);
      case 'marathon_packet_users_edit':
        return this.http.put(`${this.SERVER}/starter-packs-marathon/${params.id}`, params.data);

      case 'marathons_get':
        return this.http.get(`${this.SERVER}/marathons?order=${params.order}&page=${params.page}&sort=${params.order_dir}`);
      case 'marathons_coordinators_get':
        return this.http.get(`${this.SERVER}/managers?specify=1`);
      case 'marathon_add':
        return this.http.post(`${this.SERVER}/marathons`, params);
      case 'marathon_delete':
        return this.http.delete(`${this.SERVER}/marathons/${params.id}`);

      case 'marathons_categories_get':
        return this.http.get(`${this.SERVER}/age-categories?order=${params.order}&page=${params.page}&sort=${params.order_dir}`);
      case 'marathons_category_add':
        return this.http.post(`${this.SERVER}/age-categories`, params);
      case 'marathons_category_delete':
        return this.http.delete(`${this.SERVER}/age-categories/${params.id}`);

      case 'marathons_distances_get':
        return this.http.get(`${this.SERVER}/distance?page=${params}`);
      case 'marathons_distance_add':
        return this.http.post(`${this.SERVER}/distance`, params);
      case 'marathons_distance_delete':
        return this.http.delete(`${this.SERVER}/distance/${params.id}`);

      case 'marathons_statements_get':
        return this.http.get(`${this.SERVER}/statements`);
      case 'marathons_statements_add':
        return this.http.post(`${this.SERVER}/statements`, params);
      case 'marathons_statements_delete':
        return this.http.delete(`${this.SERVER}/statements/${params.id}`);

      case 'marathons_charges_get':
        return this.http.get(`${this.SERVER}/marathon-fees?page=${params}`);
      case 'marathons_charges_add':
        return this.http.post(`${this.SERVER}/marathon-fees`, params);
      case 'marathons_charges_delete':
        return this.http.delete(`${this.SERVER}/marathon-fees/${params.id}`);

      case 'marathon_details_get':
        return this.http.get(`${this.SERVER}/marathons/${params.id}`);
      case 'marathon_details_edit':
        return this.http.put(`${this.SERVER}/marathons/${params.id}`, params.data);
      case 'marathon_details_set_visibility':
        return this.http.put(`${this.SERVER}/marathons/status`, params);
      case 'marathon_coordinator_edit':
        return this.http.put(`${this.SERVER}/marathons/manager`, params);

      case 'marathon_hours_get':
        return this.http.get(`${this.SERVER}/marathon/${params.id}/hours`);

      case 'marathon_tracks_get':
        return this.http.get(`${this.SERVER}/marathon/${params.id}/tracks?start_time=${params.start_time}&start_date=${params.start_date}`);
      case 'marathon_tracks_options_get':
        return this.http.get(`${this.SERVER}/marathon/${params.id}/options`);
      case 'marathon_track_add':
        return this.http.post(`${this.SERVER}/marathons/track`, params);
      case 'marathon_track_edit':
        return this.http.put(`${this.SERVER}/marathons/track-system`, params);
      case 'marathon_tracks_options_no_used_get':
        return this.http.get(`${this.SERVER}/marathon/${params.marathon_id}/options/${params.track_id}`);
      case 'marathon_tracks_add_age_category':
        return this.http.post(`${this.SERVER}/marathons/track/${params.track_id}/age-categories/${params.age_category_id}`, {});
      case 'marathon_tracks_delete_age_category':
        return this.http.delete(`${this.SERVER}/marathons/track/${params.track_id}/age-categories/${params.age_category_id}`);
      case 'marathon_tracks_add_distance':
        return this.http.post(`${this.SERVER}/marathons/track/${params.track_id}/distances/${params.distance_id}`, {});
      case 'marathon_tracks_delete_distance':
        return this.http.delete(`${this.SERVER}/marathons/track/${params.track_id}/distances/${params.distance_id}`);
      case 'marathon_track_change_status':
        return this.http.put(`${this.SERVER}/marathons/track-status`, params);
      case 'marathon_track_user_list_get':
        return this.http.get(`${this.SERVER}/users/parent-user/${params.track_id}`);
      case 'marathon_track_fake_user_list_get':
        return this.http.get(`${this.SERVER}/users/sub-users/${params.parent_id}/${params.track_id}`);
      case 'marathon_track_user_add':
        return this.http.post(`${this.SERVER}/users/tracks`, params);
      case 'marathon_track_new_user_add':
        return this.http.post(`${this.SERVER}/users/sub-users`, params);

      case 'marathon_list_start_save':
        return this.http.post(`${this.SERVER}/marathon/${params.id}/list-start`, params.data);
      case 'marathon_list_user_save':
        return this.http.post(`${this.SERVER}/marathon/${params.id}/user-list`, {});

      case 'marathon_result_get':
        return this.http.get(`${this.SERVER}/marathon/${params.id}/result`);
      case 'marathon_result_add':
        return this.http.post(`${this.SERVER}/marathon/${params.id}/result`, params.data);
      case 'marathon_result_delete':
        return this.http.delete(`${this.SERVER}/marathon/${params.marathon_id}/result/${params.pdf_id}`);
      case 'marathon_result_distance_add':
        return this.http.post(`${this.SERVER}/marathon/${params.id}/distance`, params.data);

      case 'marathon_coordinator':
        return this.http.get(`${this.SERVER}/marathons/manager/${params.id}`);

      case 'settings_get_counter':
        return this.http.get(`${this.SERVER}/settings?type=counter_status`);
      case 'settings_get_admin_mail':
        return this.http.get(`${this.SERVER}/settings?type=admin_email`);
      case 'settings_get_marathon_info':
        return this.http.get(`${this.SERVER}/settings?type=marathon_info`);
      case 'settings_get_bonus_register':
        return this.http.get(`${this.SERVER}/settings?type=bonus_register`);
      case 'settings_get_bonus_six_players':
        return this.http.get(`${this.SERVER}/settings?type=bonus_six_players`);
      case 'settings_edit':
        return this.http.put(`${this.SERVER}/settings`, params);

      case 'logout':
        return this.http.post(`${this.shared.host}/api/logout`, {})



      case 'gallery-data':
        return this.http.get(`${this.SERVER}/gallery`);
      case 'add-gallery':
        return this.http.post(`${this.SERVER}/gallery`, params);
      case 'delete-gallery':
        return this.http.delete(`${this.SERVER}/gallery/${params.id}`);
      case 'change-status-gallery':
        return this.http.put(`${this.SERVER}/gallery/${params.id}/change-status`, params);
    }
  }
}
