import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatTabChangeEvent, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  selectedIndex = 0;
  counter_status = false;
  docs = [];
  sponsors = [];
  institutions = [];

  public gallery: any[] = [];
  public gallery_loading: boolean = false;

  loading_counter = true;
  main_loader_docs = true;
  main_loader_sliders = true;
  server: string;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler,
    private shared: SharedService
  ) {

    this.server = `${shared.host}`;
  }

  ngOnInit() { }

  show_error = (err) => {
    this.selectedIndex = 0;
    this.errorHandler.handleError(err);
  }

  updateCounter = (event) => {
    this.loading_counter = true;
    this.http.Admin('settings_edit', { counter_status: event.checked ? 1 : 2 })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.counter_status = event.checked;
        this.loading_counter = false;
      }, err => {
        this.loading_counter = false;
        this.show_error(err);
      });
  }

  setActiveTab = (event: MatTabChangeEvent) => {
    let current_page = this.selectedIndex;
    this.selectedIndex = event.index;
    switch (event.index) {
      case 1:
        this.loading_counter = true;
        this.http.Admin('settings_get_counter')
          .subscribe(data => {
            this.loading_counter = false;
            this.counter_status = data.counter_status == 1;
          }, err => {
            this.loading_counter = false;
            this.show_error(err);
          });
        break;
      case 2:
        this.get_documents();
        break;
      case 3:
        this.get_sliders();
        break;
      case 4:
        this.getGalleryData();
        break;
    }
  }

  get_documents = () => {
    this.main_loader_docs = true;
    this.http.Admin('documents_get')
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.docs = data.documents;
        this.main_loader_docs = false;
      }, err => {
        this.show_error(err);
        this.main_loader_docs = false;
      });
  }

  get_sliders = () => {
    this.main_loader_sliders = true;
    this.http.Admin('sliders_get')
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        if (data.slider && data.slider.length > 0) {
          let sponsors = [];
          let institutions = [];
          for (let i in data.slider) {
            if (data.slider[i].type == 1) sponsors.push(data.slider[i]);
            else if (data.slider[i].type == 2) institutions.push(data.slider[i]);
          }
          this.sponsors = sponsors;
          this.institutions = institutions;
        }
        this.main_loader_sliders = false;
      }, err => {
        this.main_loader_sliders = false;
        this.show_error(err);
      });
  }

  edit = (item) => {
    let dialogRef = this.dialog.open(HomeDialogEditContent, {
      disableClose: true,
      width: '990px',
      data: { item: item }
    });
  }

  add_doc = () => {
    let dialogRef = this.dialog.open(HomeDialogAddDoc, {
      disableClose: true,
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved')
        this.get_documents();
    });
  }

  add_slider = (type) => {
    let dialogRef = this.dialog.open(HomeDialogAddEditSlider, {
      disableClose: true,
      width: '400px',
      data: { type: type }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved')
        this.get_sliders();
    });
  }

  edit_slider = (id) => {
    let edited_slider = this.sponsors.filter((sponsor) => {
      return sponsor.id == id;
    });
    if (edited_slider.length == 0)
      edited_slider = this.institutions.filter((institution) => {
        return institution.id == id;
      });
    let dialogRef = this.dialog.open(HomeDialogAddEditSlider, {
      disableClose: true,
      width: '400px',
      data: { edited_slider: edited_slider }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved')
        this.get_sliders();
    });
  }

  delete_doc = (id) => {
    let dialogRef = this.dialog.open(HomeDialogDeleteDoc, {
      disableClose: true,
      width: '400px',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted')
        this.get_documents();
    });
  }

  delete_slider = (id) => {
    let dialogRef = this.dialog.open(HomeDialogDeleteSlider, {
      disableClose: true,
      width: '400px',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted')
        this.get_sliders();
    });
  }

  public getGalleryData(): void {


    this.gallery = [];
    this.gallery_loading = true;

    this.http.Admin('gallery-data').subscribe((data: any) => {
      if (data.gallery) this.gallery = data.gallery;
      this.gallery_loading = false;

    }, err => {
      this.gallery_loading = false;
      return this.errorHandler.handleError(err);
    });
  }

  public addOrEditGalleryItem(): void {
    if (this.gallery_loading) return;

    let dialogRef = this.dialog.open(HomeDialogAddItemsGallery, {
      disableClose: true,
      width: '500px',
      data: {}
    });


    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getGalleryData();
      }
    })

  }


  public deleteItemGallery(item: any): void {
    if (this.gallery_loading) return;
    this.gallery_loading = true;

    this.http.Admin('delete-gallery', { id: item.id }).subscribe((data: any) => {
      this.getGalleryData();
    }, err => {
      this.gallery_loading = false;
      return this.errorHandler.handleError(err);
    });
  }

  public changeStatusItemGallery(item: any, event: any): void {
    if (this.gallery_loading) return;

    let status = event.checked ? 1 : 0;
    this.http.Admin('change-status-gallery', { id: item.id, status: status }).subscribe((data: any) => {
      // this.getGalleryData();
    }, err => {
      this.gallery_loading = false;
      return this.errorHandler.handleError(err);
    });
  }
}

@Component({
  selector: 'home-dialog-edit-content',
  templateUrl: 'dialog-edit-content.html',
  styleUrls: ['./home.component.scss']
})
export class HomeDialogEditContent {
  loading_ckeditor = true;
  loading_content = true;
  loading = false;
  ckeConfig: any;
  mycontent: '';
  @ViewChild("myckeditor") ckeditor: any;

  constructor(
    public dialogRef: MatDialogRef<HomeDialogEditContent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  item = this.data.item;

  ngOnInit() {
    this.ckeConfig = { allowedContent: true, extraPlugins: 'divarea' };
    switch (this.data.item) {
      case 1:
        this.http.Admin('content_get_logo')
          .subscribe(data => {
            if (data && data.logo_text)
              this.mycontent = data.logo_text;
            this.loading_content = false;
          }, err => {
            this.dialogRef.close();
            this.errorHandler.handleError(err);
          });
        break;
      case 2:
        this.http.Admin('content_get_payment')
          .subscribe(data => {
            if (data && data.payment_text)
              this.mycontent = data.payment_text;
            this.loading_content = false;
          }, err => {
            this.dialogRef.close();
            this.errorHandler.handleError(err);
          });
        break;
      case 3:
        this.http.Admin('content_get_award')
          .subscribe(data => {
            if (data && data.award_text)
              this.mycontent = data.award_text;
            this.loading_content = false;
          }, err => {
            this.dialogRef.close();
            this.errorHandler.handleError(err);
          });
        break;
    }
  }

  save = () => {
    this.loading = true;
    this.http.Admin('content_update',
      this.data.item == 1 ? { logo_text: this.mycontent } : (
        this.data.item == 2 ? { payment_text: this.mycontent } : (
          this.data.item == 3 && { award_text: this.mycontent }))
    )
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close();
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }

  ckeditor_load = () => {
    this.loading_ckeditor = false;
  }
}

@Component({
  selector: 'home-dialog-add-doc',
  templateUrl: 'dialog-add-doc.html',
  styleUrls: ['./home.component.scss']
})
export class HomeDialogAddDoc {
  loading = false;
  name = '';
  filename = '';
  doc: File;

  constructor(
    public dialogRef: MatDialogRef<HomeDialogAddDoc>,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() { }

  openInput = () => {
    document.getElementById("add-doc-input").click();
  }

  fileChange = (event) => {
    if (event.target.files.length > 0) {
      this.filename = event.target.files[0].name;
      this.doc = event.target.files[0];
    }
  }

  save = () => {
    if (this.name.length == 0)
      return this.snackBar.open('Podaj nazwę.', '', snackbar_options);
    else if (this.filename.length == 0)
      return this.snackBar.open('Wybierz plik.', '', snackbar_options);
    let formData: FormData = new FormData();
    formData.append('document_file', this.doc, this.doc.name);
    formData.append('description', this.name);
    this.loading = true;
    this.http.Admin('documents_add', { formData: formData })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'home-dialog-delete-doc',
  templateUrl: 'dialog-delete-doc.html',
  styleUrls: ['./home.component.scss']
})
export class HomeDialogDeleteDoc {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<HomeDialogDeleteDoc>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() { }

  delete = () => {
    this.loading = true;
    this.http.Admin('documents_delete', { id: this.data.id })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'home-dialog-add-edit-slider',
  templateUrl: 'dialog-add-edit-slider.html',
  styleUrls: ['./home.component.scss']
})
export class HomeDialogAddEditSlider {
  loading = false;
  page_link = '';
  filename = '';
  type = 0;
  id = 0;
  doc: File;
  imageChangedEvent = '';
  croppedImage = '';

  constructor(
    public dialogRef: MatDialogRef<HomeDialogAddEditSlider>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    if (this.data.edited_slider) {
      this.page_link = this.data.edited_slider[0].page_link;
      this.filename = this.data.edited_slider[0].filename;
      this.type = this.data.edited_slider[0].type;
      this.id = this.data.edited_slider[0].id;
    }
    if (this.data.type)
      this.type = this.data.type;
  }

  openInput = () => {
    document.getElementById("add-slider-input").click();
  }

  fileChange = (event) => {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      this.doc = event.target.files[0];
      this.filename = event.target.files[0].name;
    }
  }

  imageCropped(image: string) {
    this.croppedImage = image;
  }
  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }

  save = () => {
    if (this.filename.length == 0)
      return this.snackBar.open('Wybierz plik.', '', snackbar_options);
    let formData: FormData = new FormData();
    if (this.doc && this.doc.name) {
      let byteCharacters = atob(this.croppedImage.split(',')[1]);
      let byteArrays = [];
      let sliceSize = 512;
      let type = this.croppedImage.split(',')[0].split(':')[1].split(';')[0];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      let blob = new Blob(byteArrays, { type: type });
      formData.append('slider_file', blob, this.doc.name);
    }

    if (this.page_link.length > 0)
      formData.append('page_link', this.page_link);

    this.loading = true;
    if (this.id) {
      this.http.Admin('sliders_edit', { formData: formData, id: this.id })
        .subscribe(data => {
          if (data && data.message)
            this.snackBar.open(data.message, '', snackbar_options);
          this.dialogRef.close('saved');
        }, err => {
          this.loading = false;
          this.errorHandler.handleError(err);
        });
    } else {
      formData.append('type', String(this.type));
      this.http.Admin('sliders_add', { formData: formData })
        .subscribe(data => {
          if (data && data.message)
            this.snackBar.open(data.message, '', snackbar_options);
          this.dialogRef.close('saved');
        }, err => {
          this.loading = false;
          this.errorHandler.handleError(err);
        });
    }
  }
}

@Component({
  selector: 'home-dialog-delete-slider',
  templateUrl: 'dialog-delete-slider.html',
  styleUrls: ['./home.component.scss']
})
export class HomeDialogDeleteSlider {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<HomeDialogDeleteSlider>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() { }

  delete = () => {
    this.loading = true;
    this.http.Admin('sliders_delete', { id: this.data.id })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}


@Component({
  selector: 'home-dialog-add-item-gallery',
  templateUrl: 'dialog-add-item-gallery.html',
  styleUrls: ['./home.component.scss']
})
export class HomeDialogAddItemsGallery {
  public loading: boolean = false;

  public gallery_item: any = {
    type: 1,
    src: null,
    title: null,
    desc: null,
    file: null
  }

  constructor(
    public dialogRef: MatDialogRef<HomeDialogDeleteSlider>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() { }

  public save(): any {
    if (this.loading) return;

    let formData: FormData = new FormData();

    if (!this.gallery_item.type) return this.snackBar.open("Wybierz typ", '', snackbar_options);

    formData.append('type', this.gallery_item.type);
    //jeżeli  jest typem "html" to sprawdzamy czy podał źródło
    if (this.gallery_item.type == 3) {
      if (!this.gallery_item.src) return this.snackBar.open("Wpisz źródło html", '', snackbar_options);
      formData.append('src', this.gallery_item.src);
    } else {
      if (!this.gallery_item.file) return this.snackBar.open("Wybierz plik", '', snackbar_options);

      formData.append('gallery_file', this.gallery_item.file, this.gallery_item.file.name);
    }
    if (this.gallery_item.desc)
      formData.append('desc', this.gallery_item.desc);

    this.loading = true;
    this.http.Admin('add-gallery', formData).subscribe((data: any) => {
      this.loading = false;
      return this.close(true);
    }, err => {
      this.loading = false;
      return this.errorHandler.handleError(err);
    });
  }

  openInput(): void {
    document.getElementById("add-file-gallery-input").click();
  }

  fileChange(event): void {
    if (event.target.files.length > 0) {
      this.gallery_item.file = event.target.files[0];
    }
  }

  public close(data?: any): void {
    this.dialogRef.close(data ? data : null);
  }

}

