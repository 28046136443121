import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagerComponent } from './manager.component';
import { HomeComponent } from './home/home.component';
import { MarathonComponent, MarathonDialogAddResult, MarathonDialogDeleteResult } from './marathon/marathon.component';
import { ManagerRoutingModule } from './manager.routing.module';

import {
  MatCardModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatRadioModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatSelectModule,
  MatInputModule,
  MatProgressSpinnerModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ManagerRoutingModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    ManagerComponent,
    HomeComponent,
    MarathonComponent,
    MarathonDialogAddResult,
    MarathonDialogDeleteResult
  ],
  entryComponents: [
    MarathonDialogAddResult,
    MarathonDialogDeleteResult
  ]
})
export class ManagerModule { }
