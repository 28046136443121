import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { PanelComponent } from './panel/panel.component';
import { AdminComponent } from './admin/admin.component';
import { ManagerComponent } from './manager/manager.component';

const routes: Routes = [
  {
    path: '', component: AppComponent, children: [
      { path: '', component: StartComponent },
      { path: 'panel', component: PanelComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'koordynator', component: ManagerComponent }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
