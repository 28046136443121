import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { PanelService } from '../../services/panel.service';
import { snackbar_options } from '../../variables';
import { PanelComponent } from '../panel.component';
import { ErrorHandler } from '../../services/errorHandler.service';

import { SharedService } from "../../shared.service";

@Component({
  selector: 'app-user-marathons-details',
  templateUrl: './user-marathons-details.component.html',
  styleUrls: ['./user-marathons-details.component.scss']
})
export class UserMarathonsDetailsComponent implements OnInit {
  data = {
    id: 0,
    status: 1, //1: aktualny, 2: skończony
    type: 2, // 1: indywidualny, 2: drużynowy
    user_list: [],
    starter_packs: -1,
    distance: null,
    end_start_date: null
  };
  list_person = '';
  list_all = '';
  loading_list_person = false;
  loading_list_all = false;

  constructor(
    public http: PanelService,
    public dialog: MatDialog,
    private location: Location,
    private shared: SharedService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }
  server = this.shared.host;

  ngOnInit() {
    document.body.classList.add("mat-menu-transparent");
    this.get_details();
  }

  get_details = () => {
    let href = window.location.pathname.split('/');
    this.http.User('marathon_details_get', href[href.length - 1])
      .subscribe(data => {
        this.data = data;
      }, err => {
        this.errorHandler.handleError(err);
      });
  }

  get_list_person = () => {
    this.loading_list_person = true;
    this.http.User('marathon_details_list_person', this.data.id)
      .subscribe(data => {
        this.list_person = data.file;
        this.loading_list_person = false;
      }, err => {
        this.loading_list_person = false;
        this.errorHandler.handleError(err);
      });
  }

  get_list_all = () => {
    this.loading_list_all = true;
    this.http.User('marathon_details_list_all', this.data.id)
      .subscribe(data => {
        this.list_all = data.file;
        this.loading_list_all = false;
      }, err => {
        this.loading_list_all = false;
        this.errorHandler.handleError(err);
      });
  }

  resignation = (id) => {
    let dialogRef = this.dialog.open(UserMarathonsDialogResignation, {
      disableClose: true,
      minWidth: '865px',
      width: '865px',
      data: { id: id, marathon: this.data.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.dialog.open(UserMarathonsDialogResignationConfirm, {
          minWidth: '550px',
          width: '550px'
        });
        this.list_person = '';
        this.list_all = '';
        for (let i in this.data.user_list)
          if (this.data.user_list[i].id == id)
            return this.data.user_list[i].delete = 2;
      }
    });
  }

  buy_start_pack = () => {
    this.http.User('marathon_sign_start_pack_get', { id: this.data.id })
      .subscribe(data => {
        // if (!data.free_pack)
        //   return this.snackBar.open('Brak dostępnych pakietów startowych.', '', snackbar_options);
        let dialogRef = this.dialog.open(UserMarathonsDialogBuyStartPack, {
          disableClose: true,
          minWidth: '880px',
          width: '880px',
          data: { id: this.data.id, data: data }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'saved') {
            this.get_details();
          }
        });
      }, err => {
        this.errorHandler.handleError(err);
      });

  }
}

@Component({
  selector: 'user-marathons-details-dialog-resignation',
  templateUrl: 'dialog-resignation.html',
  styleUrls: ['./user-marathons-details.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class UserMarathonsDialogResignation {
  radio = 0;
  current_fakeuser = '0';
  fakeusers = [];
  new_user = {
    firstname: '',
    lastname: '',
    gender: '0',
    city: '',
    birthday_date: undefined,
    email: '',
    phone: '',
    team: '',
    school: '',
    profession: ''
  };
  loading = false;
  page_loading = true;


  constructor(
    public http: PanelService,
    public dialogRef: MatDialogRef<UserMarathonsDialogResignation>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.http.User('marathon_details_user_list', this.data.marathon)
      .subscribe(data => {
        this.fakeusers = data;
        this.page_loading = false;
      }, err => {
        this.errorHandler.handleError(err);
        this.dialogRef.close();
      });
  }

  close = () => {
    this.dialogRef.close();
  }

  save = () => {
    if (this.radio == 0) return this.snackBar.open('Wybierz typ użytkownika.', '', snackbar_options);
    let request = { user_id_old: this.data.id };
    if (this.radio == 1) {
      if (this.current_fakeuser == '0') return this.snackBar.open('Wybierz uczestnika wydarzenia.', '', snackbar_options);
      let player = this.fakeusers.filter(obj => {
        return obj.id == this.current_fakeuser;
      });
      request['firstname'] = player[0].firstname;
      request['lastname'] = player[0].lastname;
      request['email'] = player[0].email;
      request['birthday_date'] = player[0].birthday_date;
      request['gender'] = player[0].gender;
      request['city'] = player[0].city;
      request['phone'] = player[0].phone;
      if (player[0].team) request['team'] = player[0].team;
      if (player[0].school) request['school'] = player[0].school;
      if (player[0].profession) request['profession'] = player[0].profession;
    }
    if (this.radio == 2) {
      if (!this.new_user.firstname) return this.snackBar.open('Podaj imię.', '', snackbar_options);
      if (!this.new_user.lastname) return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
      if (parseInt(this.new_user.gender) == 0) return this.snackBar.open('Wybierz płeć.', '', snackbar_options);
      if (this.new_user.city.length == 0) return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
      if (!this.new_user.birthday_date) return this.snackBar.open('Podaj datę urodzenia.', '', snackbar_options);
      if (!this.new_user.email) return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
      let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re_mail.test(String(this.new_user.email).toLowerCase()))
        return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
      if (!this.new_user.phone) return this.snackBar.open('Podaj numer telefonu.', '', snackbar_options);
      let re_phone = /^[\d\s]+$/;
      if (!re_phone.test(String(this.new_user.phone)))
        return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
      if (String(this.new_user.phone).replace(/[^0-9]/g, "").length < 9)
        return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);
      request['firstname'] = this.new_user.firstname;
      request['lastname'] = this.new_user.lastname;
      request['email'] = this.new_user.email;
      request['birthday_date'] = this.new_user.birthday_date;
      request['gender'] = this.new_user.gender;
      request['city'] = this.new_user.city;
      request['phone'] = String(this.new_user.phone);
      if (this.new_user.team) request['team'] = this.new_user.team;
      if (this.new_user.school) request['school'] = this.new_user.school;
      if (this.new_user.profession) request['profession'] = this.new_user.profession;
    }
    this.loading = true;
    this.http.User('marathon_details_user_replace', { id: this.data.marathon, data: request })
      .subscribe(data => {
        // if (data.message)
        //   this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'user-marathons-details-dialog-resignation-confirm',
  templateUrl: 'dialog-resignation-confirm.html',
  styleUrls: ['./user-marathons-details.component.scss'],
})
export class UserMarathonsDialogResignationConfirm {
  constructor(
    public dialogRef: MatDialogRef<UserMarathonsDialogResignationConfirm>
  ) { }

  close = () => {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'user-marathons-details-dialog-buy-start-pack',
  templateUrl: 'dialog-buy-start-pack.html',
  styleUrls: ['./user-marathons-details.component.scss'],
})
export class UserMarathonsDialogBuyStartPack {
  Math = Math;
  start_pack_number = 0;
  start_pack = {
    id: 0,
    free_pack: 0,
    items: [],
    price: null,
    img: null,
    url: null
  };
  start_pack_loader = false;
  starter_pack_image_previev = false;
  constructor(
    public http: PanelService,
    public dialogRef: MatDialogRef<UserMarathonsDialogBuyStartPack>,
    private shared: SharedService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public errorHandler: ErrorHandler
  ) {
    this.start_pack = dialog_data.data;
  }
  server = this.shared.host;

  ngOnInit() {
    // console.log(this.dialog_data);
  }

  close = () => {
    this.dialogRef.close();
  }
  start_pack_number_add = () => {
    if (this.start_pack_number >= 0) {
      //OM-68
      // if ((this.start_pack_number + 1) > this.start_pack.free_pack) {
      //   this.start_pack_number = this.start_pack.free_pack;
      //   return this.snackBar.open('Osiągnięto maksymalną możliwą do zamówienia liczbę pakietów startowych.', '', snackbar_options);
      // }
      this.start_pack_number = Math.floor(this.start_pack_number + 1);
    } else
      this.start_pack_number = 0;
  }

  start_pack_number_remove = () => {
    if (this.start_pack_number > 0)
      this.start_pack_number = Math.ceil(this.start_pack_number - 1);
  }

  buy_start_pack = () => {
    if (this.start_pack_number < 1)
      return this.snackBar.open('Zamów minimum 1 pakiet startowy.', '', snackbar_options);
    this.start_pack_loader = true;
    this.http.User('marathon_sign_start_pack_add', { id: this.dialog_data.id, data: { starter_pack_count: this.start_pack_number } })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        PanelComponent.returned.next();
        this.dialogRef.close('saved');
      }, err => {
        this.start_pack_loader = false;
        this.errorHandler.handleError(err);
      });
  }
}
