import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import { SharedService } from "../shared.service";

import { snackbar_options } from '../variables';

@Injectable()
export class ErrorHandler {

  constructor(
    public http: HttpClient,
    private shared: SharedService,
    public snackBar: MatSnackBar,
    private router: Router
  ) {}

  public handleError = (err: any) => {
    if (err.error && err.error.message)
      this.snackBar.open(err.error.message, '', snackbar_options);
    else
      this.snackBar.open("Wystąpił błąd połączenia.", '', snackbar_options);

    if (err.error.logout)
      this.router.navigateByUrl('/');
  }
}
