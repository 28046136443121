import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-marathon-set-statements',
  templateUrl: './marathon-set-statements.component.html',
  styleUrls: ['./marathon-set-statements.component.scss']
})
export class MarathonSetStatementsComponent implements OnInit {
  statements = [];
  loading_page = true;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_statements();
  }

  get_statements = () => {
      this.loading_page = true;
      this.http.Admin('marathons_statements_get')
      .subscribe(data => {
        this.statements = data.statements;
        this.loading_page = false;
      }, err => {
        this.loading_page = false;
        this.errorHandler.handleError(err);
      });
  }

  add_statement = () => {
    let dialogRef = this.dialog.open(MarathonDialogAddStatement, {
      disableClose: true,
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.statements = [];
        this.get_statements();
      }
    });
  }

  delete_statement = (id) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteStatement, {
      disableClose: true,
      width: '400px',
      data: {id: id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.statements = [];
        this.get_statements();
      }
    });
  }

  goBack = () => {
    this.router.navigate(['/admin/maraton']);
  }
}

@Component({
  selector: 'marathon-dialog-add-statement',
  templateUrl: 'dialog-add-statement.html',
  styleUrls: ['./marathon-set-statements.component.scss']
})
export class MarathonDialogAddStatement {
  loading = false;
  content = '';
  name = '';
  file_name = '';
  doc: File;
  select = 0;

  link_name = '';
  link_address = '';
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddStatement>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  openInput = () => {
    document.getElementById("add-statement-input").click();
  }

  fileChange = (files: File[]) => {
    if (files.length > 0) {
      this.file_name = files[0].name;
      this.doc = files[0];
    }
  }

  save = () => {
      if (this.content.length == 0) return this.snackBar.open('Podaj treść oświadczenia.', '', snackbar_options);
      else if (this.select == 1 && this.name.length == 0) return this.snackBar.open('Podaj nazwę pliku.', '', snackbar_options);
      else if (this.select == 1 && this.file_name.length == 0) return this.snackBar.open('Wybierz plik.', '', snackbar_options);
      else if (this.select == 2 && this.link_name.length == 0) return this.snackBar.open('Podaj nazwę linku.', '', snackbar_options);
      else if (this.select == 2 && this.link_address.length == 0) return this.snackBar.open('Podaj adres www.', '', snackbar_options);

      let formData:FormData = new FormData();
      formData.append('content', this.content);
      if (this.select > 0)
        formData.append('type', String(this.select));
      if (this.select == 1) {
        formData.append('statement_file', this.doc, this.doc.name);
        formData.append('show_name', this.name);
      } else if (this.select == 2) {
        formData.append('page_link', this.link_address);
        formData.append('show_name', this.link_name);
      }

      this.loading = true;
      this.http.Admin('marathons_statements_add', formData)
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });

      }
}

@Component({
  selector: 'marathon-dialog-delete-statement',
  templateUrl: 'dialog-delete-statement.html',
  styleUrls: ['./marathon-set-statements.component.scss']
})
export class MarathonDialogDeleteStatement {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteStatement>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  delete = () => {
    this.loading = true;
    this.http.Admin('marathons_statements_delete', {id: this.data.id})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}
