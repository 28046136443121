import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ManagerComponent } from "./manager.component";
import { HomeComponent } from "./home/home.component";
import { MarathonComponent } from "./marathon/marathon.component";

const routes: Routes = [
  {
    path: 'koordynator', component: ManagerComponent, children: [
      { path: '', component: HomeComponent},
      { path: 'maraton/:id', component: MarathonComponent}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class ManagerRoutingModule { }
