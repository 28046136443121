import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../shared.service";

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  SERVER: string;

  constructor(public http: HttpClient, private shared: SharedService) {
    this.SERVER = `${shared.host}/api/manager`;
  }

  Manager(action, params?): any {
    switch (action) {
      case 'get_marathons':
        return this.http.get(`${this.SERVER}/marathon`);
      case 'get_marathon':
        return this.http.get(`${this.SERVER}/marathon/${params.id}`);

      case 'marathon_list_start_save':
          return this.http.post(`${this.SERVER}/marathon/${params.id}/list-start`, params.data);
      case 'marathon_list_user_save':
          return this.http.post(`${this.SERVER}/marathon/${params.id}/user-list`, {});
      case 'marathon_result_add':
          return this.http.post(`${this.SERVER}/marathon/${params.id}/result`, params.data);
      case 'marathon_result_delete':
          return this.http.delete(`${this.SERVER}/marathon/${params.marathon_id}/result/${params.pdf_id}`);
      case 'marathon_result_distance_add':
          return this.http.post(`${this.SERVER}/marathon/${params.id}/distance`, params.data);

        case 'logout':
          return this.http.post(`${this.shared.host}/api/logout`, {})
    }
  }
}
