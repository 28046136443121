import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AdminService } from '../../services/admin.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-marathon-set-categories',
  templateUrl: './marathon-set-categories.component.html',
  styleUrls: ['./marathon-set-categories.component.scss']
})
export class MarathonSetCategoriesComponent implements OnInit {
  categories = [];
  displayedColumns = ['name', 'gender', 'start_year', 'start_date', 'id'];
  order = 'name';
  order_dir = 0;
  current_categories_page = 0;
  is_next_page = false;
  loading_page = true;
  loading_categories = false;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_categories();
  }

  get_categories = () => {
    this.loading_categories = true;
      this.http.Admin('marathons_categories_get', {order: this.order, page: this.current_categories_page, order_dir: this.order_dir})
      .subscribe(data => {
          this.current_categories_page++;
          this.is_next_page = data.next_page;
          this.categories = [...this.categories, ...data.categories];
          this.loading_categories = false;
          this.loading_page = false;
      }, err => {
        this.loading_page = false;
        this.loading_categories = false;
        this.errorHandler.handleError(err);
      });
  }

  sort = (order) => {
    if (this.order == order)
      this.order_dir = this.order_dir == 0 ? 1 : 0;
    else
      this.order_dir = 0;

    this.order = order;
    this.categories = [];
    this.current_categories_page = 0;
    this.loading_page = true;
    this.is_next_page = false;
    this.get_categories();
  }

  add_category = () => {
    let dialogRef = this.dialog.open(MarathonDialogAddCategory, {
      disableClose: true,
      width: '400px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.categories = [];
        this.current_categories_page = 0;
        this.loading_page = true;
        this.is_next_page = false;
        this.get_categories();
      }
    });
  }

  delete_category = (id, has_marathons) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteCategory, {
      disableClose: true,
      width: '400px',
      data: {id: id, has_marathons: has_marathons}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.categories = [];
        this.current_categories_page = 0;
        this.loading_page = true;
        this.is_next_page = false;
        this.get_categories();
      }
    });
  }

  goBack = () => {
    this.router.navigate(['/admin/maraton']);
  }
}

@Component({
  selector: 'marathon-dialog-add-category',
  templateUrl: 'dialog-add-category.html',
  styleUrls: ['./marathon-set-categories.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MarathonDialogAddCategory {
  loading = false;
  year_start: number;
  year_end: number;
  gender = '0';
  name = '';
  date_start: any;
  date_end: any;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddCategory>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  save = () => {
    if (!this.year_start || !this.year_end || this.year_end < this.year_start)
      return this.snackBar.open('Podaj przedział wiekowy.', '', snackbar_options);
    if (this.gender == '0') return this.snackBar.open('Podaj płeć.', '', snackbar_options);
    if (this.name.length == 0) return this.snackBar.open('Podaj nazwę.', '', snackbar_options);
    if (!this.date_start || !this.date_end)
      return this.snackBar.open('Podaj przedział obowiązywania.', '', snackbar_options);

    this.loading = true;
    this.http.Admin('marathons_category_add', {
      start_year: this.year_start,
      end_year: this.year_end,
      start_date: new Date(this.date_start._d.setHours(0, 0, 0)),
      end_date: new Date(this.date_end._d.setHours(23, 59, 59)),
      name: this.name,
      gender: parseInt(this.gender)
    })
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('saved');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}

@Component({
  selector: 'marathon-dialog-delete-category',
  templateUrl: 'dialog-delete-category.html',
  styleUrls: ['./marathon-set-categories.component.scss']
})
export class MarathonDialogDeleteCategory {
  loading = false;
  has_marathons = false;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteCategory>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {
    this.has_marathons = this.data.has_marathons;
  }

  delete = () => {
      this.loading = true;
      this.http.Admin('marathons_category_delete', {id: this.data.id})
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}
