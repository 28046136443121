import { Component, OnInit } from '@angular/core';
import { PanelService } from '../../services/panel.service';
import { MatSnackBar } from '@angular/material';
import { snackbar_options } from '../../variables';
import { ErrorHandler } from '../../services/errorHandler.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  html_content = '';
  is_loaded = false;

  constructor(
    public http: PanelService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.http.User('informations_get')
      .subscribe(data => {
        this.html_content = data.info;
        this.is_loaded = true;
      }, err => {
        this.is_loaded = true;
        this.errorHandler.handleError(err);
      });
  }
}
