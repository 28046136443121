import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Md5 } from 'ts-md5/dist/md5';
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { PanelService } from '../../services/panel.service';
import { PanelComponent } from '../panel.component';
import { snackbar_options } from '../../variables';
import {Router} from '@angular/router';
import { SharedService } from "../../shared.service";
import { ErrorHandler } from '../../services/errorHandler.service';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {
  data = {
    id: 0,
    avatar: '',
    firstname: '',
    lastname: '',
    gender: 0,
    city: '',
    birthday_date: null,
    email: '',
    phone: null,
    team: '',
    profession: '',
    school: ''
  };
  new_avatar = false;
  avatar_filename = '';
  loading = false;

  constructor(
    public dialog: MatDialog,
    public http: PanelService,
    private location: Location,
    public snackBar: MatSnackBar,
    private shared: SharedService,
    private router: Router,
    public errorHandler: ErrorHandler
  ) { }
  server = this.shared.host;

  ngOnInit() {
    let href = window.location.pathname.split('/');
    this.http.User('account_details_get', href[href.length - 1])
      .subscribe(data => {
        this.data = data;
      }, err => {
        this.errorHandler.handleError(err);
        this.router.navigate(['/panel/konto']);
      });
  }

  change_password = () => {
    this.dialog.open(AccountEditDialogChangePassword, {
      disableClose: true,
      minWidth: '700px',
      width: '700px'
    });
  }

  choose_avatar = () => {
    let dialogRef = this.dialog.open(AccountEditDialogCropper, {
      disableClose: true,
      minWidth: '700px',
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.image) {
        this.new_avatar = true;
        this.data['avatar'] = result.image;
        this.avatar_filename = result.filename;
      }
    });
  }

  save = () => {
    if (!this.data.firstname)
      return this.snackBar.open('Podaj imię.', '', snackbar_options);
    if (!this.data.lastname)
      return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
    if (!this.data.gender)
      return this.snackBar.open('Wybierz płeć.', '', snackbar_options);
    if (!this.data.city)
      return this.snackBar.open('Podaj miejscowość.', '', snackbar_options);
    if (!this.data.phone)
      return this.snackBar.open('Podaj numer telefonu.', '', snackbar_options);
    let re_phone = /^[\d\s]+$/;
    if (!re_phone.test(String(this.data.phone)))
      return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
    if (String(this.data.phone).replace(/[^0-9]/g, "").length < 9)
      return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);

    this.loading = true;
    let formData: FormData = new FormData();
    formData.append('firstname', this.data.firstname);
    formData.append('lastname', this.data.lastname);
    formData.append('city', this.data.city);
    formData.append('gender', String(this.data.gender));
    formData.append('phone', String(this.data.phone));
    formData.append('team', this.data.team);
    formData.append('profession', this.data.profession);
    formData.append('school', this.data.school);

    if (this.new_avatar) {
      let byteCharacters = atob(this.data.avatar.split(',')[1]);
      let byteArrays = [];
      let sliceSize = 512;
      let type = this.data.avatar.split(',')[0].split(':')[1].split(';')[0];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      let blob = new Blob(byteArrays, { type: type });
      formData.append('avatar', blob, this.avatar_filename);
    }
    this.http.User('account_edit', formData)
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        PanelComponent.returned.next();
        // this.loading = false;
        this.router.navigate(['/panel/konto']);
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }

  go_back = () => {
    this.router.navigate(['/panel/konto']);
  }

}

@Component({
  selector: 'account-edit-dialog-change-password',
  templateUrl: 'dialog-change-password.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditDialogChangePassword {
  current_pass = '';
  new_pass = '';
  confirm_new_pass = '';
  loading = false;

  constructor(
    public http: PanelService,
    public dialogRef: MatDialogRef<AccountEditDialogChangePassword>,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  close = () => {
    this.dialogRef.close();
  }

  change_password = () => {
    if (!this.current_pass)
      return this.snackBar.open('Podaj aktualne hasło.', '', snackbar_options);
    if (!this.new_pass)
      return this.snackBar.open('Podaj nowe hasło.', '', snackbar_options);
    if (this.new_pass.length < 8)
      return this.snackBar.open('Hasło musi składać się minimum z 8 znaków.', '', snackbar_options);
    if (!/^\S*$/.test(this.new_pass))
      return this.snackBar.open('Hasło nie może posiadać spacji.', '', snackbar_options);
    let reg = /^(?=(?:[^a-z]*[a-z]){1})(?=(?:[^0-9]*[0-9]){1})(?=.*[!-\/:-@\[-`{-~]).{8,}$/i;
    if (!reg.test(this.new_pass.toLowerCase()))
      return this.snackBar.open('Hasło musi posiadać minimum jedną literę, jedną liczbę i jeden znak specjalny.', '', snackbar_options);
    if (!this.confirm_new_pass)
      return this.snackBar.open('Potwierdź nowe hasło.', '', snackbar_options);
    if (this.new_pass !== this.confirm_new_pass)
      return this.snackBar.open('Potwierdź hasło. Oba hasła muszą być identyczne.', '', snackbar_options);

    this.loading = true;
    this.http.User('account_pass_edit', {
      old_password: Md5.hashStr(this.current_pass),
      password: Md5.hashStr(this.new_pass)
    })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close();
      }, err => {
        this.loading = false;
        this.current_pass = '';
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'account-edit-dialog-cropper',
  templateUrl: 'dialog-cropper.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditDialogCropper {
  data: any;
  filename = '';
  cropperSettings: any;
  @ViewChild('cropper', undefined)
  cropper: ImageCropperComponent;

  constructor(
    public dialogRef: MatDialogRef<AccountEditDialogCropper>,
    public snackBar: MatSnackBar
  ) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 100;
    this.cropperSettings.height = 100;
    this.cropperSettings.croppedWidth = 150;
    this.cropperSettings.croppedHeight = 150;
    this.cropperSettings.canvasWidth = 400;
    this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.rounded = true;
    this.cropperSettings.noFileInput = true;
    this.data = {};
  }

  close = () => {
    this.dialogRef.close();
  }

  save = () => {
    this.data['filename'] = this.filename;
    this.dialogRef.close(this.data);
  }

  choose_avatar = () => {
    document.getElementById("choose-avatar").click();
  }

  fileChange = ($event) => {
    let image: any = new Image();
    let file: File = $event.target.files[0];
    this.filename = file.name;
    let myReader: FileReader = new FileReader();
    let that = this;
    myReader.onloadend = function(loadEvent: any) {
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);

    };
    myReader.readAsDataURL(file);
  }
}
