import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class SharedService {
  host: string;

  constructor() {
    if (environment.production)
      this.host = ``;
    else if (environment.test)
      this.host = ``;
    else if (environment.local)
      // this.host = `http://localhost:3000`;
      // this.host = `http://192.168.101.104:3000`;
      this.host = ``;
  }
}
