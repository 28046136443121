import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../shared.service";

@Injectable({
  providedIn: 'root'
})
export class StartService {
  SERVER: string;

  constructor(public http: HttpClient, private shared: SharedService) {
    this.SERVER = `${shared.host}/api`;
  }

  Auth(action, params?): any {
    switch (action) {
      case 'start_get':
        return this.http.get(`${this.SERVER}/main-page`);
      case 'send_form':
        return this.http.post(`${this.SERVER}/contact-form`, params);

      case 'register_get':
        return this.http.get(`${this.SERVER}/agreements`);
      case 'register':
        return this.http.post(`${this.SERVER}/register`, params);
      case 'login':
        return this.http.post(`${this.SERVER}/login`, params);
      case 'reset_password_step_1':
        return this.http.post(`${this.SERVER}/reset-password`, params);
      case 'reset_password_step_2':
        return this.http.put(`${this.SERVER}/reset-password`, params);
    }
  }

  Foundation(action, params?): any {
    switch (action) {
      case 'start':
        return this.http.get(`${this.SERVER}/foundation${params && params.session_id ? `/${params.session_id}` : ''}`);
      case 'buy':
        return this.http.post(`${this.SERVER}/foundation`, params);

    }
  }

}
