import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import {Router} from '@angular/router';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PanelService } from '../../services/panel.service';
import { snackbar_options } from '../../variables';
import { PanelComponent } from '../panel.component';
import { SharedService } from "../../shared.service";
import { ErrorHandler } from '../../services/errorHandler.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  data = {
    avatar: '',
    hearts: 0,
    id: 0,
    of_age: true,
    users: []
  };
  loader = true;
  loader_users = false;

  constructor(
    public dialog: MatDialog,
    public http: PanelService,
    public snackBar: MatSnackBar,
    private shared: SharedService,
    private router: Router,
    public errorHandler: ErrorHandler
  ) { }
  server = this.shared.host;

  ngOnInit() {
    document.body.classList.remove("mat-menu-transparent");
    this.get_details();
  }

  get_details = () => {
    this.data.users = [];
    this.http.User('account_get')
      .subscribe(data => {
        this.loader = false;
        this.loader_users = false;
        this.data = data;
      }, err => {
        this.loader = false;
        this.loader_users = false;
        this.errorHandler.handleError(err);
      });
  }

  print_avatar_path = () => {
    if (this.data.avatar) {
      if (this.data.avatar == 'sample_man.jpg') return '../../assets/img/user/ikona_mezczyzna.png';
      if (this.data.avatar == 'sample_woman.jpg') return '../../assets/img/user/ikona_kobieta.png';
      return (this.server + '/avatar/' + this.data.avatar);
    }
  }

  delete_player = (id) => {
    let dialogRef = this.dialog.open(AccountDialogDeleteContestant, {
      disableClose: true,
      minWidth: '700px',
      width: '700px',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.loader_users = true;
        this.get_details();
      }
    });
  }

  delete = () => {
    let dialogRef = this.dialog.open(AccountDialogDelete, {
      disableClose: true,
      minWidth: '700px',
      width: '700px',
      data: {hearts: this.data.hearts}
    });
  }

  add_player = () => {
    if (this.data.of_age)
      this.router.navigateByUrl('/panel/konto/dodaj-zawodnika');
    else
      PanelComponent.returned.next('underage');
  }
}

@Component({
  selector: 'account-dialog-delete',
  templateUrl: 'dialog-delete.html',
  styleUrls: ['./account.component.scss']
})
export class AccountDialogDelete {
  account_number = '';
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AccountDialogDelete>,
    public snackBar: MatSnackBar,
    public http: PanelService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public errorHandler: ErrorHandler
  ) { }

  close = () => {
    this.dialogRef.close();
  }

  delete = () => {
    if (!this.account_number && this.data.hearts > 0)
      return this.snackBar.open('Podaj numer konta.', '', snackbar_options);
    if (this.account_number.length > 0) {
      let re = /^[\d\s]+$/;
      if (!re.test(this.account_number))
        return this.snackBar.open('Numer konta może składać się wyłącznie z cyfr i spacji.', '', snackbar_options);
      if (this.account_number.replace(/[^0-9]/g, "").length != 26) {
        return this.snackBar.open('Numer konta powinien składać się z 26 cyfr.', '', snackbar_options);
      }
    }
    this.loading = true;
    this.http.User('account_delete', { bank_account_number: this.account_number })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'account-dialog-delete-contestant',
  templateUrl: 'dialog-delete-contestant.html',
  styleUrls: ['./account.component.scss']
})
export class AccountDialogDeleteContestant {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AccountDialogDeleteContestant>,
    public http: PanelService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public errorHandler: ErrorHandler
  ) { }

  close = () => {
    this.dialogRef.close();
  }

  delete = () => {
    this.loading = true;
    this.http.User('account_user_delete', { id: this.data.id })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}
