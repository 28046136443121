import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-user',
  templateUrl: './start-packet.component.html',
  styleUrls: ['./start-packet.component.scss']
})
export class StartPacketComponent implements OnInit {
  packets = [];
  displayedColumns = ['name', 'id'];
  current_packets_page = 0;
  is_next_page_packets = false;
  loading_packets = false;
  details_main_loader = false;
  loading_page = true;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_packets_list();
  }

  get_packets_list = () => {
    this.loading_packets = true;
    this.http.Admin('packets_get', this.current_packets_page)
    .subscribe(data => {
      this.current_packets_page++;
      this.is_next_page_packets = data.next_page;
      this.packets = [...this.packets, ...data.starter_pack];
      this.loading_packets = false;
      this.loading_page = false;
    }, err => {
      this.loading_packets = false;
      this.loading_page = false;
      this.errorHandler.handleError(err);
    });
  }

  add_packets = () => {
    if (!this.details_main_loader) {
      let dialogRef = this.dialog.open(StartPackerDialogAddPacket, {
        width: '500px',
        autoFocus: false,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'confirm') {
          this.packets = [];
          this.current_packets_page = 0;
          this.loading_page = true;
          this.is_next_page_packets = false;
          this.get_packets_list();
        }
      });
    }
  }

  show_details = (id) => {
    if (!this.details_main_loader) {
      this.details_main_loader = true;
      let pos = this.packets.map((item) => {return item.id}).indexOf(id);
      this.packets[pos].loader = true;
      this.http.Admin('packet_get', id)
      .subscribe(data => {
        this.packets[pos].loader = false;
        this.dialog.open(StartPackerDialogDetailsPacket, {
          width: '500px',
          data: data,
          disableClose: true
        });
        this.details_main_loader = false;
      }, err => {
        this.details_main_loader = false;
        this.packets[pos].loader = false;
        this.errorHandler.handleError(err);
      });
    }


  }

  delete_packet = (id) => {
    if (!this.details_main_loader) {
      let dialogRef = this.dialog.open(StartPackerDialogDeletePacket, {
        width: '400px',
        data: {id: id},
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'deleted') {
          this.packets = [];
          this.current_packets_page = 0;
          this.loading_page = true;
          this.is_next_page_packets = false;
          this.get_packets_list();
        }
      });
    }
  }
}

@Component({
  selector: 'start-packet-dialog-delete-packet',
  templateUrl: 'dialog-delete-packet.html',
  styleUrls: ['./start-packet.component.scss']
})
export class StartPackerDialogDeletePacket {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<StartPackerDialogDeletePacket>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  delete = () => {
    this.loading = true;
    this.http.Admin('packet_delete', {id: this.data.id})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}
@Component({
  selector: 'start-packet-dialog-add-packet',
  templateUrl: 'dialog-add-packet.html',
  styleUrls: ['./start-packet.component.scss']
})
export class StartPackerDialogAddPacket {
  data = {
    name: '',
    price: null,
    items: [],
    url: ''
  };
  new_element = '';
  filename = '';
  loading = false;

  doc: File;
  imageChangedEvent = '';
  croppedImage = '';

  constructor(
    public dialogRef: MatDialogRef<StartPackerDialogAddPacket>,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  openInput = () => {
    document.getElementById("add-packet-input").click();
  }

  fileChange = (event) => {
    this.imageChangedEvent = event;
    if (event.target.files.length > 0) {
      this.doc = event.target.files[0];
      this.filename = event.target.files[0].name;
    }
  }

  add_element = () => {
    if (this.new_element) {
      this.data.items.push(this.new_element);
      this.new_element = '';
    }
  }

  imageCropped(image: string) {
    this.croppedImage = image;
  }
  imageLoaded() {
      // show cropper
  }
  loadImageFailed() {
      // show message
  }

  add = () => {
    if (this.data.name.length == 0)
      return this.snackBar.open('Podaj nazwę.', '', snackbar_options);
    if (!this.data.price || this.data.price < 0)
      return this.snackBar.open('Podaj koszt.', '', snackbar_options);
    if (this.data.items.length == 0 && this.new_element.length > 0 )
      return this.snackBar.open('Kliknij przycisk z plusem, aby dodać element.', '', snackbar_options);
    if (this.data.items.length == 0)
      return this.snackBar.open('Podaj minimum jeden element.', '', snackbar_options);

    let formData: FormData = new FormData();
    if (this.doc && this.doc.name) {
      let byteCharacters = atob(this.croppedImage.split(',')[1]);
      let byteArrays = [];
      let sliceSize = 512;
      let type = this.croppedImage.split(',')[0].split(':')[1].split(';')[0];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      let blob = new Blob(byteArrays, {type: type});
      formData.append('starter_packs_img', blob, this.doc.name);
    }
    formData.append('name', this.data.name);
    formData.append('price', this.data.price);
    formData.append('url', this.data.url);
    formData.append('items', JSON.stringify(this.data.items));

    this.loading = true;
    this.http.Admin('packet_add', formData)
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('confirm');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }

  delete_element = (item) => {
    this.data.items.splice(item, 1);
  }
}
@Component({
  selector: 'start-packet-dialog-details-packet',
  templateUrl: 'dialog-details-packet.html',
  styleUrls: ['./start-packet.component.scss']
})
export class StartPackerDialogDetailsPacket {
  constructor(
    public dialogRef: MatDialogRef<StartPackerDialogDetailsPacket>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {}
}
