const google_maps_settings = {
  map_styles: [{
    "featureType": "water",
    "stylers": [{"color": "#8BB8F3"}]
  }, {
    "featureType": "road",
    "stylers": [{"visibility": "off"}]
  }, {
    "featureType": "poi",
    "stylers": [{ "visibility": "off" }]
  }, {
    "featureType": "transit",
    "stylers": [{ "visibility": "off" }]
  }, {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [{ "color": "#8BB8F3" }]
  }, {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [{ "color": "#ffffff" }]
  }],
  cluster_styles: [{
    textColor: "#FFFFFF",
     url: "assets/img/user/serce_mapa.png",
     height: 40,
     width: 45
  }]
};

export default google_maps_settings;
