import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-coordinator-details',
  templateUrl: './coordinator-details.component.html',
  styleUrls: ['./coordinator-details.component.scss']
})
export class CoordinatorDetailsComponent implements OnInit {
  coordinator = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    marathons: []
  }

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_details();
  }

  get_details = () => {
    let href = window.location.pathname.split('/');
    this.http.Admin('manager_details_get', {id: href[href.length - 1]})
    .subscribe(data => {
      this.coordinator = data;
    }, err => {
      this.router.navigate(['/admin/koordynator']);
      this.errorHandler.handleError(err);
    });
  }

  goBack = () => {
    this.router.navigate(['/admin/koordynator']);
  }

  edit = () => {
    let dialogRef = this.dialog.open(CoordinatorDialogEditCoordinator, {
      disableClose: true,
      width: '400px',
      data: {coordinator: this.coordinator}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.coordinator.id = 0;
        this.get_details();
      }
    });
  }

  reset_password = () => {
    let dialogRef = this.dialog.open(CoordinatorDialogResetCoordinator, {
      disableClose: true,
      width: '400px',
      data: {id: this.coordinator.id, email: this.coordinator.email}
    });
  }
}

@Component({
  selector: 'coordinator-dialog-edit-coordinator',
  templateUrl: 'dialog-edit-coordinator.html',
  styleUrls: ['./coordinator-details.component.scss']
})
export class CoordinatorDialogEditCoordinator {
  loading = false;
  id = 0;
  firstname = '';
  lastname = '';
  phone = '';

  constructor(
    public dialogRef: MatDialogRef<CoordinatorDialogEditCoordinator>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {
    this.firstname = this.data.coordinator.firstname;
    this.lastname = this.data.coordinator.lastname;
    this.phone = this.data.coordinator.phone;
    this.id = this.data.coordinator.id;
  }

  save = () => {
    if (this.firstname.length == 0)
      return this.snackBar.open('Podaj imię.', '', snackbar_options);
    if (this.lastname.length == 0)
      return this.snackBar.open('Podaj nazwisko.', '', snackbar_options);
    if (!this.phone)
      return this.snackBar.open('Podaj numer telefonu.', '', snackbar_options);
    let re_phone = /^[\d\s]+$/;
    if (!re_phone.test(String(this.phone)))
      return this.snackBar.open('Numer telefonu może składać się wyłącznie z cyfr.', '', snackbar_options);
    if (String(this.phone).replace(/[^0-9]/g, "").length < 9)
      return this.snackBar.open('Numer telefonu powinien składać się minimum z 9 cyfr.', '', snackbar_options);

    this.loading = true;
    this.http.Admin('manager_edit', {id: this.id, data: {firstname: this.firstname, lastname: this.lastname, phone: this.phone}})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('saved');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}

@Component({
  selector: 'coordinator-dialog-reset-coordinator',
  templateUrl: 'dialog-reset-coordinator.html',
  styleUrls: ['./coordinator-details.component.scss']
})
export class CoordinatorDialogResetCoordinator {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<CoordinatorDialogResetCoordinator>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  reset = () => {
    this.loading = true;
    this.http.Admin('manager_reset_pass', {manager_id: this.data.id, email: this.data.email})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('reset');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}
