import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  approval  = [];
  current_page = 0;
  is_next_page = false;
  loading_next_page = false;
  main_loader = true;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_agreements();
  }

  get_agreements = () => {
    this.loading_next_page = true;
    this.http.Admin('agreements_get', this.current_page)
    .subscribe(data => {
      this.current_page++;
      this.is_next_page = data.next_page;
      this.approval = [...this.approval, ...data.agreements];
      this.loading_next_page = false;
      this.main_loader = false;
    }, err => {
      this.loading_next_page = false;
      this.main_loader = false;
      this.errorHandler.handleError(err);
    });
  }

  add_approval = () => {
    let dialogRef = this.dialog.open(ApprovalDialogAddApproval, {
      disableClose: true,
      width: '400px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.approval = [];
        this.current_page = 0;
        this.main_loader = true;
        this.is_next_page = false;
        this.get_agreements();
      }
    });
  }

  delete_approval = (id) => {
    let dialogRef = this.dialog.open(ApprovalDialogDeleteApproval, {
      disableClose: true,
      width: '400px',
      data: {id: id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.approval = [];
        this.current_page = 0;
        this.main_loader = true;
        this.is_next_page = false;
        this.get_agreements();
      }
    });
  }
}

@Component({
  selector: 'approval-dialog-add-approval',
  templateUrl: 'dialog-add-approval.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalDialogAddApproval {
  loading = false;
  content = '';
  name = '';
  file_name = '';
  doc: File;
  select = 0;
  link_name = '';
  link_address = '';

  constructor(
    public dialogRef: MatDialogRef<ApprovalDialogAddApproval>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  openInput = () => {
    document.getElementById("add-approval-input").click();
  }

  fileChange = (files: File[]) => {
    if (files.length > 0) {
      this.file_name = files[0].name;
      this.doc = files[0];
    }
  }

  save = () => {
    if (this.content.length == 0) return this.snackBar.open('Podaj treść zgody.', '', snackbar_options);
    else if (this.select == 1 && this.name.length == 0) return this.snackBar.open('Podaj nazwę pliku.', '', snackbar_options);
    else if (this.select == 1 && this.file_name.length == 0) return this.snackBar.open('Wybierz plik.', '', snackbar_options);
    else if (this.select == 2 && this.link_name.length == 0) return this.snackBar.open('Podaj nazwę linku.', '', snackbar_options);
    else if (this.select == 2 && this.link_address.length == 0) return this.snackBar.open('Podaj adres www.', '', snackbar_options);

    // if (this.select == 0) return this.snackBar.open('Dodaj załącznik.', '', snackbar_options);

    let formData:FormData = new FormData();
    formData.append('content', this.content);
    if (this.select > 0)
      formData.append('type', String(this.select));
    if (this.select == 1) {
      formData.append('agreement_file', this.doc, this.doc.name);
      formData.append('show_name', this.name);
    } else if (this.select == 2) {
      formData.append('page_link', this.link_address);
      formData.append('show_name', this.link_name);
    }
    this.loading = true;
    this.http.Admin('agreements_add', formData)
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('saved');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });

  }
}

@Component({
  selector: 'approval-dialog-delete-approval',
  templateUrl: 'dialog-delete-approval.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalDialogDeleteApproval {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<ApprovalDialogDeleteApproval>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  delete = () => {
    this.loading = true;
    this.http.Admin('agreements_delete', {id: this.data.id})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}
