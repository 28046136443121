import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-marathon-set-distance',
  templateUrl: './marathon-set-distance.component.html',
  styleUrls: ['./marathon-set-distance.component.scss']
})
export class MarathonSetDistanceComponent implements OnInit {
  distances = [];
  current_distances_page = 0;
  is_next_page = false;
  loading_page = true;
  loading_distances = false;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_distances();
  }

  get_distances = () => {
    this.loading_distances = true;
      this.http.Admin('marathons_distances_get', this.current_distances_page)
      .subscribe(data => {
          this.current_distances_page++;
          this.is_next_page = data.next_page;
          this.distances = [...this.distances, ...data.distances];
          this.loading_distances = false;
          this.loading_page = false;
      }, err => {
        this.loading_page = false;
        this.loading_distances = false;
        this.errorHandler.handleError(err);
      });
  }

  add_distance = () => {
    let dialogRef = this.dialog.open(MarathonDialogAddDistance, {
      disableClose: true,
      width: '400px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.distances = [];
        this.current_distances_page = 0;
        this.loading_page = true;
        this.is_next_page = false;
        this.get_distances();
      }
    });
  }

  delete_distance = (id, has_marathons) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteDistance, {
      disableClose: true,
      width: '400px',
      data: {id: id, has_marathons: has_marathons}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.distances = [];
        this.current_distances_page = 0;
        this.loading_page = true;
        this.is_next_page = false;
        this.get_distances();
      }
    });
  }

  goBack = () => {
    this.router.navigate(['/admin/maraton']);
  }
}

@Component({
  selector: 'marathon-dialog-add-distance',
  templateUrl: 'dialog-add-distance.html',
  styleUrls: ['./marathon-set-distance.component.scss']
})
export class MarathonDialogAddDistance {
  loading = false;
  distans_start: number;
  distans_end: number;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddDistance>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  save = () => {
    if (!this.distans_start || !this.distans_end)
      return this.snackBar.open('Podaj przedział.', '', snackbar_options);

      this.loading = true;
      this.http.Admin('marathons_distance_add', {
          meters_from: this.distans_start,
          meters_to: this.distans_end
      })
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'marathon-dialog-delete-distance',
  templateUrl: 'dialog-delete-distance.html',
  styleUrls: ['./marathon-set-distance.component.scss']
})
export class MarathonDialogDeleteDistance {
  loading = false;
  has_marathons = false;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteDistance>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {
    this.has_marathons = this.data.has_marathons;
  }

  delete = () => {
      this.loading = true;
      this.http.Admin('marathons_distance_delete', {id: this.data.id})
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}
