import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { ManagerService } from '../../services/manager.service';
import { snackbar_options } from '../../variables';
import {DomSanitizer} from '@angular/platform-browser';
// import { Router, NavigationEnd } from '@angular/router';
import {Subject} from 'rxjs/Subject';
import { ErrorHandler } from '../../services/errorHandler.service';

import { SharedService } from "../../shared.service";

@Component({
  selector: 'app-marathon',
  templateUrl: './marathon.component.html',
  styleUrls: ['./marathon.component.scss']
})
export class MarathonComponent implements OnInit {
  server: string;
  data = {
    id: 0,
    distance: null
  };
  loader = true;

  selectedList = '-1';
  file_to_save = null;

  list_all = '';
  loading_list_all = false;
  list_person = '';
  loading_list_person = false;

  results = [];
  edit_distance = false;
  distance_loader = false;

  public static returned: Subject<any> = new Subject();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    // private _router: Router,
    public http: ManagerService,
    private shared: SharedService,
    private sanitizer: DomSanitizer,
    public errorHandler: ErrorHandler
  ) {
      this.server = `${shared.host}`;
      MarathonComponent.returned.subscribe(res => {
        this.data = {
          id: 0,
          distance: null
        };
        this.loader = true;
        this.selectedList = '-1';
        this.file_to_save = null;

        this.list_all = '';
        this.loading_list_all = false;
        this.list_person = '';
        this.loading_list_person = false;

        this.results = [];
        this.edit_distance = false;
        this.distance_loader = false;
        this.get_data();
      });
  }

  ngOnInit() {
    // this._router.routeReuseStrategy.shouldReuseRoute = function(){
    //   return false;
    // };
    // this._router.events.subscribe((evt) => {
    //     if (evt instanceof NavigationEnd) {
    //         this._router.navigated = false;
    //         window.scrollTo(0, 0);
    //     }
    // });
    this.get_data();
  }

  openInput = () => {
    document.getElementById("add-result-input").click();
  }

  get_data = () => {
    setTimeout(() => {
        let href = window.location.pathname.split('/');
        this.http.Manager('get_marathon', {id: href[href.length - 1]})
          .subscribe(data => {
            this.data = data;
            this.loader = false;
          }, err => {
            this.loader = false;
            this.errorHandler.handleError(err);
          });
    }, 100);

  }

  save_start_list = () => {
    if (this.selectedList == '-1')
        return this.snackBar.open('Wybierz listę startową.', '', snackbar_options);
    let events;
    if (this.selectedList.length > 1) {
      events = this.selectedList.split(" ");
    }
    this.loading_list_all = true;
    this.http.Manager('marathon_list_start_save', {id: this.data.id, data: {
      start_time: this.selectedList == '0' ? '' : events[1],
      start_date: this.selectedList == '0' ? '' : events[0]
    }})
    .subscribe(data => {
        if (data.message)
            this.snackBar.open(data.message, '', snackbar_options);
        this.list_all = data.file;
        this.loading_list_all = false;
    }, err => {
        this.loading_list_all = false;
        this.errorHandler.handleError(err);
    });
  }

  save_user_list = () => {
      this.loading_list_person = true;
      this.http.Manager('marathon_list_user_save', {id: this.data.id})
      .subscribe(data => {
          if (data.message)
              this.snackBar.open(data.message, '', snackbar_options);
          this.list_person = data.file;
          this.loading_list_person = false;
      }, err => {
        this.loading_list_person = false;
        this.errorHandler.handleError(err);
      });
  }

  add_result_distance = () => {
      if (this.data.distance < 0 || !this.data.distance)
        return this.snackBar.open('Podaj dystans.', '', snackbar_options);
      this.distance_loader = true;
      this.http.Manager('marathon_result_distance_add', {id: this.data.id, data: {
          distance: parseFloat(this.data.distance)
      }})
      .subscribe(data => {
          if (data && data.message)
              this.snackBar.open(data.message, '', snackbar_options);
            this.edit_distance = false;
            this.distance_loader = false;
      }, err => {
        this.distance_loader = false;
        this.errorHandler.handleError(err);
      });
  }

  add_result = () => {
    let dialogRef = this.dialog.open(MarathonDialogAddResult, {
      disableClose: true,
      width: '400px',
      data: {id: this.data.id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved')
          this.get_data();
    });
  }

  delete_result = (id) => {
    let dialogRef = this.dialog.open(MarathonDialogDeleteResult, {
      disableClose: true,
      width: '400px',
      data: {pdf_id: id, marathon_id: this.data.id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted')
          this.get_data();
    });
  }

}

@Component({
  selector: 'marathon-dialog-add-result',
  templateUrl: 'dialog-add-result.html',
  styleUrls: ['./marathon.component.scss']
})
export class MarathonDialogAddResult {
  loading = false;
  name = '';
  file_name = '';
  doc: File;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogAddResult>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: ManagerService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  openInput = () => {
    document.getElementById("add-result-input").click();
  }

  fileChange = (files: File[]) => {
    if (files.length > 0) {
      this.file_name = files[0].name;
      this.doc = files[0];
    }
  }

  save = () => {
    if (this.name.length == 0) return this.snackBar.open('Podaj nazwę pliku.', '', snackbar_options);
    else if (this.file_name.length == 0) return this.snackBar.open('Wybierz plik.', '', snackbar_options);

    let formData:FormData = new FormData();
    formData.append('result_marathon_file', this.doc, this.doc.name);
    formData.append('show_name', this.name);
    this.loading = true;
    this.http.Manager('marathon_result_add', {id: this.data.id, data: formData})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('saved');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}

@Component({
  selector: 'marathon-dialog-delete-result',
  templateUrl: 'dialog-delete-result.html',
  styleUrls: ['./marathon.component.scss']
})
export class MarathonDialogDeleteResult {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<MarathonDialogDeleteResult>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: ManagerService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  delete = () => {
    this.loading = true;
      this.http.Manager('marathon_result_delete', {pdf_id: this.data.pdf_id, marathon_id: this.data.marathon_id})
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('deleted');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}
