import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  users = [];
  displayedColumns = ['firstname', 'lastname', 'email', 'phone', 'city', 'id'];
  order = 'firstname';
  order_dir = 0;
  current_users_page = 0;
  is_next_page_users = false;
  loading_page = true;
  loading_users = false;

  user_limit = 10;
  search = '';
  search_timeout: any;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_user_list();
  }

  set_user_limit = (event) => {
    this.user_limit = event.value;
    this.users = [];
    this.current_users_page = 0;
    this.loading_page = true;
    this.is_next_page_users = false;
    this.get_user_list();
  }

  ngOnDestroy() {
    if (this.search_timeout)
      clearTimeout(this.search_timeout);
  }

  set_search = (event) => {
    this.search = event;
    if (this.search_timeout)
      clearTimeout(this.search_timeout);
    this.search_timeout = setTimeout(() => {
      this.users = [];
      this.current_users_page = 0;
      this.loading_page = true;
      this.is_next_page_users = false;
      this.get_user_list();
    }, 500);
  }

  get_user_list = () => {
    this.loading_users = true;
    this.http.Admin('users_get', {order: this.order, page: this.current_users_page, order_dir: this.order_dir, limit: this.user_limit, search: this.search})
    .subscribe(data => {
      this.current_users_page++;
      this.is_next_page_users = data.next_page;
      this.users = [...this.users, ...data.users];
      this.loading_users = false;
      this.loading_page = false;
    }, err => {
      this.loading_page = false;
      this.loading_users = false;
      this.errorHandler.handleError(err);
    });
  }

  sort = (order) => {
    if (this.order == order)
      this.order_dir = this.order_dir == 0 ? 1 : 0;
    else
      this.order_dir = 0;

    this.order = order;
    this.users = [];
    this.current_users_page = 0;
    this.loading_page = true;
    this.is_next_page_users = false;
    this.get_user_list();
  }

  delete_user = (id) => {
    let dialogRef = this.dialog.open(UserDialogDeleteUser, {
      width: '500px',
      data: {id: id},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.users = [];
        this.current_users_page = 0;
        this.loading_page = true;
        this.is_next_page_users = false;
        this.get_user_list();
      }
    });
  }
}

@Component({
  selector: 'user-dialog-delete-user',
  templateUrl: 'dialog-delete-user.html',
  styleUrls: ['./user.component.scss']
})
export class UserDialogDeleteUser {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<UserDialogDeleteUser>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  delete = () => {
    this.loading = true;
    this.http.Admin('user_delete', {id: this.data.id})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.loading = true;
      this.errorHandler.handleError(err);
    });

  }
}
