import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { snackbar_options } from '../variables';
import { ErrorHandler } from '../services/errorHandler.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  current_page = 'home';

  constructor(
    public snackBar: MatSnackBar,
    public http: AdminService,
    private router: Router,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    let href = window.location.pathname.split('/');
    if (href.indexOf("ranking") > -1)
      this.current_page = 'ranking';
    else if ((href.indexOf("zgody") > -1))
      this.current_page = 'zgody';
    else if (href.indexOf("faq") > -1)
      this.current_page = 'faq';
    else if (href.indexOf("koordynator") > -1)
      this.current_page = 'koordynator';
    else if (href.indexOf("uzytkownik") > -1)
      this.current_page = 'uzytkownik';
    else if (href.indexOf("maraton") > -1)
      this.current_page = 'maraton';
    else if (href.indexOf("email") > -1)
      this.current_page = 'email';
    else if (href.indexOf("pakiety-startowe") > -1)
      this.current_page = 'packets';
  }

  set_page = (page) => {
    this.current_page = page;
  }

  logout = () => {
    this.http.Admin('logout')
      .subscribe(data => {
        this.router.navigateByUrl('/');
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
      }, err => {
        this.errorHandler.handleError(err);
      });
  }

}
