import { Component, OnInit, Inject } from '@angular/core';
import {MatTabChangeEvent, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  selectedIndex = 0;

  user = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    gender: 0,
    city: '',
    birthday_date: '',
    team: '',
    school: '',
    profession: '',
    hearts: 0
  };

  marathon_page = 0;
  marathons = [];
  marathons_next_page = false;
  loading_marathons_main = true;
  loading_marathons = false;

  loading_hearts_main = true;
  loading_hearts = false;
  loading_hearts_add = false;
  heart_page = 0;
  hearts = [];
  displayedColumns = ['created_at', 'message', 'value', 'hearts_after'];
  new_desc = '';
  new_hearts = null;
  hearts_next_page = false;


  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    private router: Router,
    private location: Location,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    let href = window.location.pathname.split('/');
    this.http.Admin('user_get', {id: href[href.length-1]})
    .subscribe(data => {
      this.user = data;
    }, err => {
      this.router.navigate(['/admin/uzytkownik']);
      this.errorHandler.handleError(err);
    });
  }

  get_marathons = () => {
    this.loading_marathons = true;
    let page = this.marathon_page;
    this.http.Admin('user_marathons_get', {
        user_id: this.user.id,
        page: this.marathon_page
    })
    .subscribe(data => {
      if (page == 0)
        this.marathons = [];
      this.marathon_page++;
      this.marathons_next_page = data.next_page;
      this.marathons = [...this.marathons, ...data.marathons];
      this.loading_marathons = false;
      this.loading_marathons_main = false;
    }, err => {
      this.loading_marathons = false;
      this.loading_marathons_main = false;
      this.errorHandler.handleError(err);
    });
  }

  get_hearts = () => {
    this.loading_hearts = true;
    let page = this.heart_page;
    this.http.Admin('user_hearts_get', {
        user_id: this.user.id,
        page: this.heart_page
    })
    .subscribe(data => {
      if (page == 0)
        this.hearts = [];
      this.heart_page++;
      this.hearts_next_page = data.next_page;
      this.user.hearts = data.hearts;
      this.hearts = [...this.hearts, ...data.hearts_histories];
      this.loading_hearts_main = false;
      this.loading_hearts = false;
    }, err => {
      this.loading_hearts = false;
      this.loading_hearts_main = false;
      this.errorHandler.handleError(err);
    });
  }

  setActiveTab = (event: MatTabChangeEvent) => {
    // let current_page = this.selectedIndex;
    this.selectedIndex = event.index;
    switch (event.index) {
      case 0:
        // this.get_details();
        break;
      case 1:
        this.marathon_page = 0;
        this.marathons = [];
        this.marathons_next_page = false;
        this.loading_marathons_main = true;
        this.get_marathons();
        break;
      case 2:
        this.hearts = [];
        this.loading_hearts_main = true;
        this.heart_page = 0;
        this.hearts_next_page = false;
        this.get_hearts();
        break;
    }
  }

  goBack = () => {
    this.router.navigate(['/admin/uzytkownik']);
  }

  reset_password = () => {
    let dialogRef = this.dialog.open(UserDialogResetUser, {
      width: '400px',
      data: {id: this.user.id},
      disableClose: true
    });
  }

  add_hearts = () => {
      if (!this.new_desc)
        return this.snackBar.open("Podaj opis.", '', snackbar_options);
      if (this.new_hearts <= 0 || !this.new_hearts)
        return this.snackBar.open("Podaj kwotę.", '', snackbar_options);
      this.loading_hearts_add = true;
      this.http.Admin('user_hearts_add', {id: this.user.id, data: {
          message: this.new_desc,
          value: this.new_hearts
      }})
      .subscribe(data => {
          if (data && data.message)
            this.snackBar.open(data.message, '', snackbar_options);
          this.new_desc = '';
          this.new_hearts = null;
          this.heart_page = 0;
          this.loading_hearts_main = true;
          this.hearts = [];
          this.hearts_next_page = false;
          this.loading_hearts_add = false;
          this.get_hearts();
      }, err => {
        this.loading_hearts_add = false;
        this.errorHandler.handleError(err);
      });
  }

  unregister_user = (user_id, track_id) => {
    let dialogRef = this.dialog.open(UserDialogUnregisterUser, {
      width: '400px',
      data: {user_id: user_id, track_id: track_id},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
          this.marathon_page = 0;
          this.marathons = [];
          this.get_marathons();
      }
    });
  }
}

@Component({
  selector: 'user-dialog-reset-user',
  templateUrl: 'dialog-reset-user.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDialogResetUser {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<UserDialogResetUser>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  reset = () => {
    this.loading = true;
    this.http.Admin('user_reset_pass', {id: this.data.id})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('reset');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}

@Component({
  selector: 'user-dialog-unregister-user',
  templateUrl: 'dialog-unregister-user.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDialogUnregisterUser {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<UserDialogUnregisterUser>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  delete = () => {
    this.loading = true;
    this.http.Admin('user_marathons_sign_out', {
        user_id: parseInt(this.data.user_id),
        track_id: parseInt(this.data.track_id)
    })
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}
