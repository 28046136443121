import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {
  edited_mail = false;
  mail = '';
  input = '';
  loading_mail = true;
  loading_save = false;
  loading_mail_error = false;

  constructor(
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.http.Admin('settings_get_admin_mail')
    .subscribe(data => {
      if (data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.mail = data.admin_email;
      this.loading_mail = false;
    }, err => {
      this.loading_mail = false;
      this.loading_mail_error = true;
      this.errorHandler.handleError(err);
    });
  }

  edit_mail = () => {
    this.input = this.mail;
    this.edited_mail = true;
  }

  save_mail = () => {
    if (this.input.length == 0)
      return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
    let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_mail.test(String(this.input).toLowerCase()))
      return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);
    this.loading_save = true;
    this.http.Admin('settings_edit', {admin_email: this.input})
    .subscribe(data => {
      if (data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.mail = this.input;
      this.edited_mail = false;
      this.loading_save = false;
    }, err => {
      this.loading_save = false;
      this.errorHandler.handleError(err);
    });
  }
}
