import { Component, OnInit } from '@angular/core';
import { StartService } from 'src/app/services/start.service';
import { MatSnackBar } from '@angular/material';
import { snackbar_options } from '../../variables';
import { ErrorHandler } from '../../services/errorHandler.service';
import { ActivatedRoute } from '@angular/router';
import { $ } from 'protractor';

@Component({
  selector: 'app-foundation',
  templateUrl: './foundation.component.html',
  styleUrls: ['./foundation.component.scss']
})
export class FoundationComponent implements OnInit {

  public amount: number = 10;
  public other_amount: boolean = false;
  public email: string;
  public is_login: boolean = false;
  public loading: boolean = false;
  public loading_page: boolean = true;
  public session_id: string;
  public foundation_history: any;


  constructor(
    public http: StartService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler,
    private route: ActivatedRoute
  ) {
    this.loading_page = true;
    this.route.paramMap.subscribe(params => {

      this.session_id = params.get("session_id");

      if (!this.session_id) this.loading_page = false;

      this.loadData();
    });

  }

  ngOnInit() {
  }

  loadData() {
    this.loading = true;
    this.http.Foundation('start', {
      session_id: this.session_id
    })
      .subscribe(data => {
        if (data.email) this.email = data.email;
        if (data.id) this.is_login = true;

        setTimeout(() => {
          this.loading = false;
          this.loading_page = false;
          if (data.foundation_history) {
            this.foundation_history = data.foundation_history;
          }
        }, 500);

      }, err => {
        this.errorHandler.handleError(err);
        this.loading_page = false;
        this.loading = false;
      });
  }


  selectAmount(amount: number | null, other_amount?: boolean): void {
    if (this.loading) return;
    if (this.amount == amount && !!other_amount == !!this.other_amount) return;

    this.other_amount = !!other_amount;

    this.amount = Number(amount) || null;

    if (!!other_amount) {

      setTimeout(() => {
        document.getElementById("amount").focus();
      }, 250);

    }

  }

  buy(): any {
    if (this.loading) return;
    if (!this.email) {
      return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
    }
    let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_mail.test(String(this.email).toLowerCase()))
      return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);

    this.loading = true;

    this.http.Foundation('buy', { amount: this.amount, email: this.email })
      .subscribe(data => {
        if (data.link) {
          this.snackBar.open('Za chwilę zostaniesz przeniesony do płatności.', '', snackbar_options);
          window.location.assign(data.link);
        }

      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });


  }



}
