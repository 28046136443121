import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ng2-ckeditor';
import { AgmCoreModule } from '@agm/core';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin.routing.module';
import {
  HomeComponent, HomeDialogEditContent, HomeDialogAddDoc, HomeDialogAddEditSlider,
  HomeDialogDeleteDoc, HomeDialogDeleteSlider, HomeDialogAddItemsGallery
} from './home/home.component';
import { RankingComponent, RankingDialogSaveRanking } from './ranking/ranking.component';
import { ApprovalComponent, ApprovalDialogAddApproval, ApprovalDialogDeleteApproval } from './approval/approval.component';
import { FaqComponent, FaqDialogAddEditFaq, FaqDialogDeleteFaq } from './faq/faq.component';
import {
  CoordinatorComponent, CoordinatorDialogAddCoordinator, CoordinatorDialogDeleteCoordinator
} from './coordinator/coordinator.component';
import { CoordinatorDetailsComponent, CoordinatorDialogResetCoordinator, CoordinatorDialogEditCoordinator } from './coordinator-details/coordinator-details.component';
import { UserComponent, UserDialogDeleteUser } from './user/user.component';
import { StartPacketComponent, StartPackerDialogDeletePacket, StartPackerDialogAddPacket, StartPackerDialogDetailsPacket } from './start-packet/start-packet.component';
import { UserDetailsComponent, UserDialogResetUser, UserDialogUnregisterUser } from './user-details/user-details.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { MarathonComponent, MarathonDialogDeleteMarathon } from './marathon/marathon.component';
import { MarathonSetCategoriesComponent, MarathonDialogDeleteCategory, MarathonDialogAddCategory } from './marathon-set-categories/marathon-set-categories.component';
import { MarathonSetDistanceComponent, MarathonDialogAddDistance, MarathonDialogDeleteDistance } from './marathon-set-distance/marathon-set-distance.component';
import { MarathonSetStatementsComponent, MarathonDialogAddStatement, MarathonDialogDeleteStatement } from './marathon-set-statements/marathon-set-statements.component';
import { MarathonSetChargesComponent, MarathonDialogAddCharge, MarathonDialogDeleteCharge } from './marathon-set-charges/marathon-set-charges.component';
import { MarathonAddComponent } from './marathon-add/marathon-add.component';
import { MarathonDetailsComponent } from './marathon-details/marathon-details.component';
import {
  MarathonDialogAddResult, MarathonDialogDeleteResult, MarathonDialogAddTrack,
  MarathonDetailsDialogAddCategory, MarathonDetailsDialogAddDistance, MarathonDialogAddUser, MarathonDialogDeleteUser,
  MarathonDialogChangeTrackSystem, MarathonDialogEditPacket
} from './marathon-details/dialogs';

import { MailComponent } from './mail/mail.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MatTabsModule,
  MatCardModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatTableModule,
  MatSelectModule,
  MatExpansionModule,
  MatDividerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMenuModule,
  MatInputModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule
} from '@angular/material';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBkHAFytdHa8wiUbx9sSD9b6FbwffivjLs",
      libraries: ["places"]
    }),
    CommonModule,
    AdminRoutingModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatExpansionModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatInputModule,
    MatCheckboxModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    PipesModule
  ],
  declarations: [
    AdminComponent,
    HomeComponent,
    HomeDialogEditContent,
    HomeDialogAddDoc,
    HomeDialogDeleteDoc,
    HomeDialogAddEditSlider,
    HomeDialogDeleteSlider,
    RankingComponent,
    RankingDialogSaveRanking,
    ApprovalComponent,
    ApprovalDialogAddApproval,
    ApprovalDialogDeleteApproval,
    FaqComponent,
    FaqDialogAddEditFaq,
    FaqDialogDeleteFaq,
    CoordinatorComponent,
    CoordinatorDialogAddCoordinator,
    CoordinatorDialogDeleteCoordinator,
    CoordinatorDetailsComponent,
    CoordinatorDialogResetCoordinator,
    CoordinatorDialogEditCoordinator,
    UserComponent,
    UserDialogDeleteUser,
    UserDetailsComponent,
    UserDialogResetUser,
    UserDialogUnregisterUser,
    UserAddEditComponent,
    MarathonComponent,
    MarathonDialogDeleteMarathon,
    MarathonAddComponent,
    MarathonDetailsComponent,
    MailComponent,
    MarathonSetCategoriesComponent,
    MarathonDialogDeleteCategory,
    MarathonDialogAddCategory,
    MarathonSetDistanceComponent,
    MarathonDialogAddDistance,
    MarathonSetStatementsComponent,
    MarathonDialogAddStatement,
    MarathonDialogDeleteStatement,
    MarathonSetChargesComponent,
    MarathonDialogDeleteDistance,
    MarathonDialogAddCharge,
    MarathonDialogDeleteCharge,
    MarathonDialogAddResult,
    MarathonDialogDeleteResult,
    MarathonDialogAddTrack,
    MarathonDetailsDialogAddCategory,
    MarathonDetailsDialogAddDistance,
    MarathonDialogAddUser,
    MarathonDialogDeleteUser,
    MarathonDialogChangeTrackSystem,
    StartPacketComponent,
    StartPackerDialogDeletePacket,
    StartPackerDialogAddPacket,
    StartPackerDialogDetailsPacket,
    MarathonDialogEditPacket,
    HomeDialogAddItemsGallery
  ],
  entryComponents: [
    HomeDialogEditContent,
    HomeDialogAddDoc,
    HomeDialogDeleteDoc,
    HomeDialogAddEditSlider,
    ApprovalDialogAddApproval,
    ApprovalDialogDeleteApproval,
    HomeDialogDeleteSlider,
    FaqDialogAddEditFaq,
    FaqDialogDeleteFaq,
    RankingDialogSaveRanking,
    CoordinatorDialogAddCoordinator,
    CoordinatorDialogDeleteCoordinator,
    CoordinatorDialogResetCoordinator,
    CoordinatorDialogEditCoordinator,
    UserDialogDeleteUser,
    UserDialogResetUser,
    UserDialogUnregisterUser,
    MarathonDialogDeleteMarathon,
    MarathonDialogDeleteCategory,
    MarathonDialogAddCategory,
    MarathonDialogAddDistance,
    MarathonDialogDeleteDistance,
    MarathonDialogAddStatement,
    MarathonDialogDeleteStatement,
    MarathonDialogAddCharge,
    MarathonDialogDeleteCharge,
    MarathonDialogAddResult,
    MarathonDialogDeleteResult,
    MarathonDialogAddTrack,
    MarathonDetailsDialogAddCategory,
    MarathonDetailsDialogAddDistance,
    MarathonDialogAddUser,
    MarathonDialogDeleteUser,
    MarathonDialogChangeTrackSystem,
    StartPackerDialogDeletePacket,
    StartPackerDialogAddPacket,
    StartPackerDialogDetailsPacket,
    MarathonDialogEditPacket,
    HomeDialogAddItemsGallery
  ]
})
export class AdminModule { }
