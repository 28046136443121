import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AdminService } from '../../services/admin.service';
import { ErrorHandler } from '../../services/errorHandler.service';

import { snackbar_options } from '../../variables';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqs = [];
  current_page = 0;
  is_next_page = false;
  loading_next_page = false;
  main_loader = true;

  constructor(
    public dialog: MatDialog,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.get_faqs();
  }

  get_faqs = () => {
    this.loading_next_page = true;
    this.http.Admin('faq_get', this.current_page)
    .subscribe(data => {
      this.current_page++;
      this.is_next_page = data.next_page;
      this.faqs = [...this.faqs, ...data.faq];
      this.loading_next_page = false;
      this.main_loader = false;
    }, err => {
      this.loading_next_page = false;
      this.main_loader = false;
      this.errorHandler.handleError(err);
    });
  }

  add_faq = () => {
    let dialogRef = this.dialog.open(FaqDialogAddEditFaq, {
      disableClose: true,
      width: '400px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.faqs = [];
        this.current_page = 0;
        this.main_loader = true;
        this.is_next_page = false;
        this.get_faqs();
      }
    });
  }

  edit_faq = (id) => {
    let edited_faq = this.faqs.filter((faq) => {
      return faq.id == id;
    });
    let dialogRef = this.dialog.open(FaqDialogAddEditFaq, {
      disableClose: true,
      width: '400px',
      data: {edited_faq: edited_faq}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'saved') {
        this.faqs = [];
        this.current_page = 0;
        this.main_loader = true;
        this.is_next_page = false;
        this.get_faqs();
      }
    });
  }

  delete_faq = (id) => {
    let dialogRef = this.dialog.open(FaqDialogDeleteFaq, {
      disableClose: true,
      width: '400px',
      data: {id: id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'deleted') {
        this.faqs = [];
        this.current_page = 0;
        this.main_loader = true;
        this.is_next_page = false;
        this.get_faqs();
      }
    });
  }
}

@Component({
  selector: 'faq-dialog-add-edit-faq',
  templateUrl: 'dialog-add-edit-faq.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqDialogAddEditFaq {
  loading = false;
  question = '';
  answer = '';
  id = 0;

  constructor(
    public dialogRef: MatDialogRef<FaqDialogAddEditFaq>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {
    if (this.data.edited_faq) {
      this.question = this.data.edited_faq[0].question;
      this.answer = this.data.edited_faq[0].answer;
      this.id = this.data.edited_faq[0].id;
    }
  }

  save = () => {
    if (this.question.length == 0)
      return this.snackBar.open('Podaj treść pytania.', '', snackbar_options);
    else if (this.answer.length == 0)
      return this.snackBar.open('Podaj treść odpowiedzi.', '', snackbar_options);
    this.loading = true;
    if(this.id) {
      this.http.Admin('faq_edit', {data: {question: this.question, answer: this.answer}, id: this.id})
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
    } else {
      this.http.Admin('faq_add', {question: this.question, answer: this.answer})
      .subscribe(data => {
        if (data && data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close('saved');
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
    }
  }
}

@Component({
  selector: 'faq-dialog-delete-faq',
  templateUrl: 'dialog-delete-faq.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqDialogDeleteFaq {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<FaqDialogDeleteFaq>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: AdminService,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {}

  ngOnInit() {}

  delete = () => {
    this.loading = true;
    this.http.Admin('faq_delete', {id: this.data.id})
    .subscribe(data => {
      if (data && data.message)
        this.snackBar.open(data.message, '', snackbar_options);
      this.dialogRef.close('deleted');
    }, err => {
      this.loading = false;
      this.errorHandler.handleError(err);
    });
  }
}
