import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Location} from '@angular/common';
import { StartService } from '../../services/start.service';
import {Router} from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { snackbar_options } from '../../variables';
import { ErrorHandler } from '../../services/errorHandler.service';
import $ from 'jquery';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  login = '';
  pass = '';

  constructor(
    public dialog: MatDialog,
    private location: Location,
    public http: StartService,
    private router: Router,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) {
  }

  ngOnInit() {
    let href = window.location.pathname.split('/');
    $("body").removeClass('overflow-hidden');
    setTimeout(() => {
      if (href[href.length - 1] != 'login' && href[href.length - 1].length == 32) {
        this.dialog.open(LoginDialogChangePassword, {
          disableClose: true,
          minWidth: '360px',
          width: '360px',
          data: {hash: href[href.length - 1]}
        });
      }
    });
  }

  logIn() {
    if (!this.login) return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
    if (!this.pass) return this.snackBar.open('Podaj hasło.', '', snackbar_options);
    this.http.Auth('login', {
      email: this.login,
      password: Md5.hashStr(this.pass)
    })
    .subscribe(data => {
      switch (data.type) {
        case 1:
          return this.router.navigateByUrl('/panel');
        case 3:
          return this.router.navigateByUrl('/koordynator');
        case 4:
          return this.router.navigateByUrl('/admin');
      }
    }, err => {
      this.errorHandler.handleError(err);
      if (err.error.type)
      switch (err.error.type) {
        case 1:
          return this.router.navigateByUrl('/panel');
        case 3:
          return this.router.navigateByUrl('/koordynator');
        case 4:
          return this.router.navigateByUrl('/admin');
      }
    });
  }

  forgotPassword(event) {
    event.preventDefault();
    this.dialog.open(LoginDialogRemindPassword, {
      disableClose: true,
      minWidth: '360px',
      width: '360px'
    });
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);

  matcherMail = new MyErrorStateMatcher();
  matcherPass = new MyErrorStateMatcher();
}

@Component({
  selector: 'login-dialog-change-password',
  templateUrl: 'dialog-change-password.html',
  styleUrls: ['./login.component.scss']
})
export class LoginDialogChangePassword {
  new_pass = '';
  confirm_new_pass = '';
  loading = false;

  constructor(
    public http: StartService,
    public dialogRef: MatDialogRef<LoginDialogChangePassword>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public errorHandler: ErrorHandler
  ) { }

  close = () => {
    this.dialogRef.close();
  }

  change_password = () => {
    if (!this.new_pass)
      return this.snackBar.open('Podaj nowe hasło.', '', snackbar_options);
    if (this.new_pass.length < 8)
      return this.snackBar.open('Hasło musi składać się minimum z 8 znaków.', '', snackbar_options);
    if (!/^\S*$/.test(this.new_pass))
      return this.snackBar.open('Hasło nie może posiadać spacji.', '', snackbar_options);
    let reg = /^(?=(?:[^a-z]*[a-z]){1})(?=(?:[^0-9]*[0-9]){1})(?=.*[!-\/:-@\[-`{-~]).{8,}$/i;
    if (!reg.test(this.new_pass.toLowerCase()))
      return this.snackBar.open('Hasło musi posiadać minimum jedną literę, jedną liczbę i jeden znak specjalny.', '', snackbar_options);
    if (!this.confirm_new_pass)
      return this.snackBar.open('Potwierdź nowe hasło.', '', snackbar_options);
    if (this.new_pass !== this.confirm_new_pass)
      return this.snackBar.open('Potwierdź hasło. Oba hasła muszą być identyczne.', '', snackbar_options);

    this.loading = true;
    this.http.Auth('reset_password_step_2', {
      new_password: Md5.hashStr(this.new_pass),
      hash: this.data.hash
    })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close();
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}

@Component({
  selector: 'login-dialog-remind-password',
  templateUrl: 'dialog-remind-password.html',
  styleUrls: ['./login.component.scss']
})
export class LoginDialogRemindPassword {
  email = '';
  loading = false;

  constructor(
    public http: StartService,
    public dialogRef: MatDialogRef<LoginDialogRemindPassword>,
    public snackBar: MatSnackBar,
    public errorHandler: ErrorHandler
  ) { }

  close = () => {
    this.dialogRef.close();
  }

  change_password = () => {
    if (this.email.length == 0)
      return this.snackBar.open('Podaj adres e-mail.', '', snackbar_options);
    let re_mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_mail.test(String(this.email).toLowerCase()))
      return this.snackBar.open('Podaj poprawny adres e-mail.', '', snackbar_options);

    this.loading = true;
    this.http.Auth('reset_password_step_1', {
      email: this.email
    })
      .subscribe(data => {
        if (data.message)
          this.snackBar.open(data.message, '', snackbar_options);
        this.dialogRef.close();
      }, err => {
        this.loading = false;
        this.errorHandler.handleError(err);
      });
  }
}
