import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from "./admin.component";
import { HomeComponent } from "./home/home.component";
import { RankingComponent } from './ranking/ranking.component';
import { ApprovalComponent } from './approval/approval.component';
import { FaqComponent } from './faq/faq.component';
import { CoordinatorComponent } from './coordinator/coordinator.component';
import { CoordinatorDetailsComponent } from './coordinator-details/coordinator-details.component';
import { UserComponent } from './user/user.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { MarathonComponent } from './marathon/marathon.component';
import { MarathonAddComponent } from './marathon-add/marathon-add.component';
import { MarathonDetailsComponent } from './marathon-details/marathon-details.component';
import { MarathonSetCategoriesComponent } from './marathon-set-categories/marathon-set-categories.component';
import { MarathonSetChargesComponent } from './marathon-set-charges/marathon-set-charges.component';
import { MarathonSetDistanceComponent } from './marathon-set-distance/marathon-set-distance.component';
import { MarathonSetStatementsComponent } from './marathon-set-statements/marathon-set-statements.component';
import { MailComponent } from './mail/mail.component';
import { StartPacketComponent } from './start-packet/start-packet.component';

const routes: Routes = [
  {
    path: 'admin', component: AdminComponent, children: [
      { path: '', component: HomeComponent},
      { path: 'ranking', component: RankingComponent},
      { path: 'zgody', component: ApprovalComponent},
      { path: 'faq', component: FaqComponent},
      { path: 'koordynator', component: CoordinatorComponent},
      { path: 'koordynator/:id', component: CoordinatorDetailsComponent},
      { path: 'uzytkownik', component: UserComponent},
      { path: 'uzytkownik/dodaj-uzytkownika', component: UserAddEditComponent},
      { path: 'uzytkownik/edytuj-uzytkownika/:id', component: UserAddEditComponent},
      { path: 'uzytkownik/:id', component: UserDetailsComponent},
      { path: 'maraton', component: MarathonComponent},
      { path: 'maraton/dodaj-maraton', component: MarathonAddComponent},
      { path: 'maraton/dodaj-maraton/:id', component: MarathonDetailsComponent},
      { path: 'maraton/kategorie', component: MarathonSetCategoriesComponent},
      { path: 'maraton/dystans', component: MarathonSetDistanceComponent},
      { path: 'maraton/oswiadczenia', component: MarathonSetStatementsComponent},
      { path: 'maraton/oplata', component: MarathonSetChargesComponent},
      { path: 'maraton/:id', component: MarathonDetailsComponent},
      { path: 'email', component: MailComponent},
      { path: 'pakiety-startowe', component: StartPacketComponent}

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AdminRoutingModule { }
